import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import Edit from '../images/edit_icon.png'
import Modal from 'react-modal';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Delete from '../images/delete_icon.png';
import Header from '../Home/header';
import Footer from '../Home/footer';
import New from '../images/add.png'
import { format, parse } from 'date-fns';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import MyForm from './MyForm';
import { useHistory } from 'react-router-dom';
import SprintMyForm from './SprintMyForm';


// import Select from 'react-select';


const SignupSchema = Yup.object().shape({
  // projectName: Yup.string()
  //   .required('Required'),
  sprintName: Yup.string()
    .required('Required'),
  board: Yup.string()
    .required('Required'),
  sprintStartDate: Yup.date()
    .required('Required'),
  sprintEndDate: Yup.date()
    .required('Required'),
  sprintDeliveryDate: Yup.date()
    .required('Required'),
    sprintEstimatedHours: Yup.number()
    .required('Required'),
  sprintComplitedHours: Yup.number()
    .required('Required'),
  comments: Yup.string()
    .required('Required'),
});



const EditSignupSchema = Yup.object().shape({
  newprojectName: Yup.string()
    .required('Required'),
  newsprintName: Yup.string()
    .required('Required'),
  newboard: Yup.string()
    .required('Required'),
  newsprintStartDate: Yup.date()
    .required('Required'),
  newsprintEndDate: Yup.date()
    .required('Required'),
  newsprintDeliveryDate: Yup.date()
    .required('Required'),
  newsprintEstimatedHours: Yup.number()
    .required('Required'),
  newsprintComplitedHours: Yup.number()
    .required('Required'),
  newcomments: Yup.string()
    .required('Required'),
});


const ProjectSprint = (props) => {
  const history = useHistory();
  const [records, setRecords] = useState([]);
  const [records2, setRecords2] = useState([]);
  // const [reload, setRelode] = useState(true);
  const [filter, setFilter] = useState([]);
  const [pendingfilter, setPendingfilter] = useState([]);
  const [selectedRows, setSelecterRows] = useState([]);
  // const [isPopupOpen, setPopupOpen] = useState(false);
  const [editdatas, setEditdatas] = useState([]);
  const [SprintStartDate, setSprintStartDate] = useState([]);
  const [SprintEndDate, setSprintEndDate] = useState([]);
  const [SprintDeliveryDate, setSprintDeliveryDate] = useState([]);
  // ----------------------------
  const [forSprintStartDate, setForSprintStartDate] = useState([]);
  const [forSprintEndDate, setForSprintEndDate] = useState([]);
  const [forSprintDeliveryDate, setForSprintDeliveryDate] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [newProjectNameList, setNewProjectNameList] = useState('')
  const [editProjectNameList, setEditProjectNameList] = useState()


  // const [logindataid, setLogindataid] = useState(JSON.parse(localStorage.getItem("HelpDeskLoginDatas")));
  const [isOpen, setIsOpen] = useState(false);
  const [newOpen, setNewOpen] = useState(false);
  const [refrsh, setRefrsh] = useState(true)
  const [projectNameList, setProjectNameList] = useState([]);
  const [boardNameList, setBoardNameList] = useState([]);
  const [ProjectID, setProjectID] = useState(localStorage.getItem("SprintProjectID"));

const EditprojectMethod = (rowData) => {
    console.log('Button clicked for row:', rowData);
    var ProjectDetails = 'https://helpdeskservice.desss-portfolio.com/api/QuickEdit/LoadAll?ProjectID=' + rowData.ProjectID;
    axios.post(ProjectDetails)
    .then(ProjectDetailsData => {
      localStorage.setItem("EditprojectDatas", JSON.stringify(ProjectDetailsData.data.data[0]));
      console.log('ProjectDetailsData',  ProjectDetailsData.data.data[0]);
      // this.props.history.push('/projectdetails');
      history.push('/projectdetails');
    })
    .catch(error => {
    console.log(error);
    // this.props.history.push('/projectdetails');
    history.push('/projectdetails');
    })
}

const colums = [
    {
      name: 'Edit',
      selector: row => <img alt='edit_img' onClick={() => openModal(row)} width={20} height={20} src={Edit} />
    },
    {
      name: 'Delete',
      selector: row => <img alt='delete_image' onClick={() => deleteModal(row)} width={20} height={20} src={Delete} />
    },
    {
      name: 'Project Name',
      selector: row => row.ProjectName,
      sortable: true,
      cell: row => <button onClick={() => EditprojectMethod(row)}>{row.ProjectName}</button>,
    },
    {
      name: 'Board Name',
      selector: row => row.BoardName,
      sortable: true
    },
    {
      name: 'Sprint Name',
      selector: row => row.SprintName,
      sortable: true
    },
    {
      name: 'SprintStartDate',
      selector: row => row.SprintStartDate,
      sortable: true
    },
    {
      name: 'SprintEndDate',
      selector: row => row.SprintEndDate,
      sortable: true
    },
    {
      name: 'SprintDeliveryDate',
      selector: row => row.SprintDeliveryDate,
      sortable: true
    },
    {
      name: 'SprintEstimatedHours',
      selector: row => row.SprintEstimatedHours,
      sortable: true
    },
    {
      name: 'SprintCompletedHours',
      selector: row => row.SprintCompletedHours,
      sortable: true
    },
    {
      name: 'Allot Task',
      selector: row => <Link to='allottasktosprint' class="edit-btn animi" onClick={()=>{localStorage.setItem('AllotTaskToSprintData', JSON.stringify(row))}}>Allot Task to Sprint</Link>
    },
    {
      name: 'Sprint ID',
      selector: row => <Link to='burnoutchart' class="edit-btn animi" onClick={()=>{localStorage.setItem('BurnOutChartData', JSON.stringify(row))}}>Burn Out Chart</Link>
    },
  
    {
      name: 'Comments',
      selector: row => row.Comments,
      sortable: true,

    },
  ];
  const colums2 = [
    {
      name: 'Project Name',
      selector: row => row.ProjectName,
      sortable: true,
      cell: row => <button onClick={() => EditprojectMethod(row)}>{row.ProjectName}</button>,
    },
    {
      name: 'Board Name',
      selector: row => row.BoardName,
      sortable: true
    },
    {
      name: 'Sprint Name',
      selector: row => row.SprintName,
      sortable: true
    },
    {
      name: 'SprintStartDate',
      selector: row => row.SprintStartDate,
      sortable: true
    },
    {
      name: 'SprintEndDate',
      selector: row => row.SprintEndDate,
      sortable: true
    },
    {
      name: 'SprintDeliveryDate',
      selector: row => row.SprintDeliveryDate,
      sortable: true
    },
    {
      name: 'SprintEstimatedHours',
      selector: row => row.SprintEstimatedHours,
      sortable: true
    },
    {
      name: 'SprintCompletedHours',
      selector: row => row.SprintCompletedHours,
      sortable: true
    },
    {
      name: 'Comments',
      selector: row => row.Comments,
      sortable: true,
    },
    {
      name: 'Allot Task',
      selector: row => <Link to='allottasktosprint' class="edit-btn animi" onClick={()=>{localStorage.setItem('AllotTaskToSprintData', JSON.stringify(row))}}>Allot Task to Sprint</Link>
    },
    {
      name: 'Sprint ID',
      selector: row => <Link to='burnoutchart' class="edit-btn animi" onClick={()=>{localStorage.setItem('BurnOutChartData', JSON.stringify(row))}}>Burn Out Chart</Link>
    },
  ];


  //  function  editsprint (event){

  //   console.log( 'edit', event);
  //   }
  // const boardList=(e)=>{
  //   setEditProjectNameList(e.target.value)
  // console.log("board list data", e.target.value)
  // setNewProjectNameList(e.target.value)

  // console.log("newProjectNameList :", newProjectNameList)
  // console.log("EditProjectNameList : ",editProjectNameList);
  //     const boardNameListApi = 'https://helpdeskservice.desss-portfolio.com/api/Board/LoadBoard?ProjectID=' + e.target.value
  //     console.log(boardNameListApi);
  //     axios.post(boardNameListApi)
  //       .then(res => {
  //         console.log("BOARD NAME LIST :", res.data.data)
  //         setBoardNameList(res.data.data);
  //       })
  //       .catch(error => {
  //         console.log(error);
  //       })

  // }
  const openModal = (event) => {
    boardList1(event.ProjectID)
    // setEditProjectNameList(event.ProjectID);
    setNewProjectNameList(event.ProjectID)
    setIsOpen(true);
    // console.log('edit', event);
    setSelectedProjectId(event.ProjectID);
    var SprintStartDate = event.SprintStartDate.substring(0, 10);
    setSprintStartDate(SprintStartDate)
    setForSprintStartDate(format(parse(SprintStartDate, 'MM-dd-yyyy', new Date()), 'yyyy-MM-dd'))

    setEditdatas(event)
    var SprintEndDate = event.SprintEndDate.substring(0, 10);
    setSprintEndDate(SprintEndDate)
    setForSprintEndDate(format(parse(SprintEndDate, 'MM-dd-yyyy', new Date()), 'yyyy-MM-dd'))
    setEditdatas(event)
    var SprintDeliveryDate = event.SprintDeliveryDate.substring(0, 10);
    setSprintDeliveryDate(SprintDeliveryDate)
    setForSprintDeliveryDate(format(parse(SprintDeliveryDate, 'MM-dd-yyyy', new Date()), 'yyyy-MM-dd'),)
    setEditdatas(event)
    // console.log('edit ProjectID', event.ProjectID);
    // // boardList(event);
    // console.log("SprintStartDate : ",SprintStartDate);
    // console.log("SprintEndDate : ",SprintEndDate);
    // console.log("SprintDeliveryDate : ",SprintDeliveryDate);
    // localStorage.setItem("editdatas", JSON.stringify(event));
  };
  const deleteModal = (event) => {
    // console.log('delete', event);

    const deletesprint = 'https://helpdeskservice.desss-portfolio.com/api/DeleteSprintData/Delete?SprintID=' + event.SprintID;
    // console.log('employid', deletesprint);
    axios.delete(deletesprint).then(res => {
      // setLoader(false)
      let delsprint = res.data.data
      // console.log('employid', delsprint);
      setRefrsh(!refrsh)
    })
      .catch(error => {
        console.log(error);
      })
  }
  // console.log('SprintStartDate', SprintStartDate);
  const closeModal = () => {
    setNewProjectNameList('')
    setIsOpen(false);
  };

  const newopenModal = () => {
    setNewOpen(true);
  };

  const newcloseModal = () => {
    setNewOpen(false);
    setNewProjectNameList('')
  };
  const handlechange = (state) => {
    setSelecterRows(state.selectedRows);
  }


  const boardList = (e) => {
    // setEditProjectNameList(e.target.value)
    console.log("board list data", e.target.value)
    setNewProjectNameList(e.target.value)

    console.log("newProjectNameList :", newProjectNameList)
    console.log("EditProjectNameList : ", editProjectNameList);
    const boardNameListApi = 'https://helpdeskservice.desss-portfolio.com/api/Board/LoadBoard?ProjectID=' + e.target.value
    console.log(boardNameListApi);
    axios.post(boardNameListApi)
      .then(res => {
        console.log("BOARD NAME LIST :", res.data.data)
        setBoardNameList(res.data.data);
      })
      .catch(error => {
        console.log(error);
      })
  }
  const boardList1 = (e) => {
    // setEditProjectNameList(e.target.value)
    // console.log("board list data", e.target.value)
    setNewProjectNameList(e)

    // console.log("newProjectNameList :", newProjectNameList)
    // console.log("EditProjectNameList : ", editProjectNameList);
    const boardNameListApi = 'https://helpdeskservice.desss-portfolio.com/api/Board/LoadBoard?ProjectID=' +ProjectID
    // console.log(boardNameListApi);
    axios.post(boardNameListApi)
      .then(res => {
        console.log("BOARD NAME LIST :", res.data.data)
        // setBoardNameList(res.data.data);
      })
      .catch(error => {
        console.log(error);
      })

  }

  const setEmptyBoadList=()=>{
    setBoardNameList([]);
  }

  const getNewOpen=()=>{
    setNewOpen(false);
    setRefrsh(!refrsh)
  }

  // current sprint 


  function handleFilter1(event) {
    const newData = filter.filter(row => {
      return row.ProjectName.toLowerCase().includes(event.target.value.toLowerCase())
    })
    setRecords(newData)
  }
  function handleFilter2(event) {
    const newData = filter.filter(row => {
      return row.BoardName.toLowerCase().includes(event.target.value.toLowerCase())
    })
    setRecords(newData)
  }
  function handleFilter3(event) {
    const newData = filter.filter(row => {
      return row.SprintName.toLowerCase().includes(event.target.value.toLowerCase())
    })
    setRecords(newData)
  }
  function handleFilter4(event) {
    const originalDate = event.target.value;
    if (originalDate === "") {
      setRecords(filter);
    } else {
      console.log("2", originalDate)
      const parts = originalDate.split('-');
      const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
      // const newData = filter.filter(row => {
      //   return row.SprintStartDate.toLowerCase().includes(formattedDate.toLowerCase())
      // })
      const newData = filter.filter(row => {
        return row.SprintStartDate.toLowerCase().includes(originalDate.toLowerCase())
      })
      setRecords(newData)
    }

  }
  function handleFilter5(event) {
    const originalDate = event.target.value;
    if (originalDate === "") {
      setRecords(filter);
    } else {
      // console.log("2", originalDate)
      const parts = originalDate.split('-');
      const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
      // const newData = pendingfilter.filter(row => {
      //   return row.SprintEndDate.toLowerCase().includes(formattedDate.toLowerCase())
      // })
      const newData = filter.filter(row => {
        return row.SprintEndDate.toLowerCase().includes(originalDate.toLowerCase())
      })
      setRecords(newData)
    }
  }
  function handleFilter6(event) {
    const originalDate = event.target.value;
    if (originalDate === "") {
      setRecords(filter);
    } else {
      // console.log("2", originalDate)
      const parts = originalDate.split('-');
      const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
      // const newData = pendingfilter.filter(row => {
      //   return row.SprintDeliveryDate.toLowerCase().includes(formattedDate.toLowerCase())
      // })
      const newData = filter.filter(row => {
        return row.SprintDeliveryDate.toLowerCase().includes(originalDate.toLowerCase())
      })
      setRecords(newData)
    }
  }
  function handleFilter7(event) {
    const newData = filter.filter(row => {
      return row.SprintEstimatedHours.toLowerCase().includes(event.target.value.toLowerCase())
    })
    setRecords(newData)
  }
  function handleFilter8(event) {
    const newData = filter.filter(row => {
      return row.SprintCompletedHours.toLowerCase().includes(event.target.value.toLowerCase())
    })
    setRecords(newData)
  }
  function handleFilter9(event) {
    const newData = filter.filter(row => {
      return row.Comments.toLowerCase().includes(event.target.value.toLowerCase())
    })
    setRecords(newData)
  }


  // pending sprint

  function phandleFilter1(event) {
    const newData = pendingfilter.filter(row => {
      return row.ProjectName.toLowerCase().includes(event.target.value.toLowerCase())
    })
    setRecords2(newData)
  }
  function phandleFilter2(event) {
    const newData = pendingfilter.filter(row => {
      return row.BoardName.toLowerCase().includes(event.target.value.toLowerCase())
    })
    setRecords2(newData)
  }
  function phandleFilter3(event) {
    const newData = pendingfilter.filter(row => {
      return row.SprintName.toLowerCase().includes(event.target.value.toLowerCase())
    })
    setRecords2(newData)
  }
  function phandleFilter4(event) {
    const originalDate = event.target.value;
    if (originalDate === "") {
      setRecords2(pendingfilter);
    } else {
      // console.log("2", originalDate)
      const parts = originalDate.split('-');
      const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
      // const newData = pendingfilter.filter(row => {
      //   return row.SprintStartDate.toLowerCase().includes(formattedDate.toLowerCase())
      // })
      const newData = pendingfilter.filter(row => {
        return row.SprintStartDate.toLowerCase().includes(originalDate.toLowerCase())
      })
      setRecords2(newData)
    }

// console.log("pendingStateDate",event.target.value);

  }

  function phandleFilter5(event) {
    const originalDate = event.target.value;
    if (originalDate === "") {
      setRecords2(pendingfilter);
    } else {
      // console.log("2", originalDate)
      const parts = originalDate.split('-');
      const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
      // const newData = pendingfilter.filter(row => {
      //   return row.SprintEndDate.toLowerCase().includes(formattedDate.toLowerCase())
      // })
      const newData = pendingfilter.filter(row => {
        return row.SprintEndDate.toLowerCase().includes(originalDate.toLowerCase())
      })
      setRecords2(newData)
    }
  }
  function phandleFilter6(event) {
    const originalDate = event.target.value;
    if (originalDate === "") {
      setRecords2(pendingfilter);
    } else {
      // console.log("2", originalDate)
      const parts = originalDate.split('-');
      const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
      // const newData = pendingfilter.filter(row => {
      //   return row.SprintDeliveryDate.toLowerCase().includes(formattedDate.toLowerCase())
      // })
      const newData = pendingfilter.filter(row => {
        return row.SprintDeliveryDate.toLowerCase().includes(originalDate.toLowerCase())
      })
      setRecords2(newData)
    }
  }
  function phandleFilter7(event) {
    const newData = pendingfilter.filter(row => {
      return row.SprintEstimatedHours.toLowerCase().includes(event.target.value.toLowerCase())
    })
    setRecords2(newData)
  }
  function phandleFilter8(event) {
    const newData = pendingfilter.filter(row => {
      return row.SprintCompletedHours.toLowerCase().includes(event.target.value.toLowerCase())
    })
    setRecords2(newData)
  }
  function phandleFilter9(event) {
    const newData = pendingfilter.filter(row => {
      return row.Comments.toLowerCase().includes(event.target.value.toLowerCase())
    })
    setRecords2(newData)
  }

  function formatDate(apiDate) {
    const dateObj = new Date(apiDate);
    const day = dateObj.getDate().toString().padStart(2, "0");
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const year = dateObj.getFullYear();
    return `${month}-${day}-${year}`;
  }


  useEffect(() => {
    console.log("sprint page working fine123")
    const ProjectID = localStorage.getItem("SprintProjectID");
    console.log("daiiiiiiiiiiii"+ProjectID)
    // setProjectID(localStorage.getItem("SprintProjectID"));
    const BoardID = '';
    const UserTypeID = '';
    const EmpID = '';
    // const sprint = 'https://helpdeskservice.desss-portfolio.com/api/GetSprintData/LoadSprintGrid?ProjectID='+ProjectID+'&UserTypeID=1&BoardID=' + BoardID + '&EmpID=10'
    const sprint = 'https://helpdeskservice.desss-portfolio.com/api/GetSprintData/LoadSprintGrid?ProjectID='+ProjectID+'&UserTypeID=1&BoardID=' + BoardID + '&EmpID=10'
    axios.get(sprint)
      .then(res => {
        let pensprint = res.data.data
        // console.log('current sprint', pensprint)
       
        
        if(pensprint[0].errorMsg=="No Data Found"){
          setRecords([])
          setFilter([])
        }else{
          const formattedData = pensprint.map(item => ({
            ...item,
            SprintStartDate: formatDate(item.SprintStartDate),
            SprintEndDate: formatDate(item.SprintEndDate),
            SprintDeliveryDate: formatDate(item.SprintDeliveryDate)
          }))
          // console.log("current sprint formated data ",formattedData)
          setRecords(formattedData)
          setFilter(formattedData)
        }
      
      })
      .catch(error => {
        console.log(error);
      })

      const Pendingsprint = 'https://helpdeskservice.desss-portfolio.com/api/GetPendingSprintData/LoadPendingSprintGrid?ProjectID='+ProjectID+'&UserTypeID=1&BoardID=' + BoardID + '&EmpID=10'
      axios.post(Pendingsprint)
        .then(res => {
          let pensprint = res.data.data;
          // console.log('pending sprint', pensprint)
          const formattedData = pensprint.map(item => ({
            ...item,
            SprintStartDate: formatDate(item.SprintStartDate),
            SprintEndDate: formatDate(item.SprintEndDate),
            SprintDeliveryDate: formatDate(item.SprintDeliveryDate)
          }))
          // setRecords2(pensprint)
          // setPendingfilter(pensprint)

          setRecords2(formattedData)
          setPendingfilter(formattedData)
          // console.log("Formatted Data : ", formattedData)
        })

        const projectNameListApi = 'https://helpdeskservice.desss-portfolio.com/api/GetProjectName/GetProjectName?CompanyID=DESSS&UserTypeID=1&Est&Empid=10'
        axios.get(projectNameListApi)
          .then(res => {
            console.log("PROJECT NAME LIST :", res.data.data)
            console.log('ProjectID', ProjectID)
            setProjectNameList(res.data.data);
          })
          const boardNameListApi = 'https://helpdeskservice.desss-portfolio.com/api/Board/LoadBoard?ProjectID=' +ProjectID
          // console.log(boardNameListApi);
          axios.post(boardNameListApi)
            .then(res => {
              console.log("BOARD NAME LIST :", res.data.data)
              setBoardNameList(res.data.data);
            })
            .catch(error => {
              console.log(error);
            })

  }, [refrsh])


  return (
    <div>

      {/* <Header /> */}
      <div className='center-container'>
        <div className='full-tab'>
      <Tabs>
        <TabList>
          <Tab>Pending Sprint</Tab>
          <Tab>Current Sprint</Tab>
        </TabList>

        <TabPanel>
          <div className='table-grid'>
            <div className="search-filter">
              <div className='spr-filter'>
                <input type="text" placeholder="ProjectName" onChange={phandleFilter1} />
              </div>
              <div className='spr-filter'>
                <input type="text" placeholder="BoardName" onChange={phandleFilter2} />
              </div>
              <div className='spr-filter'>
                <input type="text" placeholder="SprintName" onChange={phandleFilter3} />
              </div>
              <div className='spr-filter'>
                <input type="text" placeholder="SprintStartDate" onChange={phandleFilter4} />
              </div>
              <div className='spr-filter'>
                <input type="text" placeholder="SprintEndDate" onChange={phandleFilter5} />
              </div>
              <div className='spr-filter'>
                <input type="text" placeholder="SprintDeliveryDate" onChange={phandleFilter6} />
              </div>
              <div className='spr-filter'>
                <input type="number" placeholder="SprintEstimatedHours" onChange={phandleFilter7} />
              </div>
              <div className='spr-filter'>
                <input type="number" placeholder="SprintCompletedHours" onChange={phandleFilter8} />
              </div>
              <div className='spr-filter'>
                <input type="text" placeholder="Comments" onChange={phandleFilter9} />
              </div>
              <div className='spr-filter'>
                <input type="text" placeholder="Allot Task"/>
              </div>
              <div className='spr-filter'>
                <input type="text" placeholder="Sprint ID" />
              </div>
            </div>
            <DataTable
              columns={colums2}
              data={records2}
              fixedHeader
              pagination
              filterable
              selectableRowsHighlight
              onSelectedRowsChange={handlechange}
            />
          </div>
        </TabPanel>
        <TabPanel>
          <div className='table-grid'>
            <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
              <a className='edit-btn-submodule' onClick={newopenModal} style={{width:'120px', cursor:'pointer'}}>
                <img src={New} style={{color: 'rgb(6, 99, 143)', height: '20px', borderWidth:'0px',marginRight:'10px'}}/>
                New Sprint
              </a>
            </div>
            <div className="search-filter">
            <div className='spr-filter'>
                <input type="text" placeholder="ProjectName" onChange={handleFilter1} />
              </div>
              <div className='spr-filter'>
                <input type="text" placeholder="BoardName" onChange={handleFilter2} />
              </div>
              <div className='spr-filter'>
                <input type="text" placeholder="SprintName" onChange={handleFilter3} />
              </div>
              <div className='spr-filter'>
                <input type="text" placeholder="SprintStartDate" onChange={handleFilter4} />
              </div>
              <div className='spr-filter'>
                <input type="text" placeholder="SprintEndDate" onChange={handleFilter5} />
              </div>
              <div className='spr-filter'>
                <input type="text" placeholder="SprintDeliveryDate" onChange={handleFilter6} />
              </div>
              <div className='spr-filter'>
                <input type="number" placeholder="SprintEstimatedHours" onChange={handleFilter7} />
              </div>
              <div className='spr-filter'>
                <input type="number" placeholder="SprintCompletedHours" onChange={handleFilter8} />
              </div>
              <div className='spr-filter'>
                <input type="text" placeholder="Allot Task"/>
              </div>
              <div className='spr-filter'>
                <input type="text" placeholder="Sprint ID" />
              </div>
              <div className='spr-filter'>
                <input type="text" placeholder="Allot Task" style={{visibility:'hidden'}} />
              </div>
              <div className='spr-filter'>
                <input type="text" placeholder="Sprint ID" style={{visibility:'hidden'}} />
              </div>
              <div className='spr-filter'>
                <input type="text" placeholder="Comments" onChange={phandleFilter9} />
              </div>
            </div>
            <DataTable
              columns={colums}
              data={records}
              fixedHeader
              pagination
              filterable
              selectableRowsHighlight
              onSelectedRowsChange={handlechange}
            />
          </div>
          {/* <button onClick={openModal}>edit Form</button> */}
          <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
          >

            <div>
              <div style={{width:'100%', textAlign:'right', marginBottom:'10px',display:'flex',justifyContent:'space-between'}}>
                <p style={{padding:'0px', fontSize:'22px',color:'#42403f',margin:'0px',fontWeight:'700'}}>Edit sprint</p>
                <button className='popup-closebtn' onClick={closeModal}>X</button>
              </div>
              <Formik
                initialValues={{
                  newprojectName: newProjectNameList,
                  newsprintName: editdatas.SprintName,
                  newboard: editdatas.BoardID,
                  newsprintStartDate: forSprintStartDate,
                  newsprintEndDate: forSprintEndDate,
                  newsprintDeliveryDate: forSprintDeliveryDate,
                  newsprintEstimatedHours: editdatas.SprintEstimatedHours,
                  newsprintComplitedHours: editdatas.SprintCompletedHours,
                  newcomments: editdatas.Comments,
                  // formatedSprintStartDate:format(parse(SprintStartDate, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd'),
                  // formatedSprintEndDate:"",
                  // formatedSprintDeliveryDate:''
                }}
                validationSchema={EditSignupSchema}
                onSubmit={values => {
                  //  console.log(values.newsprintStartDate)
                  // console.log('SprintEditvalues', values)
                  const url = 'https://helpdeskservice.desss-portfolio.com/api/UpdateSprintData/Update?ProjectID=' + newProjectNameList + '&BoardID=' + values.newboard + '&SprintID=' + editdatas.SprintID + '&SprintName=' + values.newsprintName + '&DeliveryDate=' + values.newsprintDeliveryDate + '&EstimatedHours=' + values.newsprintEstimatedHours + '&CompletedHours=' + values.newsprintComplitedHours + '&Comments=' + values.newcomments + '&EndDate=' + values.newsprintEndDate + '&StartDate=' + values.newsprintStartDate
                  // 'https://helpdeskservice.desss-portfolio.com/api/InsertSprintData/InsertData?ProjectID=1335&BoardID=1132&SprintName='+values.newsprintName+'&StartDate='+values.newsprintStartDate+'&DeliveryDate='+values.newsprintDeliveryDate+'&EstimatedHours='+values.newsprintEstimatedHours+'&CompletedHours='+values.newsprintComplitedHours+'&Comments='+values.newcomments+'&EndDate='+values.newsprintEndDate
                  console.log('api url', url)
                  axios.post(url)
                    .then(response => {
                      console.log('response', response.data.data)
                      setIsOpen(false);
                      setRefrsh(!refrsh)
                      setNewProjectNameList('')
                      // setNewProjectNameList('')
                      // setEditProjectNameList('')
                    })
                    .catch(error => {
                      console.error('Error fetching data: ', error);
                    });
                }}
              >
                {({ errors, touched, setFieldValue }) => (
                  <Form style={{borderTop:'1px solid #757575',paddingTop:'10px'}}>
                    <div className='sprint-popup'>
                    <label>Project Name : </label>
                    {
                    projectNameList.map((list) => {
                        if (list.ProjectID === newProjectNameList) {
                          return <span style={{border:'1px solid #ccc',width:'275px', display:'inline-block',padding:'3px'}} key={list.ProjectID}>{list.ProjectName}</span>;
                        }
                        return null; // or any default value if needed
                      })}
                    {/* <Field name="newprojectName" id='projectName' as="select" defaultValue={newProjectNameList} onChange={(e) => { boardList(e) }} > */}
                      {/* <option >--Select--</option>
                      {projectNameList.map((list) =>
                  <option key={list.ProjectID}  value={list.ProjectID}>{list.ProjectName}  </option>
                      )} */}
                     
                    {/* </Field> */}
                    {errors.newprojectName && touched.newprojectName ? (
                      <div>{errors.newprojectName}</div>
                    ) : null}
                   </div>

                   <div className='sprint-popup'>
                    <label>Sprint Name : </label>
                    <Field name="newsprintName" defaultValue={editdatas.SprintName} />
                    {errors.newsprintName && touched.newsprintName ? (
                      <div>{errors.newsprintName}</div>
                    ) : null}
                   </div>

                   <div className='sprint-popup'>
                    <label>Board : </label>
                    <Field name="newboard" defaultValue={editdatas.BoardID} id='board' as="select">
                      <option>--Select--</option>
                      {boardNameList.map((list) =>
                        <option value={list.BoardID}>{list.BoardName}</option>
                      )}
                    </Field>
                    {errors.newboard && touched.newboard ? (
                      <div>{errors.newboard}</div>
                    ) : null}
                    </div>
                    {/* <div className='sprint-popup'>
                    <label>Sprint Start Date : </label>
                    <Field name="newsprintStartDate" type="date" defaultValue={format(parse(SprintStartDate, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd')} />
                    {errors.newsprintStartDate && touched.newsprintStartDate ? (
                      <div>{errors.newsprintStartDate}</div>
                    ) : null}
                    </div>
                    <div className='sprint-popup'>
                    <label>Sprint End Date : </label>
                    <Field name="newsprintEndDate" type="date" defaultValue={format(parse(SprintEndDate, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd')} />
                    {errors.newsprintEndDate && touched.newsprintEndDate ? (
                      <div>{errors.newsprintEndDate}</div>
                    ) : null}
                    </div>
                    <div className='sprint-popup'>
                    <label>Sprint Delivery Date : </label>
                    <Field name="newsprintDeliveryDate" type="date" defaultValue={format(parse(SprintDeliveryDate, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd')} />
                    {errors.newsprintDeliveryDate && touched.newsprintDeliveryDate ? (
                      <div>{errors.newsprintDeliveryDate}</div>
                    ) : null}
                    </div> */}
                    <div className='sprint-popup'>
                    <label>Sprint Start Date : </label>
                    <Field name="newsprintStartDate" type="date" defaultValue={format(parse(SprintStartDate, 'MM-dd-yyyy', new Date()), 'yyyy-MM-dd')} />
                    {errors.newsprintStartDate && touched.newsprintStartDate ? (
                      <div>{errors.newsprintStartDate}</div>
                    ) : null}
                    </div>
                    <div className='sprint-popup'>
                    <label>Sprint End Date : </label>
                    <Field name="newsprintEndDate" type="date" defaultValue={format(parse(SprintEndDate, 'MM-dd-yyyy', new Date()), 'yyyy-MM-dd')} />
                    {errors.newsprintEndDate && touched.newsprintEndDate ? (
                      <div>{errors.newsprintEndDate}</div>
                    ) : null}
                    </div>
                    <div className='sprint-popup'>
                    <label>Sprint Delivery Date : </label>
                    <Field name="newsprintDeliveryDate" type="date" defaultValue={format(parse(SprintDeliveryDate, 'MM-dd-yyyy', new Date()), 'yyyy-MM-dd')} />
                    {errors.newsprintDeliveryDate && touched.newsprintDeliveryDate ? (
                      <div>{errors.newsprintDeliveryDate}</div>
                    ) : null}
                    </div>
                    <div className='sprint-popup'>
                    <label>Sprint Estimated Hours : </label>
                    <Field name="newsprintEstimatedHours" type='number' defaultValue={editdatas.SprintEstimatedHours} />
                    {errors.newsprintEstimatedHours && touched.newsprintEstimatedHours ? (
                      <div>{errors.newsprintEstimatedHours}</div>
                    ) : null}
                    </div>
                    <div className='sprint-popup'>
                    <label>Sprint Complite Hours : </label>
                    <Field name="newsprintComplitedHours" type='number' defaultValue={editdatas.SprintCompletedHours} />
                    {errors.newsprintComplitedHours && touched.newsprintComplitedHours ? (
                      <div>{errors.newsprintComplitedHours}</div>
                    ) : null}
                    </div>
                    <div className='sprint-popup'>
                    <label>Comments : </label>
                    <Field name="newcomments" defaultValue={editdatas.Comments} />
                    {errors.newcomments && touched.newcomments ? (
                      <div>{errors.newcomments}</div>
                    ) : null}
                    </div>
                    <div className='popup-update' style={{width:'100%', textAlign:'center',marginTop:'15px'}}>
                      <button  type="submit">Submit</button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>


          </Modal>
          <Modal
            isOpen={newOpen}
            onRequestClose={newcloseModal}
            contentLabel="Example Modal"
          >
            <div>
              <div style={{width:'100%', textAlign:'right', marginBottom:'10px',display:'flex',justifyContent:'space-between'}}>
                <p style={{padding:'0px', fontSize:'22px',color:'#42403f',margin:'0px',fontWeight:'700'}}>New sprint</p>
                <button className='popup-closebtn' onClick={newcloseModal}>X</button>
              </div>
            {/* ---------------------- */}
    {/* <MyForm projectNameList={projectNameList} boardNameList={boardNameList} boardList={boardList1} setEmptyBoadList={setEmptyBoadList} getNewOpen={getNewOpen} /> */}
    <SprintMyForm  projectNameList={projectNameList} boardNameList={boardNameList} boardList={boardList1} setEmptyBoadList={setEmptyBoadList} getNewOpen={getNewOpen}  ProjectID={ProjectID}/>
            {/* ---------------------------- */}

            </div>

          </Modal>
        </TabPanel>
      </Tabs>
      </div>
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default ProjectSprint