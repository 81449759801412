import React, { useEffect, useState } from 'react'
import Header from './header'
import refresh from '../images/refresh.png';
import Footer from './footer';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import Loader from '../images/loader2.gif';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const FunBoardMembers = () => {
    const [records1, setRecords1] = useState([]);
    const[filter1,setFilter1]=useState([]);
    const [records2, setRecords2] = useState([]);
    const[filter2,setFilter2]=useState([]);
    const [selectedRows, setSelecterRows] = useState([]);
    const[reload,setReload]=useState(true);
    const[loaderShow,setLoaderShow]=useState(false);
    const[loginData,setLoginData]=useState( JSON.parse(localStorage.getItem("TofunctionalBoard")));
    const[auth,setAuth]=useState(JSON.parse(localStorage.getItem("auth")))
    const[selectedColumn,setSelectedColumn]=useState([]);
    const [resetSelectionTable1, setResetSelectionTable1] = useState(false);
    const column1 = [
        {
          name: 'Employee Name',
          selector: row => row.EmpName,
          sortable: true
        },
        {
          name: 'Department',
          selector: row => row.Department,
          sortable: true
        },
        {
          name: 'Designation',
          selector: row => row.Designation,
          sortable: true
        },
         ];
    
    const column2=[
     {
          name: 'Employee Name',
          selector: row => row.BoardMembersName,
          sortable: true
        },
    ]    


    const handlechange = (state) => {
        setSelecterRows(state.selectedRows);
      }
      

      
      const adduser = () => {
        setSelecterRows('');
        console.log("add employee function working")
        const selecterIds = selectedRows.map(row => row.EmpID).join(',');
        console.log('employee', selecterIds);
        // https://helpdeskservice.desss-portfolio.com/api/AddBoard/Add_button?EmpID='+selecterIds+'&CompanyID=DESSS&BoardID='+loginData.BoardID
      const emp = ' https://helpdeskservice.desss-portfolio.com/api/AddBoard/Add_button?EmpID='+selecterIds+'&CompanyID=DESSS&BoardID='+loginData.BoardID;
      console.log("Add API ",emp)
      axios.post(emp).then(res => {
        setResetSelectionTable1(prevState => !prevState);
          let employee = res.data.data
          console.log('employee', employee);
          setReload(!reload)
        })
          .catch(error => {
            console.log(error);
          })
    
      }
      const removeemp = () => {
        console.log('employee full', selectedRows);
      let selecterIdss = selectedRows.map(row => row.BoardMemberID).join(',');
        console.log('employee', selecterIdss);

        const removeEmp = 'https://helpdeskservice.desss-portfolio.com/api/DeleteBoardMembers/Delete?BoardMemberID='+selecterIdss;
        selecterIdss='';        console.log("Remove API ",removeEmp);

        axios.delete(removeEmp).then(res => {
          // setLoader(false)
          setResetSelectionTable1(prevState => !prevState);
          let removeid = res.data.data
          console.log('employid', removeid);
          setReload(!reload)
       
    
        })
          .catch(error => {
            console.log(error);
            
          })

        }

      function EmployeeName2(event) {
        const newData = filter2.filter(row => {
          return  row.BoardMembersName.toLowerCase().includes(event.target.value.toLowerCase())
        })
        setRecords2(newData)
      }
      function EmployeeName1(event) {
        console.log(event.target.value)
        const newData = filter1.filter(row => {
          return row.EmpName.toLowerCase().includes(event.target.value.toLowerCase())
        })
        console.log("empname filter",newData)
        setRecords1(newData)
      }
      function Department(event) {
        const newData = filter1.filter(row => {
          return row.Department.toLowerCase().includes(event.target.value.toLowerCase())
        })
        setRecords1(newData)
      }
      function Designation(event) {
        const newData = filter1.filter(row => {
          return row.Designation.toLowerCase().includes(event.target.value.toLowerCase())
        })
        setRecords1(newData)
      }

      
useEffect(()=>{



    const AvailableProgrammer=()=>{
        setLoaderShow(true);
    const url='https://helpdeskservice.desss-portfolio.com/api/ProjectMembersList/LoadGrid?ProjectID='+loginData.ProjectID+'&CompanyID=DESSS&BoardID='+loginData.BoardID;
    console.log("Left Api ",url)
    axios.post(url)
    .then(res => {
    setRecords1(res.data.data)
    setFilter1(res.data.data)
    console.log("AvailableProgrammer : ",res.data.data)
    setLoaderShow(false);
    })
    .catch(error => {
        setLoaderShow(false);
    console.log(error);
    })
    }
    
    const SelectedEmployee=()=>{
        setLoaderShow(true);
        const url='https://helpdeskservice.desss-portfolio.com/api/BoardMembersList/LoadGrid?BoardID='+loginData.BoardID;
        console.log("Right Api ",url)
        axios.post(url)
        .then(res => {
        setRecords2(res.data.data)
        setFilter2(res.data.data)
        console.log("SelectedEmployee : ",res.data.data);
        setLoaderShow(false);
        })
        .catch(error => {
            setLoaderShow(false);
        console.log(error);
        })
    }
    AvailableProgrammer();
    SelectedEmployee();
    console.log("login data",loginData);
    },[reload])
    
    

  return (
<div>
<Header />
<h1 style={{display:'none'}}>Header</h1>
<div className='first-addemp'>
<div className='two-addemp1'>
  {/* sp 09-0-2024 */}
  <div className="add-employees add-emp-flex">
    <div style={{width: '50%',textAlign:'left'}}>
      <h2 style={{padding:'0px 0px 10px 10px',margin:'0px',fontSize:'21px'}}>Assigned resource</h2>
      <h3 style={{padding:'0px 0px 10px 10px',margin:'0px',fontSize:'15px'}}>project Name : {loginData.ProjectName}</h3>
    </div>

    <div style={{width: '50%',textAlign:'right'}}>
       <h3 style={{padding:'0px 0px 10px 10px',margin:'0px',fontSize:'15px'}}>Available Programmers</h3>
       <button onClick={() => adduser()} >Add Employees</button>
    </div>
  
  </div>
   {/* sp 09-0-2024 */}
  <div className="search-filter">
  <input type="text" placeholder="EmpName" onChange={EmployeeName1} />
    <input type="text" placeholder="Department" onChange={Department} />
    <input type="text" placeholder="Designation" onChange={Designation} />
  </div>
  <DataTable
    columns={column1}
    data={records1}
    selectableRows
    fixedHeader
    pagination
    filterable
    selectableRowsHighlight
    onSelectedRowsChange={handlechange}
    clearSelectedRows={resetSelectionTable1}

  />
</div>

<div className='two-addemp2'>
  {/* sp 09-0-2024 */}
  <div className="add-emp-flex">
  <h3 style={{padding:'12px 0px 10px 10px',margin:'0px',fontSize:'15px',width:'50%',textAlign:'left'}}>Members of the project</h3>
  <div className="add-employees" style={{width:'50%',textAlign:'right'}}>
    <button onClick={() => removeemp()}>Remove employees</button>
  </div>
  </div>


  {/* sp 09-0-2024 */}
  <div className="search-filter">
    <input type="text" placeholder="Employe name" onChange={EmployeeName2} />
  </div>
  <DataTable
    columns={column2}
    data={records2}
    selectableRows
    fixedHeader
    pagination
    filterable
    selectableRowsHighlight
    onSelectedRowsChange={handlechange}
    clearSelectedRows={resetSelectionTable1}
  />

</div>




</div>
<Footer/>
</div>


  )
}


export default FunBoardMembers