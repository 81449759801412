import React, { Component } from 'react';
import Footer from './footer';
import Header from './header';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import Loader from '../images/loader2.gif';
import ReactTable from "react-table-6";
import Edit from '../images/edit_icon.png';
import Delete from '../images/delete_icon.png';
import Swal from 'sweetalert2';
import moment from 'moment';
import New from '../images/add.png';



const TaskAllotPopUpvalidation = Yup.object({

    // Taskdate: Yup.string()
    // .required('Task Date is Required'),
    Projectname: Yup.string()
    .required('Project Name is Required'),
    Modulename: Yup.string()
    .required('Module Name is Required'),
    Screenname1: Yup.string()
    .required('Screen Name is Required'),
    Task: Yup.string()
    .required('Task is Required'),
    Board: Yup.string()
    .required('Board is Required'),
    Scrum: Yup.string()
    .required('Scrum is Required'),
    Assingedby: Yup.string()
    .required('Assinged By is Required'),
    Assingedto: Yup.string()
    .required('Assinged To is Required'),
    Tasktype: Yup.string()
    .required('Task Type is Required'),
    Hours: Yup.string()
    .required('Hours is Required'),
    // DocCount: Yup.string(),
    Taskstatus: Yup.string()
    .required('Task Status is Required'),
    // Notes: Yup.string(),
    // Billable: Yup.string(),
    // Eststartdate: Yup.string()
    // .required('Start Date is Required'),
    // Estenddate: Yup.string()
    // .required('End Date is Required')
  });

export class backlogestimateandallot extends Component {

    constructor() {
		super();
		this.state = {
            BackLogListDatas:[],
            LoginDatas:  JSON.parse(localStorage.getItem("HelpDeskLoginDatas")),
            EstimateAndAllotBackLogsDatas:  JSON.parse(localStorage.getItem("EstimateAndAllotBackLogsDatas")),
            Auth:  JSON.parse(localStorage.getItem("auth")),
            LoaderShow: false,
            showtab1: true,
            showtab2: false,
            showModal1: false,
            showModal2: false,
            ProjectDatas:[],
            IssueTypeDatas: [],
            Module_Epic_Datas: [],
            WorkFlowDatas: [],
            ReportedByData: [],
            ProjectID: '',
            formData : new FormData(),

            GridAllotTableData: [],
            TaskNameList:[],
            PushTaskList:[],
            AddTaskList:[],

            TaskAllotModalShow: false,
            IsBillable : false,
            Notes1 : '',
            DocCount: '',
            PrjTaskAllotID: '',
            ModuleID: '',
            SubModuleID: '',
            EditTaskAllotDatas: [],
            BoardDatas: [],
            SprintDatas: [],
            AssingedbyDatas: [],
            TaskstatusDatas: [],
            AssingedToDatas: [],
            TaskTypeDatas: [],
	  };
	}

componentDidMount(){
this.GridAllotTableDatamethod();
this.TaskListMethod();
}

ProjectNameDataMethod(){
    var project = 'https://helpdeskservice.desss-portfolio.com/api/GetProjectName/GetProjectName?CompanyID='+this.state.LoginDatas.CompanyID+'&UserTypeID='+this.state.LoginDatas.UserTypeID+'&Est&Empid='+this.state.LoginDatas.EmpID;
   
    axios.get(project)
    .then(res => {
    this.setState({ProjectDatas: res.data.data});
    console.log('ProjectDatas',  this.state.ProjectDatas);
    })
    .catch(error => {
    console.log(error);
    })
}

IssueTypeDataMethod(){
    var issue = 'https://helpdeskservice.desss-portfolio.com/api/ProjectTaskType/LoadTasktype';
    axios.post(issue)
    .then(res => {
    this.setState({IssueTypeDatas: res.data.data});
    console.log('IssueTypeDatas',  this.state.IssueTypeDatas);
    })
    .catch(error => {
    console.log(error);
    })
}
Module_Epic_DataMethod(){
  var module = 'https://helpdeskservice.desss-portfolio.com/api/ModuleByCompany/LoadModule?CompanyID=' + this.state.LoginDatas.CompanyID;
  axios.post(module)
  .then(res => {
  this.setState({Module_Epic_Datas: res.data.data});
  console.log('Module_Epic_Datas',  this.state.Module_Epic_Datas);
  })
  .catch(error => {
  console.log(error);
  })
}
WorkFlowDataMethod(){
  var workflow = 'https://helpdeskservice.desss-portfolio.com/api/SubModTaskCat/GetTaskCategory?CompanyID=' + this.state.LoginDatas.CompanyID;
  axios.get(workflow)
  .then(res => {
  this.setState({WorkFlowDatas: res.data.data});
  console.log('WorkFlowDatas',  this.state.WorkFlowDatas);
  })
  .catch(error => {
  console.log(error);
  })
}

ReportedByDataMethod(){
  var URL = 'https://helpdeskservice.desss-portfolio.com/api/AssignedBy/loadall?CompanyID=' + this.state.LoginDatas.CompanyID;
  axios.post(URL)
  .then(res => {
  this.setState({ReportedByData: res.data.data});
  console.log('ReportedByData',  this.state.ReportedByData);
  })
  .catch(error => {
  console.log(error);
  })
}

//start Estimate amd Allot

AddTaskCheckBoxmethod = (event) => {
    var checked = event.target.checked;
    var Value = event.target.value;
    // var AddEmpList = [];
    if(checked === true){
      // this.setState.AddEmpList.push({Value});
      this.setState({
        AddTaskList: this.state.AddTaskList.concat(Value)
      })
    }else if(checked === false){
      var array = this.state.AddTaskList;
      var index = array.indexOf(Value); // Let's say it's Bob.
      delete array[index];
    }
    console.log('check box Datas1221212', this.state.AddTaskList);
    console.log('check box Datas12', event.target.checked, event.target.value);
}
AddTasksMethod = () => {
    const AddTsk = this.state.AddTaskList.join(',');
    console.log('AddEmp Method', AddTsk);
    var AddURL = "https://helpdeskservice.desss-portfolio.com/api/AddTaskName/AddTask?taskId=" + AddTsk + "&CompanyID=" + this.state.LoginDatas.CompanyID + "&ProjectID=" + this.state.EstimateAndAllotBackLogsDatas.ProjectID + "&ModuleID=" + this.state.EstimateAndAllotBackLogsDatas.ModuleID + "&SubModuleID=" + this.state.EstimateAndAllotBackLogsDatas.PrjTaskID + "&EmpID=" + this.state.LoginDatas.EmpID + "&TaskCategoryID=" + this.state.EstimateAndAllotBackLogsDatas.TaskCategory + "&UserName=" + this.state.LoginDatas.EmpName;
  
    axios.post(AddURL)
    .then(Response => {
    this.state.AddTaskList = [];
     console.log('AddURL', Response);
     this.setState({ TaskNameList: []});
     this.componentDidMount();
    })
    .catch(error => {
    console.log(error);
    this.state.AddTaskList = [];
    this.componentDidMount();
  
 })
  }
PushTaskCheckBoxmethod = (event) => {
    var checked = event.target.checked;
    var Value = event.target.value;
    if(checked === true){
      this.setState({
        PushTaskList: this.state.PushTaskList.concat(Value)
      })
    }else if(checked === false){
      var array = this.state.PushTaskList;
      var index = array.indexOf(Value); 
      delete array[index];
    }
    console.log('PushTaskList Datas1221212', this.state.PushTaskList);
    console.log('PushTaskList box Datas12', event.target.checked, event.target.value);
}

PushToTasksMethod = () => {
    const Tasks = this.state.PushTaskList.join(',');
    console.log('PushToTasksMethod Method', Tasks);
    this.setState({ LoaderShow: true});
    var AddURL = "https://helpdeskservice.desss-portfolio.com/api/PushToTaskAllotment/LoadGrid?PrjTaskAllotID=" + Tasks + "&CompanyID="+this.state.LoginDatas.CompanyID + "&ProjectID=" + this.state.EstimateAndAllotBackLogsDatas.ProjectID + "&UserName=" + this.state.LoginDatas.EmpName+"&ModuleID=" + this.state.EstimateAndAllotBackLogsDatas.ModuleID + "&SubModuleID=" + this.state.EstimateAndAllotBackLogsDatas.PrjTaskID;
  
    axios.post(AddURL)
    .then(Response => {
    this.state.PushTaskList = [];
     console.log('AddURL', Response.data.data[0].errorMsg);
     if(Response.data.data[0].errorMsg === "Task Pushed Successfully"){
      Swal.fire({
        icon: "success",
        title: "Task Pushed Successfully",
        showConfirmButton: false,
        timer: 1500
      });
     }else{
      Swal.fire({
        icon: "error",
        title: "Please Assign Employee to continue",
        showConfirmButton: false,
        timer: 3000
      });
     }
     this.setState({ PushTaskList: []});
     this.componentDidMount();
    this.setState({ LoaderShow: false});

    })
    .catch(error => {
      Swal.fire({
        icon: "error",
        title: "something went wrong",
        showConfirmButton: false,
        timer: 3000
      });
    console.log(error);
    this.state.PushTaskList = [];
    this.componentDidMount();
    this.setState({ LoaderShow: false});
    })
}
TaskAllotModalOpenMethod = (res, datas) => {

    this.BoardDatasMethod();
   this.SprintDatasMethod();
   this.AssingedbyDataMethod();
   this.TaskstatusDatasMethod();
   this.AssingedToDataMethod();
   this.TaskTypeDataMethod();

    this.setState({ IsBillable: datas.Billable });
    this.setState({ Notes1: datas.Notes });
  
    if(datas.DocCount === null || datas.DocCount === ''){
      this.setState({ DocCount: '0' });
    }else{
      this.setState({ DocCount: datas.DocCount });
    }
    
    this.setState({ PrjTaskAllotID: datas.PrjTaskAllotID });
    this.setState({ ModuleID: datas.ModuleID });
    
  
    this.setState({ TaskAllotModalShow: true });
    this.setState({ EditTaskAllotDatas: datas });
    console.log('Edit Datas 2', datas.Billable);
    
    console.log('Edit Datas 2', datas);
}
TaskAllotModalCloseMethod = () => {
    this.setState({ TaskAllotModalShow: false });
}

TaskListMethod(){
  var Search2 = "https://helpdeskservice.desss-portfolio.com/api/LoadTaskName/LoadGrid?TaskCategory="+this.state.EstimateAndAllotBackLogsDatas.TaskCategory+"&ProjectID=" + this.state.EstimateAndAllotBackLogsDatas.ProjectID + "&ModuleID=" + this.state.EstimateAndAllotBackLogsDatas.ModuleID + "&PrjTaskID=" + this.state.EstimateAndAllotBackLogsDatas.PrjTaskID;
  axios.post(Search2)
  .then(SearchResult2 => {
  console.log('SearchResult2',  SearchResult2);
  if(SearchResult2.data.data[0].errorMsg === "No Data Found"){
    this.setState({ TableShow: true});
    this.setState({ LoaderShow: false});
     return;
  }else{
    this.setState({ TaskNameList: SearchResult2.data.data});
    this.setState({ TableShow: true});
    this.setState({ LoaderShow: false});
  }

  })
  .catch(error => {
  console.log(error);
  this.setState({ LoaderShow: false});
  })
}
GridAllotTableDatamethod(){
    var GridAllotTableData1 = [];
    this.setState({ GridAllotTableData: GridAllotTableData1 });
  
    var gridAllot = "https://helpdeskservice.desss-portfolio.com/api/GridAllot/LoadGrid?ProjectID="+this.state.EstimateAndAllotBackLogsDatas.ProjectID+"&ModuleID="+this.state.EstimateAndAllotBackLogsDatas.ModuleID+"&PrjTaskID=" +this.state.EstimateAndAllotBackLogsDatas.PrjTaskID;
    axios.post(gridAllot)
    .then(Response1 => {
     console.log('GridAllotTableDatamethod', Response1.data.data);
     var data = Response1.data.data;
     if(data[0].errorMsg === "No Data Found"){
       return;
     }else{
      data.forEach((key, value) => {
        GridAllotTableData1.push({
             TaskDate:  moment(key.TaskDate).format("MM/DD/YYYY"), 
             ProjectName: key.ProjectName,
             PrjTaskAllotID: key.PrjTaskAllotID,
             ProjectID: key.ProjectID,
             ModuleID: key.ModuleID,
             ModuleName: key.ModuleName,
             Task: key.Task,
             Sequence: key.Sequence,
             EstStartDate: moment(key.EstStartDate).format("MM/DD/YYYY"),
             EstEndDate: moment(key.EstEndDate).format("MM/DD/YYYY"),
             AllotedHrs: key.AllotedHrs,
             TaskStatus:  key.TaskStatus,
             AssignedByName:  key.AssignedtoName,
             AssignedTo:  key.EmpName,
             TaskTypeName:  key.TaskTypeName,
             DocCount:  key.DocCount,
             Notes:  key.Notes,
             Billable:  key.Billable,
             Detail:  key.Details,
             TaskName: key.TaskName,
             BoardID: key.BoardID,
             BoardName: key.BoardName,
             SprintID: key.SprintID,
             SprintName: key.SprintName,
             AssignedByID: key.AssignedTo,
             AssignedToID: key.EmpID,
             TaskType: key.TaskType,
             TaskStatusID: key.TaskStatusID
           });
        })
        this.setState({ GridAllotTableData: GridAllotTableData1 }); 
     }
     
     })
    .catch(error => {
    console.log(error);
    this.setState({ GridAllotTableData: GridAllotTableData1 });
    })
  }


  DeleteTask(res, datas){
    Swal.fire({
      title: "Are you sure?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      
  
    if (result.isConfirmed) {
    const Tasks = this.state.PushTaskList.join(',');
    console.log('DeleteTask' , datas);
    var URL = "https://helpdeskservice.desss-portfolio.com/api/DeleteGridAllot/DeleteRow?PrjTaskAllotID="+ datas.PrjTaskAllotID +"&ProjectID=" + this.state.EstimateAndAllotBackLogsDatas.ProjectID + "&ModuleID=" + this.state.EstimateAndAllotBackLogsDatas.ModuleID + "&SubModuleID="+ this.state.EstimateAndAllotBackLogsDatas.PrjTaskID;
    axios.delete(URL)
    .then(res => {
     console.log('Delete Task',  res);
     this.state.PushTaskList = [];
      Swal.fire({
        icon: "success",
        title: "Ticket has been deleted",
        showConfirmButton: false,
        timer: 1500
       });
  
      this.componentDidMount();
      console.log('Delete module',  res);
    })
    .catch(error => {
    console.log(error);
    this.componentDidMount();
    })
        
  }
});
} 

DuplicateMethod = () => {
    const Tasks = this.state.PushTaskList.join(',');
    console.log('PushToTasksMethod Method', Tasks);
    var DeleteURL = "https://helpdeskservice.desss-portfolio.com/api/DuplicateButton/Duplicate?PrjTaskAlotID=" + Tasks;
    axios.post(DeleteURL)
    .then(Response => {
      this.state.PushTaskList = [];
     console.log('AddURL', Response);
     this.setState({ PushTaskList: []});

    this.componentDidMount();
    })
    .catch(error => {
      Swal.fire({
        icon: "error",
        title: "something went wrong",
        showConfirmButton: false,
        timer: 3000
      });
    console.log(error);
    this.state.PushTaskList = [];
    this.componentDidMount();
    })
  }

  GridAllotTaskDateChange = (e) => {
    console.log('GridAllotTaskDateChange', e.target.value);
    var GridAllotTaskDateChangeDate =  moment(e.target.value).format("MM/DD/YYYY");
    // this.setState({ EditTaskAllotDatas[0].TaskDate: GridAllotTaskDateChangeDate }); 
    document.getElementById('GridAllotTaskDate').value = GridAllotTaskDateChangeDate;
    console.log('Date Change GridAllotTaskDateChangeDate', GridAllotTaskDateChangeDate);
  }
  
  EstdStartDateChange = (e) => {
    console.log('EstdStartDateChange', e.target.value);
    var EstdStartDate =  moment(e.target.value).format("MM/DD/YYYY");
    // this.setState({ EditTaskAllotDatas[0].TaskDate: GridAllotTaskDateChangeDate }); 
    document.getElementById('EstdStartDate').value = EstdStartDate;
  }
  
  EstdEndDateChange = (e) => {
    console.log('EstdEndDateChange', e.target.value);
    var EstdEndDate =  moment(e.target.value).format("MM/DD/YYYY");
    // this.setState({ EditTaskAllotDatas[0].TaskDate: GridAllotTaskDateChangeDate }); 
    document.getElementById('EstdEndDate').value = EstdEndDate;
  }
  
  IsBillableChange= (e) => {
    console.log('IsBillableChange', e.target.checked);
    if(e.target.checked){
      document.getElementById('IsBillable').checked = true;
      this.setState({ IsBillable: true });
    }else{
      document.getElementById('IsBillable').checked = false;
      this.setState({ IsBillable: false });
    }
    // document.getElementById('IsBillable').value = true;
  }

  BoardDatasMethod(){
    var URL = 'https://helpdeskservice.desss-portfolio.com/api/Board/LoadBoard?ProjectID=' +  this.state.EstimateAndAllotBackLogsDatas.ProjectID;
    axios.post(URL)
    .then(res => {
      console.log('Board Datas', res.data.data);
      this.setState({ BoardDatas: res.data.data });
   })
    .catch(error => {
    console.log(error);
   })
  }
  
  SprintDatasMethod(){
    var URL = 'https://helpdeskservice.desss-portfolio.com/api/GridSprint/LoadBoard?ProjectID=' +  this.state.EstimateAndAllotBackLogsDatas.ProjectID;
    axios.post(URL)
    .then(res => {
      console.log('Sprint Datas', res.data.data);
      this.setState({ SprintDatas: res.data.data });
   })
    .catch(error => {
    console.log(error);
   })
  }
  
  AssingedbyDataMethod(){
    var URL = 'https://helpdeskservice.desss-portfolio.com/api/AssignedBy/loadEmp?CompanyID='+ this.state.LoginDatas.CompanyID +'&ProjectID=' +  this.state.EstimateAndAllotBackLogsDatas.ProjectID;
    axios.post(URL)
    .then(res => {
      console.log('Assingedby Data', res.data.data);
      this.setState({ AssingedbyDatas: res.data.data });
   })
    .catch(error => {
    console.log(error);
   })
  }
  
  TaskstatusDatasMethod(){
    var URL = 'https://helpdeskservice.desss-portfolio.com/api/TaskStatus/LodeTaskStatus';
    axios.post(URL)
    .then(res => {
      console.log('TaskstatusDatas', res.data.data);
      this.setState({ TaskstatusDatas: res.data.data });
   })
    .catch(error => {
    console.log(error);
   })
  }
  
  AssingedToDataMethod(){
    var URL = 'https://helpdeskservice.desss-portfolio.com/api/AssignedBy/loadEmp?CompanyID='+ this.state.LoginDatas.CompanyID +'&ProjectID=' +  this.state.EstimateAndAllotBackLogsDatas.ProjectID;
    axios.post(URL)
    .then(res => {
      console.log('Assinged To Data', res.data.data);
      this.setState({ AssingedToDatas: res.data.data });
   })
    .catch(error => {
    console.log(error);
   })
  }
  
  TaskTypeDataMethod(){
    var URL = 'https://helpdeskservice.desss-portfolio.com/api/ProjectTaskType/LoadTasktype';
    axios.post(URL)
    .then(res => {
      console.log('Task Type Data ', res.data.data);
      this.setState({ TaskTypeDatas: res.data.data });
   })
    .catch(error => {
    console.log(error);
   })
  }


filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    const content = row[id];
    if (typeof content !== 'undefined') {
        // filter by text in the table or if it's a object, filter by key
        if (typeof content === 'object' && content !== null && content.key) {
            return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
        } else {
            return String(content).toLowerCase().includes(filter.value.toLowerCase());
        }
    }
  
    return true;
  }
render() {
    const TaskNameColumns = [
        {
          Header: 'Select',
          accessor: 'TaskCategory',
          disableSortBy: true,
          style:{
              textAlign: "center"
          },
          Cell: props => {
            return (
              <input type='checkbox' onChange={this.AddTaskCheckBoxmethod} value={props.original.TaskID} name="CheckBox"/>
            )
        }
        },
        {
          Header: 'Task Name',
          accessor: 'TaskDescription',
          disableSortBy: true,
          style:{
              textAlign: "center"
          },
         },
      ]

      const GridAllotColumns = [
        {
          Header: 'Select',
          accessor: 'Select',
          style:{
              textAlign: "center",
              width:'100px'
          },
          width: "150px",
          Cell: props => {
            return(
            <input type='checkbox' onChange={this.PushTaskCheckBoxmethod} value={props.original.PrjTaskAllotID}  name="GridChk" style={{width:"100%"}} />
          )
        }
        },
        
        {
          Header: 'Task Date',
          accessor: 'TaskDate',
          style:{
              textAlign: "center",
              width:'100px' 
          },
          width: "150px",
         },
        {
          Header: 'Task',
          accessor: 'Task',
          style:{
              textAlign: "center",
              width:'100px'
          },
          width: "150px",
         },
        //  {
        //   Header: 'Sequence',
        //   accessor: 'Sequence',
        //   style:{
        //       textAlign: "center",
        //       width:'100px'
        //   },
        //   width: "150px",
        //  },
         {
          Header: 'Board',
          accessor: 'BoardName',
          style:{
              textAlign: "center",
              width:'100px'
          },
          width: "150px",
         },
         {
          Header: 'Sprint',
          accessor: 'SprintName',
          style:{
              textAlign: "center",
              width:'100px'
          },
          width: "150px",
         },
         {
          Header: 'AssignedByName',
          accessor: 'AssignedByName',
          style:{
              textAlign: "center",
              width:'100px'
          },
          width: "150px",
         },
         {
          Header: 'AssignedTo',
          accessor: 'AssignedTo',
          style:{
              textAlign: "center",
              width:'100px'
          },
          width: "150px",
         },
         {
          Header: 'TaskType',
          accessor: 'TaskTypeName',
          style:{
              textAlign: "center",
              width:'100px'
          },
          width: "150px",
         },
         {
          Header: 'AllotedHrs',
          accessor: 'AllotedHrs',
          style:{
              textAlign: "center",
              width:'100px'
          },
          width: "150px",
         },
         {
          Header: 'DocCount',
          accessor: 'DocCount',
          style:{
              textAlign: "center",
              width:'100px'
          },
          width: "150px",
         },
         {
          Header: 'TaskStatus',
          accessor: 'TaskStatus',
          style:{
              textAlign: "center",
              width:'100px'
          },
          width: "150px",
         },
         {
          Header: 'Notes',
          accessor: 'Notes',
          style:{
              textAlign: "center",
              width:'100px'
          },
          width: "150px",
         },
         {
          Header: 'Billable',
          accessor: 'Billable',
          style:{
              textAlign: "center",
              width:'100px'
          },
          width: "150px",
          Cell: props => {
            return(
              <input type='checkbox'  name="GridChk1" checked={props.original.Billable === true}  style={{width:"100%"}} />
          )
        }
        },
        {
          Header: 'Estd.Start Date',
          accessor: 'EstStartDate',
          style:{
              textAlign: "center",
              width:'100px'
          },
          width: "150px",
         },
         {
          Header: 'Estd.End Date',
          accessor: 'EstStartDate',
          style:{
              textAlign: "center",
              width:'100px'
          },
          width: "150px",
         },
         {
          Header: 'Detail',
          accessor: 'Detail',
          style:{
              textAlign: "center",
              width:'100px'
          },
          width: "150px",
         },
         {
          Header: '',
          width: "40px",
          style:{
            textAlign: "center",
            width:'40px'
        },
        // width 40 added sp 09-09-2024
        width: 35,
        minWidth: 100,
        maxWidth: 35,
        // width 40 added sp 09-09-2024
          Cell: props => {
            return (
                <button className='edit-btn' style={{width:"100%"}} onClick={ () => {this.TaskAllotModalOpenMethod(this, props.original);}} >
                  <img src={Edit} alt="Edit" style={{color: '#06638F', height: '17px', borderWidth: '0px'}} />
                </button>
            )
        }
         },
         {
          Header: '',
          width: "40px",
          style:{
            textAlign: "center",
            width:'40px'
        },
        // width 40 added sp 09-09-2024
        width: 35,
        minWidth: 100,
        maxWidth: 35,
        // width 40 added sp 09-09-2024
          Cell: props => {
              return (
                  <button className='del-btn'style={{width:"100%"}} 
                  onClick={() => {this.DeleteTask(this, props.original);
                }}><img title="Delete" className="dxgvCommandColumnItem_Office2003Blue dxgv__cci" src={Delete} alt="Delete" style={{color: '#06638F', height: '17px', borderWidth: '0px',cursor: 'pointer'}} /></button>
              )
          },
        }
      ]

    return (
         <div>
                {this.state.LoaderShow === true ? (
          <div className='loader-img'>
            <img   src={Loader} height="50" width="50" alt="Helpdesk" />
          </div>
        ) : (
          ''
        )}
         <Header />

         <div id="five-panel" className="panel animated slideInRight">
      <h3 className="slide-title">

<table cellSpacing={0} cellPadding={0} border={0} style={{height: '100%', width: '100%', borderCollapse: 'collapse', maxWidth: "1695px"}}>
  <tbody><tr style={{height: '100%'}}>
      <td style={{color: '#333333', fontSize: '0.8em'}}>
        <table className="table-content" cellPadding="0px" cellSpacing="0px" width="100%">
          <tbody><tr>
              <td className="tdspace">
                <div id="ValidationSummary1" className="summaryerrors" style={{color: '#FF9200', display: 'none'}}>
                </div><table width="100%">
                  <tbody>
                    <tr>
                      <td className="heading_bg1">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <span id="lblProject" style={{fontSize:"18px"}}>Project Name : {this.state.EstimateAndAllotBackLogsDatas.ProjectName}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                  </table>
              </td>
            </tr>
            <tr>
              <td className="table-inner">
                <table width="100%">
                  <tbody>
                    <tr>
                      {/* <td>
                        <span id="lblProject1" style={{fontWeight: 'bold'}} />Project Name: {this.state.ProjectName}
                        <img src={gotask} alt="Image" />Module Name: {this.state.ModuleName}
                        <span id="LblModule1" style={{fontWeight: 'bold'}} />
                        <span id="lblError" />
                      </td> */}
                      <td align="right">
                        {/* <a id="lnkbnModList" href="javascript:WebForm_DoPostBackWithOptions(new WebForm_PostBackOptions(&quot;ctl00$MainContent$wzdEmpDetails$lnkbnModList&quot;, &quot;&quot;, true, &quot;&quot;, &quot;&quot;, false, true))">&lt;--&nbsp;Go to Task List</a> */}
                      </td>
                    </tr>
                  </tbody></table>
              </td>
            </tr>
            <tr>
              <td>
                <table width="100%" className="table-content" cellPadding="0px" cellSpacing="0px">
                  <tbody>
                    <tr>
                      <td>
                        <table width="100%">
                          <tbody>
                            <tr>
                              <td>
                                {/* float added and tr td added sp 09-09-2024 */}
                              <button id="btnNew1" className='edit-btn-submodule' style={{margin: '10px',float:'left',border:'none'}} onClick={this.AddTasksMethod}>
                              <img src={New} alt="add new" style={{color: '#06638F', height: '14px', borderWidth: '0px'}} />
                              
                              <span style={{fontSize:'14px',padding:'10px'}}>Add Tasks</span></button>
                              </td>
                            </tr>
                            <tr>
                           
                              <td valign="top" style={{display: "flex",padding:'0px 10px'}}>
                            {this.state.TableShow === true || this.state.GridAllotTableData.length !== 0? (
                                 <ReactTable
                                          columns={TaskNameColumns}
                                          data={this.state.TaskNameList}
                                          noDataText={"No Data..."}
                                          defaultPageSize={5}
                                          defaultFilterMethod={this.filterCaseInsensitive}
                                          >
                                  </ReactTable>
                             ) : (
                              ''
                             )}

                           {/* {this.state.TableShow === true || this.state.GridAllotTableData.length !== 0 ? (
                                 <ReactTable
                                          columns={TaskNameColumns2}
                                          data={this.state.TaskNameDatas}
                                          noDataText={"No Data..."}
                                          defaultPageSize={5}
                                          defaultFilterMethod={this.filterCaseInsensitive}
                                          >
                                  </ReactTable>
                             ) : (
                              ''
                             )} */}
                             
                              </td>
                            </tr>
                            <tr style={{margin: '10px',display: 'block'}}>

                              <td colSpan={2}>
                                <table>
                                  <tbody><tr>
                                      <td style={{paddingRight: 5}}>
                                        <table cellSpacing={0} cellPadding={0} id="btnPush" border={0} style={{borderCollapse: 'separate', KhtmlUserSelect: 'none'}}>
                                          <tbody><tr>
                                              <td id="btnPush_B" className="dxbButton_Aqua" align="center" style={{fontFamily: 'Calibri', fontSize: 15}}><div style={{borderWidth: 0, width: 0, height: 0, opacity: 0, padding: 0, margin: 0, overflow: 'hidden'}}>
                                                  <input defaultValue onfocus="aspxBGotFocus('btnPush')" type="submit" name="ctl00$MainContent$wzdEmpDetails$btnPush" readOnly="readonly" style={{borderWidth: 0, height: 1, width: 1, padding: 0, opacity: 0, margin: 0, position: 'relative'}} />
                                                </div>
                                                <div id="btnPush_CD" className="dxb">
                                                  <button onClick={this.PushToTasksMethod}>Push To Task Allotment</button>
                                                </div>
                                                </td>
                                            </tr>
                                          </tbody></table>
                                      </td>
                                      <td style={{paddingRight: 5}}>
                                        <table cellSpacing={0} cellPadding={0} id="btnduplicate" border={0} style={{borderCollapse: 'separate', KhtmlUserSelect: 'none'}}>
                                          <tbody><tr>
                                              <td id="btnduplicate_B" className="dxbButton_Aqua" align="center" style={{fontFamily: 'Calibri', fontSize: 15}}><div style={{borderWidth: 0, width: 0, height: 0, opacity: 0, padding: 0, margin: 0, overflow: 'hidden'}}>
                                                  <input defaultValue onfocus="aspxBGotFocus('btnduplicate')" type="submit" name="ctl00$MainContent$wzdEmpDetails$btnduplicate" readOnly="readonly" style={{borderWidth: 0, height: 1, width: 1, padding: 0, opacity: 0, margin: 0, position: 'relative'}} />
                                                </div><div id="btnduplicate_CD" className="dxb">
                                                <button onClick={this.DuplicateMethod}>Duplicate</button>
                                                </div></td>
                                            </tr>
                                          </tbody></table>
                                      </td>
                                      <td style={{paddingLeft: 5}}>
                                      </td>
                                      <td>&nbsp;
                                        <span id="lblMsgdelete" />
                                      </td>
                                    </tr>
                                  </tbody></table>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {/* <span id="lbl_info_dept_next">Information To Next Dept. :</span>
                                <input name="ctl00$MainContent$wzdEmpDetails$txt_next_dept" type="text" id="txt_next_dept" className="auto-style1" style={{width: 223}} /> */}
                              </td>
                              <td>&nbsp;</td>
                            </tr>
                            <tr>
                              <td colSpan={2} className='allot-grid'>
                              <ReactTable
                                          columns={GridAllotColumns}
                                          data={this.state.GridAllotTableData}
                                          noDataText={"No Data..."}
                                          defaultPageSize={5}
                                          filterable
                                          defaultFilterMethod={this.filterCaseInsensitive}
                                          >
                                  </ReactTable>
                               
                              </td>
                            </tr>
                            <tr>
                              <td>
                              
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input type="hidden" name="ctl00$MainContent$wzdEmpDetails$hfconfirmbox1" id="hfconfirmbox1" />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input type="hidden" name="ctl00$MainContent$wzdEmpDetails$hfconfirmbox2" id="hfconfirmbox2" />
                              </td>
                            </tr>
                          </tbody></table>
                      </td>
                    </tr>
                  </tbody></table>
              </td>
            </tr>
          </tbody></table>
      </td>
    </tr>
  </tbody></table>

      </h3>
      {/* h3 tag below sp 09-09-2024 style removed and classname tab-label added*/}
  <label htmlFor="radio2" id="second-tab" className="tab">
        <span>
          <button onClick={ () => {this.handletab4();}} style={{backgroundColor: "#4c4a48",color: "#fff",border: "none",padding: "7px 15px",marginTop: "10px",borderRadius: "6px",marginRight: "10px"}}  className='tab-label' type='submit' >Previous</button>
        </span>
    </label>
    </div>



         <Footer />

 {/* Modal For Grid Task Allot Start */}

{this.state.TaskAllotModalShow && (
          <div className="popup-create">
          <div className='popup-body'>
        <div id="Grid_DXPEForm_PW-1" className="dxpcLite_Office2003Blue dxpclW dxpc-mainDiv dxpc-shadow" style={{fontFamily: 'Calibri',padding: '20px', fontSize: 15, width: 498, cursor: 'default', visibility: 'visible',overflow:"auto",minHeight: "300px", maxHeight: "500px"}}>
  <div className="popup-header" style={{fontFamily: 'Calibri', fontSize: 15,width: "100%" , display: "inline-block"}} id="Grid_DXPEForm_PWH-1">
    <div className="dxpc-headerContent" style={{float:'left'}}>
      <span className="dxpc-headerText" id="Grid_DXPEForm_PWH-1T">Edit Form</span>
    </div>
    <button class="popup-closebtn" onClick={this.TaskAllotModalCloseMethod} style={{float:'right'}}>X</button>
    <b className="dx-clear" />
  </div><div className="dxpc-contentWrapper" >
    <div className="dxpc-content" style={{fontFamily: 'Calibri', fontSize: 15, padding: 0, display: 'block'}} id="Grid_DXPEForm_PWC-1">
      <div className="dxgvPopupEditForm_Office2003Blue"  style={{overflowX: 'hidden'}}>
      <Formik
          initialValues={{
            // Taskdate:this.state.EditTaskAllotDatas.TaskDate,
            Projectname:this.state.EditTaskAllotDatas.ProjectName,
            Modulename:this.state.EditTaskAllotDatas.ModuleName,
            Screenname1:this.state.EditTaskAllotDatas.TaskName,
            Task:this.state.EditTaskAllotDatas.Task,
            Board:this.state.EditTaskAllotDatas.BoardID,
            Scrum:this.state.EditTaskAllotDatas.SprintID,
            Assingedby:this.state.EditTaskAllotDatas.AssignedByID,
            Assingedto:this.state.EditTaskAllotDatas.AssignedToID,
            Tasktype:this.state.EditTaskAllotDatas.TaskType,
            Hours:this.state.EditTaskAllotDatas.AllotedHrs,
            // DocCount:this.state.EditTaskAllotDatas.DocCount,
            Taskstatus:this.state.EditTaskAllotDatas.TaskStatusID,
            // Notes:this.state.EditTaskAllotDatas.Notes,
            // Billable:this.state.EditTaskAllotDatas.Billable,
            // Eststartdate:this.state.EditTaskAllotDatas.EstStartDate,
            // Estenddate:this.state.EditTaskAllotDatas.EstEndDate,
          }}
          validationSchema={TaskAllotPopUpvalidation}
          onSubmit={Values => {
            console.log('moduleeditpopupvalues', Values);
            var DocCount = document.getElementById('DocCount').value;
            var Billable = document.getElementById('IsBillable').checked;
            var Notes = document.getElementById('Notes1').value;
            var TaskDate = document.getElementById('GridAllotTaskDate').value;
            var StartDate = document.getElementById('EstdStartDate').value;
            var EndDate = document.getElementById('EstdEndDate').value;
            var EditTask = 'https://helpdeskservice.desss-portfolio.com/api/GridAllotProjectUpdate/UpdateGridAllot?PrjTaskAllotID='+ this.state.PrjTaskAllotID +'&CompanyID='+ this.state.LoginDatas.CompanyID +'&ProjectID=' + this.state.EstimateAndAllotBackLogsDatas.ProjectID + '&ModuleID='+this.state.ModuleID+'&AllotedHrs='+Values.Hours+'&DocCount='+DocCount+'&EmpID='+ Values.Assingedto +'&TaskStausID='+Values.Taskstatus+'&TaskName='+Values.Screenname1+'&Assignedto='+Values.Assingedby+'&TaskType='+Values.Tasktype+'&Billable='+Billable+'&Task='+Values.Task+'&Notes='+Notes+'&TaskCategoryID='+Values.Tasktype+'&SubModuleID='+this.state.EstimateAndAllotBackLogsDatas.PrjTaskID+'&TaskDate='+TaskDate+'&UserName='+this.state.LoginDatas.EmpName+'&EstdStartDate='+StartDate+'&EstdEndDate='+EndDate+'&SprintID='+Values.Scrum+'&BoardID=' + Values.Board;
            axios.post(EditTask)
            .then(EditTaskUpdate => {
               this.setState({ TaskAllotModalShow: false });
               Swal.fire({
                icon: "success",
                title: "Updated Successfully",
                showConfirmButton: false,
                timer: 1500
              });

              //  this.SearchMethod();
              this.componentDidMount();
              console.log('EditTaskUpdate',  EditTaskUpdate.data.data);
            })
            .catch(error => {
             this.setState({ TaskAllotModalShow: false });
              console.log(error);
              // this.SearchMethod();
              this.componentDidMount();
            })
          }}
        >

{formik => (
   <Form>
        <table id="Grid_DXPEForm_DXEFT" class="submodule-newpopup" cellSpacing={0} cellPadding={0} border={0} style={{fontFamily: 'Calibri', fontSize: 15, width: '100%', borderCollapse: 'collapse'}}>
          <tbody>
            <tr style={{display:'flex'}}>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Task Date</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%',display: "flex",flexDirection: "row-reverse"}}>

              <input name="editmoduleFinaldate1" onChange={this.GridAllotTaskDateChange} type="date" style={{width: '8%'}} />
              <input name="editmoduleFinaldate" id="GridAllotTaskDate" defaultValue={this.state.EditTaskAllotDatas.TaskDate} type="text" style={{width: '100%'}} readOnly/>

                {/* <Field name="Taskdate" defaultValue={this.state.EditTaskAllotDatas.TaskDate} type="text" id='Taskdate' style={{width: '100%'}} /> */}
                  {/* <ErrorMessage component="label" className=" form-label text-danger" name="Taskdate" /> */}
              </td>
            </tr>

            <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Project Name</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <Field name="Projectname" defaultValue={this.state.EditTaskAllotDatas.ProjectName} type="text" style={{width: '100%'}} readOnly/>
                  <ErrorMessage component="label" className=" form-label text-danger" name="Projectname" />
              </td>
            </tr>

            <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Module Name</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              {/* <p>Default Date: {this.state.EstStartDate}</p> */}
              {/* <DatePicker name="edittaskallotstartdate" selected={this.state.EstStartDate} style={{width: '100%'}} dateFormat="dd-mm-yyyy"/> */}
              <Field name="Modulename" id="Modulename" defaultValue={this.state.EditTaskAllotDatas.ModuleName} type="text" style={{width: '100%'}} readOnly/>
                  <ErrorMessage component="label" className=" form-label text-danger" name="Modulename" />
              </td>
            </tr>

            <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>WebPage/Screen Name</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <Field name="Screenname1" id="Screenname1" defaultValue={this.state.EditTaskAllotDatas.TaskName} type="text" style={{width: '100%'}} readOnly/>
                  <ErrorMessage component="label" className=" form-label text-danger" name="Screenname1" />
              </td>
            </tr>

            <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Task</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              {/* <DatePicker  name="editmoduleFinaldate1"  selected={this.state.FinalEndDate} style={{width: '100%'}} dateFormat="dd-mm-yyyy"/> */}
              <Field name="Task" id="Task" defaultValue={this.state.EditTaskAllotDatas.Task} type="text" style={{width: '100%'}} readOnly/>
                  <ErrorMessage component="Task" className=" form-label text-danger" name="Task" />
              </td>
            </tr>

            <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Board</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <Field name="Board" id='Board' as="select" style={{width: '100%'}} >
              <option value={0}>--Select--</option>
                    { this.state.BoardDatas.map((element) => 
                      <option  value={element.BoardID}>{element.BoardName}</option>
                    )}
                    </Field>                  
                    <ErrorMessage component="label" className="form-label text-danger" name="Board" />
              </td>
            </tr>

            <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Sprint</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <Field name="Scrum" id='Scrum' as="select" style={{width: '100%'}} >
              <option value={0}>--Select--</option>
                    { this.state.SprintDatas.map((element) => 
                      <option  value={element.SprintID}>{element.SprintName}</option>
                    )}
              </Field>
               <ErrorMessage component="label" className="form-label text-danger" name="Scrum" />
              </td>
            </tr>

            <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Assinged By</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <Field name="Assingedby" id='Assingedby' as="select" style={{width: '100%'}} >
              <option>--Select--</option>
                    { this.state.AssingedbyDatas.map((element) => 
                      <option  value={element.EmpID}>{element.EmpName}</option>
                    )}
              </Field>
               <ErrorMessage component="label" className=" form-label text-danger" name="Assingedby" />
              </td>
            </tr>

            <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Assinged To</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <Field name="Assingedto" id='Assingedto' as="select" style={{width: '100%'}} >
              <option>--Select--</option>
                    { this.state.AssingedToDatas.map((element) => 
                      <option  value={element.EmpID}>{element.EmpName}</option>
                    )}
              </Field>
               <ErrorMessage component="label" className=" form-label text-danger" name="Assingedto" />
              </td>
            </tr>

            <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Task Type</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <Field name="Tasktype" id='Tasktype' as="select" style={{width: '100%'}} selected={this.state.EditTaskAllotDatas.TaskType}>
              <option>--Select--</option>
                    { this.state.TaskTypeDatas.map((element) => 
                      <option  value={element.ModuleTypeID}>{element.ModuleTypeName}</option>
                    )}
              </Field>
                  <ErrorMessage component="label" className=" form-label text-danger" name="Tasktype" />
              </td>
            </tr>

            <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Hours</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              {/* <DatePicker  name="editmoduleFinaldate1"  selected={this.state.FinalEndDate} style={{width: '100%'}} dateFormat="dd-mm-yyyy"/> */}
              <Field name="Hours" id="Hours" defaultValue={this.state.EditTaskAllotDatas.Hours} type="number" style={{width: '100%'}}/>
                  <ErrorMessage component="Hours" className=" form-label text-danger" name="Hours" />
              </td>
            </tr>

            <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Doc Count</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              {/* <DatePicker  name="editmoduleFinaldate1"  selected={this.state.FinalEndDate} style={{width: '100%'}} dateFormat="dd-mm-yyyy"/> */}
              <input name="DocCount" id="DocCount" defaultValue={this.state.DocCount} type="number" style={{width: '100%'}}/>
              </td>
            </tr>

            <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Task Status</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <Field name="Taskstatus" id='Taskstatus' as="select" style={{width: '100%'}} defaultValue={this.state.EditTaskAllotDatas.TaskStatusID}>
              <option>--Select--</option>
                    { this.state.TaskstatusDatas.map((element) => 
                      <option  value={element.TaskStatusID}>{element.TaskStatusDescription}</option>
                    )}
              </Field>
              {/* <Field name="Taskstatus" id="Taskstatus" defaultValue={this.state.EditTaskAllotDatas.TaskStatus} type="text" style={{width: '100%'}}/> */}
                  <ErrorMessage component="Taskstatus" className=" form-label text-danger" name="Taskstatus" />
              </td>
            </tr>

            <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Notes</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
               <textarea  name="Notes1" id='Notes1' type="textarea" defaultValue={this.state.Notes1} style={{width: '100%'}} />  
              {/* <Field name="Notes" id="Notes" defaultValue={this.state.EditTaskAllotDatas.Notes} type="text" style={{width: '100%'}}/>
                  <ErrorMessage component="Notes" className=" form-label text-danger" name="Notes" /> */}
              </td>
            </tr>

            <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>IsBillable</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
                <input name="IsBillable" id='IsBillable' onChange={this.IsBillableChange} checked={this.state.IsBillable === true} type="checkbox" style={{width: '8%'}} />  
              {/* <Field name="Billable" id="Billable" checked={this.state.EditTaskAllotDatas.Billable} type="checkbox" style={{width: '100%'}}/>
                  <ErrorMessage component="Billable" className=" form-label text-danger" name="Billable" /> */}
              </td>
            </tr>

            <tr style={{display:'flex'}}>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Estd.Start Date</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%',display: "flex",flexDirection: "row-reverse"}}>

              <input name="editmoduleFinaldate1" onChange={this.EstdStartDateChange} type="date" style={{width: '8%'}} />
              <input name="editmoduleFinaldate" id="EstdStartDate" defaultValue={this.state.EditTaskAllotDatas.EstStartDate} type="text" style={{width: '100%'}} readOnly/>

              </td>
            </tr>

            <tr style={{display:'flex'}}>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Estd.End Date</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%',display: "flex",flexDirection: "row-reverse"}}>

              <input name="editmoduleFinaldate1" onChange={this.EstdEndDateChange} type="date" style={{width: '8%'}} />
              <input name="editmoduleFinaldate" id="EstdEndDate" defaultValue={this.state.EditTaskAllotDatas.EstEndDate} type="text" style={{width: '100%'}} readOnly/>

              </td>
            </tr>

          </tbody>
        </table>

       <div class="popup-update">
        <button type='submit' onClick={this.test} >Update</button>
        <button onClick={this.TaskAllotModalCloseMethod}>Close</button>
        </div>
        </Form>
    )}

    </Formik>
      </div>
    </div>
  </div>

  
        </div>

          </div>            

            
          </div>
        )}
{/* Modal For Grid Task Allot End */}

      </div>
    )
  }
}

export default backlogestimateandallot