import React, { Component } from 'react'
import Header from './header';
import Footer from './footer';

export class Unassignededit extends Component {
  render() {
    return (
      <div>
        <Header />

        <div class="issue">
            <form>
                <div className='issue-left'>
                    <div className='issue-filed'>
                        <label>ISSUE NAME</label>
                        <input type='text' />
                    </div>
                    <div className='issue-filed'>
                        <label>PROJECT NAME</label>
                        <select name="system_ton" id="system_ton1" class="select_new MB10 fleft" style={{marginleft:"10px"}}>
                            <option value="">-</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                        </select>
                    </div>
                    <div className='issue-filed'>
                        <label>MODULE/EPIC</label>
                        <select name="system_ton" id="system_ton1" class="select_new MB10 fleft" style={{marginleft:"10px"}}>
                                <option value="">-</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                        </select>
                    </div>
                    <div className='issue-filed'>
                        <label>WORK FLOW</label>
                        <select name="system_ton" id="system_ton1" class="select_new MB10 fleft" style={{marginleft:"10px"}}>
                                <option value="">-</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                        </select>
                    </div>
                    <div className='issue-filed'>
                        <label>BOARD</label>
                        <select name="system_ton" id="system_ton1" class="select_new MB10 fleft" style={{marginleft:"10px"}}>
                                <option value="">-</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                        </select>
                    </div>
                    <div className='issue-filed'>
                        <label>DESCRIPTION</label>
                        <textarea type='textarea'  />
                    </div>
                    <div className='issue-filed'>
                        <label>Estimation</label>
                        <input type='checkbox'  />
                    </div>
                    <div className='issue-filed'>
                        <label>Estimation</label>
                        <input type='text'  />
                    </div>
                    <div className='issue-filed'>
                        <label>REPORTED BY</label>
                        <select name="system_ton" id="system_ton1" class="select_new MB10 fleft" style={{marginleft:"10px"}}>
                                <option value="">-</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                        </select>
                    </div>
                    <div className='issue-filed'>
                        <label>EMAIL</label>
                        <input type='checkbox'  />
                    </div>
                    <div className='issue-filed'>
                        <label>IsACTIVE</label>
                        <input type='checkbox'  />
                    </div>
                    <div className='issue-filed'>
                        <label>IsBillable</label>
                        <input type='checkbox'  />
                    </div>
                    <div className='issue-filed'>
                        <label>ALLOTTED TO</label>
                        <select name="system_ton" id="system_ton1" class="select_new MB10 fleft" style={{marginleft:"10px"}}>
                                <option value="">-</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                        </select>
                    </div>
                    <div className='issue-filed'>
                        <label>TASK DATE:</label>
                        <input type='date'  />
                    </div>
                    <div className='issue-filed radio'>
                    <input type="radio" id="html" name="fav_language" value="HTML" />
                        <label for="html">Low</label>
                        <input type="radio" id="css" name="fav_language" value="CSS" />
                        <label for="css">Medium</label>
                        <input type="radio" id="javascript" name="fav_language" value="JavaScript" />
                        <label for="javascript">High</label>
                        <input type="radio" id="javascript" name="fav_language" value="JavaScript" />
                        <label for="javascript">Critical</label>
                    </div>
                </div>


                <div className='issue-right'>
                    <div className='issue-filed'>
                        <label>ISSUE TYPE</label>
                        <select name="system_ton" id="system_ton1" class="select_new MB10 fleft" style={{marginleft:"10px"}}>
                                <option value="">-</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                        </select>
                    </div>
                    <div  className='issue-filed'>
                        <button>Add Project</button>
                    </div>
                    <div  className='issue-filed'>
                        <button>Add Module</button>
                    </div>
                    <div  className='issue-filed'>
                        <button>Add Work Flow</button>
                    </div>
                    <div className='issue-filed'>
                        <label>SPRINT</label>
                        <select name="system_ton" id="system_ton1" class="select_new MB10 fleft" style={{marginleft:"10px"}}>
                                <option value="">-</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                        </select>
                    </div>
                    <div className='issue-filed'>
                        <label>SUB TASK</label>
                        <input type='checkbox'  />
                    </div>
                    <div className='issue-filed'>
                        <label>Actual Hours</label>
                        <input type='text'  />
                    </div>
                    <div className='issue-filed'>
                        <label>CARD VIEW STATUS</label>
                        <select name="system_ton" id="system_ton1" class="select_new MB10 fleft" style={{marginleft:"10px"}}>
                                <option value="">-</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                        </select>
                    </div>
                    <div className='issue-filed'>
                        <label>Search Email :</label>
                        <input type='text'  />
                    </div>
                    <div className='issue-filed'>
                        <label>Cc :</label>
                        <textarea type='textarea'  />
                    </div>
                    <div  className='issue-filed'>
                        <button>Add Employee</button>
                    </div>  
                    <div className='issue-filed'>
                        <label>LINK ISSUE</label>
                        <select name="system_ton" id="system_ton1" class="select_new MB10 fleft" style={{marginleft:"10px"}}>
                                <option value="">-</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                        </select>
                    </div>
                    <div className='issue-filed'>
                        <label>Ticket No</label>
                        <input type='text'  />
                    </div>
                    <div className='issue-filed'>
                        <label>SOURCE</label>
                        <select name="system_ton" id="system_ton1" class="select_new MB10 fleft" style={{marginleft:"10px"}}>
                                <option value="">-</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                        </select>
                    </div>
                    <div className='issue-filed'>
                        <label>Reviewed By :</label>
                        <select name="system_ton" id="system_ton1" class="select_new MB10 fleft" style={{marginleft:"10px"}}>
                                <option value="">-</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                        </select>
                    </div>
                    <div className='issue-filed'>
                        <label>Review Notes :</label>
                        <textarea type='textarea'  />
                    </div>
                    <div className='issue-filed'>
                        <label>Reviewed :</label>
                        <input type='checkbox'  />
                    </div>
                    <div className='issue-filed'>
                        <label>ATTACHMENT FILES :</label>
                        <input type='file'  />
                    </div>
                </div>
                <div className='save-issue'>
                    <button>SAVE TASK FOR NOW</button>
                    <button>ASSIGN NOW</button>
                </div>
            </form>
        </div>
        <div className='free-space'></div>
        <Footer />
      </div>
    )
  }
}

export default Unassignededit