import React, { Component } from 'react'
import Header from './header'
import refresh from '../images/refresh.png'
import Footer from './footer'
import axios from 'axios';
// import Delete from '../images/new_delete_ic.png';
import Delete from '../images/new_del_ic_1.png';
import ReactTable from "react-table-6";
import Swal from 'sweetalert2';
import Loader from '../images/loader2.gif';
import showmore from '../images/show_more_btn.png'
import Edit from '../images/edit_icon.png'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import New from '../images/add.png';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';


export class AllotTaskToSprint extends Component {

  constructor() {
		super();
		this.state = {
      ProjectDatas:[],
      LoginDatas:  JSON.parse(localStorage.getItem("HelpDeskLoginDatas")),
      Auth:  JSON.parse(localStorage.getItem("auth")),
      SprintAllotDatas :  JSON.parse(localStorage.getItem("AllotTaskToSprintData")),
      LoaderShow: false,
      AddShowPopup: false,
      EditShowPopup: false,
      BoardMemberPopup: false,
      EditBoardName: '',
      EditBoardID: '',
      EditProjectID: '',
      showModal1: false,
      showModal2: false,
      showModal3: false,
      BoardDatas:[],
      BoardMembersDatas: [],
      PendingDatas: [],
      SprintDetailsDatas: [],
      PushTaskList:[],
	  };
	}

componentDidMount(){
  console.log('SprintDatas', this.state.SprintAllotDatas);
    const Auth =  JSON.parse(localStorage.getItem("auth"));
      if(!Auth){
       this.props.history.push('/login');
      }

    this.PendingDatasMethod();
    this.SprintDetailsDatasMethod();
  }

  PendingDatasMethod(){
  var Datas = [];
  this.setState({ PendingDatas: Datas });
  this.setState({ LoaderShow: true });

  var URL = 'https://helpdeskservice.desss-portfolio.com/api/GetPendingSprintData/LoadPendingTaskGrid?ProjectID='+ this.state.SprintAllotDatas.ProjectID;
  axios.post(URL)
  .then(res => {

  var data = res.data.data;
  console.log('PendingDatas', data);
  if(data[0].errorMsg === "No Data Found"){
    return;
  }else{
   data.forEach((key, value) => {
     Datas.push({
      ProjectName: key.ProjectName,
      ProjectID: key.ProjectID,
      TaskName:  key.TaskName,
      EmpName:  key.EmpName,
      AllotedHrs:  key.AllotedHrs,
      Sequence:  key.Sequence,
      PrjTaskAllotID:  key.PrjTaskAllotID,
      TaskDate:  moment(key.TaskDate).format("MM/DD/YYYY"),
      Description:  key.Description,
      });
     })
     this.setState({ PendingDatas: Datas }); 
    //  console.log('PendingDatas', this.state.PendingDatas);
  }
     this.setState({ LoaderShow: false });
  })
  .catch(error => {
    this.setState({ LoaderShow: false });
    console.log(error);
  })
}


SprintDetailsDatasMethod(){
  var Datas = [];
  this.setState({ SprintDetailsDatas: Datas });
  this.setState({ LoaderShow: true });

  var URL = 'https://helpdeskservice.desss-portfolio.com/api/GetSprintData/LoadSprintDetails?SprintID='+ this.state.SprintAllotDatas.SprintID;
  axios.post(URL)
  .then(res => {

  var data = res.data.data;
  if(data[0].errorMsg === "No Data Found"){
    return;
  }else{
   data.forEach((key, value) => {
     Datas.push({
      ProjectName: key.ProjectName,
      ProjectID: key.ProjectID,
      TaskName:  key.TaskName,
      EmpName:  key.EmpName,
      AllotedHrs:  key.AllotedHrs,
      ActualHours:  key.ActualHours,
      SprintID:  key.SprintID,
      PrjTaskAllotID:  key.PrjTaskAllotID,
      TaskDate:  moment(key.TaskDate).format("MM/DD/YYYY"),
      Description:  key.Description,
      });
     })
     this.setState({ SprintDetailsDatas: Datas }); 
     console.log('SprintDetailsDatas', this.state.SprintDetailsDatas);
  }
     this.setState({ LoaderShow: false });
  })
  .catch(error => {
    this.setState({ LoaderShow: false });
    console.log(error);
  })
}

filterCaseInsensitive = (filter, row) => {
  const id = filter.pivotId || filter.id;
  const content = row[id];
  if (typeof content !== 'undefined') {
      // filter by text in the table or if it's a object, filter by key
      if (typeof content === 'object' && content !== null && content.key) {
          return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
      } else {
          return String(content).toLowerCase().includes(filter.value.toLowerCase());
      }
  }

  return true;
}

PushTaskCheckBoxmethod = (event) => {
  var checked = event.target.checked;
  var Value = event.target.value;
  if(checked === true){
    this.setState({
      PushTaskList: this.state.PushTaskList.concat(Value)
    })
  }else if(checked === false){
    var array = this.state.PushTaskList;
    var index = array.indexOf(Value); 
    delete array[index];
  }
  console.log('PushTaskList Datas1221212', this.state.PushTaskList);
  console.log('PushTaskList box Datas12', event.target.checked, event.target.value);
}

PushToTasksMethod = () => {
  const Tasks = this.state.PushTaskList.join(',');
  console.log('PushToTasksMethod Method', Tasks);
  this.setState({ LoaderShow: true});
  var AddURL = 'https://helpdeskservice.desss-portfolio.com/api/AddSprint/AddsprintButton?PrjTaskAllotID='+Tasks+'&SprintID=' + this.state.SprintAllotDatas.SprintID;

  axios.post(AddURL)
  .then(Response => {
    this.state.PushTaskList = [];
   console.log('AddURL', Response.data.data[0].errorMsg);
   if(Response.data.data[0].errorMsg === "Sprint Added Successfully"){
    Swal.fire({
      icon: "success",
      title: "Sprint Added Successfully",
      showConfirmButton: false,
      timer: 1500
    });
   }else{
    Swal.fire({
      icon: "error",
      title: "Something went wrong",
      showConfirmButton: false,
      timer: 3000
    });
   }
   this.setState({ PushTaskList: []});
  // alert('Task Allotted Succesfully')
  this.setState({ LoaderShow: false});
  this.componentDidMount();
  })
  .catch(error => {
    Swal.fire({
      icon: "error",
      title: "Something went wrong",
      showConfirmButton: false,
      timer: 3000
    });
  console.log(error);
  this.state.PushTaskList = [];
  this.componentDidMount();
  this.setState({ LoaderShow: false});
  })
}

DeleteSprintMethod(res, datas){
  Swal.fire({
    title: "Are you sure?",
    text: "",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!"
  }).then((result) => {
    

  if (result.isConfirmed) {

  this.setState({ LoaderShow: true});
  var URL = 'https://helpdeskservice.desss-portfolio.com/api/DeleteSprintDetails/Delete?SprintID=' + datas.SprintID;
  axios.delete(URL)
  .then(res => {
   this.setState({ LoaderShow: false});
  //  alert('Task Deleted Successfully');
  //  this.SearchMethod();
    Swal.fire({
      icon: "success",
      title: "Task has been deleted",
      showConfirmButton: false,
      timer: 1500
     });
     this.componentDidMount();
 
  })
  .catch(error => {
    this.setState({ LoaderShow: false});
    Swal.fire({
      icon: "error",
      title: "Something went wrong",
      showConfirmButton: false,
      timer: 3000
    });
    this.componentDidMount();
    console.log(error);
  })     
    
 }

});

}

  render() {

    const PendingTaskColumns = [
      {
        Header: 'Select',
        accessor: 'Select',
        style:{
            textAlign: "center",
            width:'100px'
        },
        width: "150px",
        Cell: props => {
          return(
          <input type='checkbox' onChange={this.PushTaskCheckBoxmethod} value={props.original.PrjTaskAllotID}  name="GridChk" style={{width:"100%"}} />
        )
      }
      },
      {
        Header: 'Backlog/Task',
        accessor: 'TaskName',
        width: "100%",
      },
      {
          Header: 'Project Name',
          accessor: 'ProjectName',
          style:{
              textAlign: "center"
          },
          width: "100%",
         },
      {
       Header: 'Task Category',
       accessor: 'Description',
       style:{
           textAlign: "center"
       },
       width: "100%",
      },
      {
        Header: 'AssignedTo',
        accessor: 'EmpName',
        style:{
          textAlign: "center"
      },
      width: "100%",
      },
      {
        Header: 'Alloted Hours',
        accessor: 'AllotedHrs',
        style:{
          textAlign: "center"
      },
      width: "100%",
      },
      {
        Header: 'Sequence',
        accessor: 'Sequence',
        style:{
          textAlign: "center"
      },
        width: "100%",
      },
      {
        Header: 'TaskDate',
        accessor: 'TaskDate',
        style:{
          textAlign: "center"
      },
      width: "100%",
      }
]

const SprintDetailsColumns = [

  {
    Header: 'Backlog/Task',
    accessor: 'TaskName',
    width: "100%",
  },
  {
      Header: 'Project Name',
      accessor: 'ProjectName',
      style:{
          textAlign: "center"
      },
      width: "100%",
     },
  {
   Header: 'Task Category',
   accessor: 'Description',
   style:{
       textAlign: "center"
   },
   width: "100%",
  },
  {
    Header: 'AssignedTo',
    accessor: 'EmpName',
    style:{
      textAlign: "center"
  },
  width: "100%",
  },
  {
    Header: 'Alloted Hours',
    accessor: 'AllotedHrs',
    style:{
      textAlign: "center"
  },
  width: "100%",
  },
  {
    Header: 'Completed Hours',
    accessor: 'ActualHours',
    style:{
      textAlign: "center"
  },
    width: "100%",
  },
  {
    Header: 'TaskDate',
    accessor: 'TaskDate',
    style:{
      textAlign: "center"
  },
  width: "100%",
  },

  {
    Header: 'Delete',
    style:{
      textAlign: "center"
  },
    width: 100,
    minWidth: 100,
    maxWidth: 100,
  Cell: props => {
    return (
        <button className='del-btn'style={{width:"100%"}} 
        onClick={() => {this.DeleteSprintMethod(this, props.original);
      }}><img title="Delete" className="dxgvCommandColumnItem_Office2003Blue dxgv__cci" src={Delete} alt="Delete" style={{color: '#06638F', height: '17px', borderWidth: '0px',cursor: 'pointer'}} /></button>
    )
},
  }
]

    return (
      <div>

         {this.state.LoaderShow === true ? (
          <div className='loader-img'>
            <img   src={Loader} height="50" width="50" alt="Helpdesk" />
          </div>
        ) : (
          ''
        )}

        <Header />
        <div className="centerBG" style={{width: "90%",margin: "0px auto", maxWidth: "1695px"}}>

       <table style={{}}>
  <tbody>
    <tr>
      <td>
        <table>
          <tbody>
            <tr className="heading_bg">
              <td style={{fontSize: 25}}>
               Pending Tasks
              </td>
              <td>
                <Link to="/sprint-function" className='edit-btn-submodule' style={{}} onClick={() => {localStorage.setItem("SprintProjectID", '');}}>
                  <span style={{fontSize:'14px'}}>Back to sprint</span>
                </Link>
              </td>
            </tr>
          </tbody>
        </table>
        </td>
       
    </tr>
    <tr>
      <td>
        <table>
          <tbody>
            <tr id="Grid_DXDataRow0" className="dxgvDataRow_Office2003Blue" style={{fontFamily: 'Calibri', fontSize: 15}}>
              <td>
                <ReactTable
                  columns={PendingTaskColumns}
                  data={this.state.PendingDatas}
                  noDataText={"No Data..."}
                  filterable
                  defaultPageSize={10}
                  defaultFilterMethod={this.filterCaseInsensitive}
                  >
                </ReactTable>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>


<table style={{}}>
  <tbody>
  <tr>
      <td>
        <table>
          <div>
            <div className="heading_bg alot_tsk_sprnt">
              <div style={{fontSize: 25}}>
                Sprint Details: {this.state.SprintAllotDatas.SprintName}
              </div>
              <div id="btnPush_CD" className="dxb">
                <button onClick={this.PushToTasksMethod} style={{fontSize:'14px'}}>Add to Sprint</button>
              </div>
            </div>
          </div>
        </table>
        </td>
       
    </tr>
    <tr>
      <td>
        <table>
          <tbody>
            <tr id="Grid_DXDataRow0" className="dxgvDataRow_Office2003Blue" style={{fontFamily: 'Calibri', fontSize: 15}}>
              <td>
                <ReactTable
                  columns={SprintDetailsColumns}
                  data={this.state.SprintDetailsDatas}
                  noDataText={"No Data..."}
                  filterable
                  defaultPageSize={10}
                  defaultFilterMethod={this.filterCaseInsensitive}
                  >
                </ReactTable>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>

        </div>
        <div className='free-space'></div>
        <Footer />
      </div>
    )
  }
}

export default AllotTaskToSprint