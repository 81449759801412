import React, { Component } from 'react';
import Footer from '../Home/footer';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import Loader from '../images/loader2.gif';

const Validation = Yup.object({

    CmpID: Yup.string()
    .required('Company ID is Required'),
    EmailId: Yup.string()
    .email('Enter Valid email...')
    .required('Email Id is Required')
  });

  const Validation2 = Yup.object({
    Answer: Yup.string()
      .required('Password is Required...')
  });

export class ForgotPassword extends Component {
    constructor() {
		super();
		this.state = {
            LoaderShow: false,
            Validation: true,
            Validation2: false,
            PasswordShow: false,
            CompanyID: '',
            EmailID: '',
            SecureQuestion: '',
            SecureAnswer: '',
            Password: '',
	  };
	}

  render() {
    return (
      <div>
         {this.state.LoaderShow === true ? (
          <div className='loader-img'>
            <img   src={Loader} height="50" width="50" alt="Helpdesk" />
          </div>
        ) : (
          ''
        )}

{this.state.Validation === true ? (
    <Formik
          initialValues={{
            CmpID: '',
            EmailId: '',
          }}
          validationSchema={Validation}
          onSubmit={values => {
            console.log('Validation', values);
            this.setState({ LoaderShow: true });  

                    axios.get('https://helpdeskservice.desss-portfolio.com/api/ForgetPassword/Continue?CompanyID=' + values.CmpID + '&EmailID=' + values.EmailId)
                    .then(response => {
                    console.log('response', response.data.data[0]);
                    this.setState({ EmailID: values.EmailId });
                    this.setState({ CompanyID: values.CmpID });
                    this.setState({ LoaderShow: false });  
                    this.setState({ Validation: false });
                    this.setState({ Validation2: true });
                    this.setState({ PasswordShow: false });
                    this.setState({ SecureQuestion: response.data.data[0].SecureQuestion });
                    this.setState({ SecureAnswer: response.data.data[0].SecureAnswer });
                  })
                   .catch(error => {
                    console.log('error', error);
                    this.setState({ LoaderShow: false });  
                    alert('Invalid Authentication!');
                    })
          }}
        >

{formik => (
  
   <Form style={{width:'30%',margin:'auto'}}>
    <div className='popup-update' style={{textAlign:'right',marginBottom:'10px'}}>
      <button onClick={ () => {this.props.history.push('/login');}}>Back</button>
    </div>
        <table id="Grid_DXPEForm_DXEFT" class="submodule-newpopup" cellSpacing={0} cellPadding={0} border={0} style={{fontFamily: 'Calibri', fontSize: 15, width: '100%', borderCollapse: 'collapse', padding:'25px',display:'flex',border:'1px solid #4c4a48',borderRadius:'10px',marginBottom:'10px'}}>
          <tbody style={{width:'100%'}}>
          <label className='popup-label'>Forgot Password</label>
          <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Company ID</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <Field name="CmpID" type="text" style={{width: '100%'}} />
                  <ErrorMessage component="label" className=" form-label text-danger" name="CmpID" />
              </td>
            </tr>

            <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Email ID</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <Field name="EmailId" type="text" style={{width: '100%'}} />
                  <ErrorMessage component="label" className=" form-label text-danger" name="EmailId" />
              </td>
            </tr>
          </tbody>
        </table>

       <div class="popup-update">
        <button type='submit' >Save</button>
        </div>
        </Form>
    )}
      </Formik>
        ) : (
          ''
        )}

{this.state.Validation2 === true ? (
  <Formik
          initialValues={{
            Answer: '',
          }}
          validationSchema={Validation2}
          onSubmit={values => {
            console.log('Validation', values);
            if(values.Answer === this.state.SecureAnswer){
                this.setState({ LoaderShow: true }); 
                axios.get('https://helpdeskservice.desss-portfolio.com/api/ForgetPassword/GetPassword?CompanyID=' + this.state.CompanyID + '&EmailID=' + this.state.EmailID)
                    .then(response1 => {
                    console.log('response', response1.data.data[0]);
                    this.setState({ Password: response1.data.data[0].Password });
                    this.setState({ LoaderShow: false });
                    this.setState({ Validation: false });
                    this.setState({ Validation2: false });
                    this.setState({ PasswordShow: true });
                  })
                   .catch(error => {
                    console.log('error', error);
                    this.setState({ LoaderShow: false });  
                    alert('Invalid Authentication!');
                    })
            }else{
                alert('Your Security Answer is Wrong!');
            }
           
          }}
        >

{formik => (
  
   <Form style={{width:'30%',margin:'auto'}}>
    <div className='popup-update' style={{textAlign:'right',marginBottom:'10px'}}>
      <button onClick={ () => {this.props.history.push('/login');}}>Back</button>
    </div>
        <table id="Grid_DXPEForm_DXEFT" class="submodule-newpopup" cellSpacing={0} cellPadding={0} border={0} style={{fontFamily: 'Calibri', fontSize: 15, width: '100%', borderCollapse: 'collapse', padding:'25px',display:'flex',border:'1px solid #4c4a48',borderRadius:'10px',marginBottom:'10px'}}>
          <tbody style={{width:'100%'}}>
          <label className='popup-label'>Forgot Password</label>
          <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Security Question :</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <label className='popup-label'>{this.state.SecureQuestion}</label>
              </td>
            </tr>

            <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Answer :</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <Field name="Answer" type="text" style={{width: '100%'}} />
                  <ErrorMessage component="label" className=" form-label text-danger" name="Answer" />
              </td>
            </tr>
          </tbody>
        </table>

       <div class="popup-update">
        <button type='submit' >Show My Password</button>
        </div>
        </Form>
    )}
      </Formik>
        ) : (
          ''
        )}

    
{this.state.PasswordShow === true ? (
    <div>
      <table id="Grid_DXPEForm_DXEFT" class="submodule-newpopup" cellSpacing={0} cellPadding={0} border={0} style={{fontFamily: 'Calibri', fontSize: 15, width: '100%', borderCollapse: 'collapse', padding:'25px',display:'flex',border:'1px solid #4c4a48',borderRadius:'10px',marginBottom:'10px'}}>
          <tbody style={{width:'100%'}}>
          <label className='popup-label'>Forgot Password</label>
          <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Your password is :</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <label className='popup-label'>{this.state.Password}</label>
              <button class="btn btn-link" onClick={() => {this.props.history.push('/login')}}>Login Here</button>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
        ) : (
          ''
        )}

     <Footer />
      </div>
    )
  }
}

export default ForgotPassword