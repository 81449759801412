import React, { Component } from 'react';
import Footer from './footer';
import Header from './header';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import Loader from '../images/loader2.gif';


const AddBackLogsValidation = Yup.object({

    IssueName: Yup.string()
    .required('Issue Name is Required'),
    IssueType: Yup.string()
    .required('Issue Type is Required'),
    Module_Epic: Yup.string()
    .required('Module is Required'),
    WorkFlow: Yup.string()
    .required('Work Flow is Required'),
    // Priority: Yup.string()
    // .required('Priority is Required'),
    // StartDate: Yup.string()
    // .required('Start Date is Required'),
    // Reportedby: Yup.string()
    // .required('Reported by is Required'),
    Source: Yup.string()
    .required('Source is Required')
  });

export class backlognew extends Component {

    constructor() {
		super();
		this.state = {
            BackLogListDatas:[],
            LoginDatas:  JSON.parse(localStorage.getItem("HelpDeskLoginDatas")),
            Auth:  JSON.parse(localStorage.getItem("auth")),
            LoaderShow: false,
            showtab1: true,
            showtab2: false,
            showModal1: false,
            showModal2: false,
            ProjectDatas:[],
            IssueTypeDatas: [],
            Module_Epic_Datas: [],
            WorkFlowDatas: [],
            BoardDatas: [],
            SprintDatas: [],
            ReportedByData: [],
            ProjectID: '',
            PriorityRadio: 'Low',
            IsActive: true,
            formData : new FormData(),

            listening: false,
            transcription: ''
	  };
    // this.recognition = new window.webkitSpeechRecognition(); // Initialize SpeechRecognition object
    // this.recognition.continuous = true; // Set continuous listening
    // this.recognition.interimResults = true; // Get interim results
    // this.recognition.lang = 'en-US'; // Set language

    this.toggleListen = this.toggleListen.bind(this);
    this.handleChange = this.handleChange.bind(this);

	}

componentDidMount(){
this.IssueTypeDataMethod();
this.ProjectNameDataMethod();
// this.Module_Epic_DataMethod();
this.WorkFlowDataMethod();
this.ReportedByDataMethod();
}

toggleListen = () => {
    const { listening } = this.state;
    if (window.hasOwnProperty('webkitSpeechRecognition')) {
      const recognition = new window.webkitSpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = true;
      recognition.lang = 'en-US';
      recognition.start();

      recognition.onstart = () => {
        this.setState({ listening: true });
        console.log("Voice recognition started. Speak now.");
      };

      recognition.onerror = event => {
        console.error("Speech recognition error detected: ", event.error);
      };

      recognition.onend = () => {
        this.setState({ listening: false });
        console.log("Voice recognition ended.");
      };
  
      recognition.onresult = event => {
        let interimTranscript = '';
        let finalTranscript = '';

        for (let i = event.resultIndex; i < event.results.length; i++) {
          const transcript = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            finalTranscript += transcript + ' ';
          } else {
            interimTranscript += transcript;
          }
        }
        this.setState({ transcription: finalTranscript });
      };
    } else {
      alert('Your browser does not support speech recognition.');
    }
  }

handleListen = () => {
  if (this.state.listening) {
    console.log('listening');
    this.recognition.start();
    this.recognition.onresult = event => {
      console.log(this.recognition);
      const transcript = Array.from(event.results)
        .map(result => result[0])
        .map(result => result.transcript)
        .join('');
      console.log('transcript', transcript); // Print transcription to console
      this.setState({
        transcription: transcript
      });
    };
  } else {
    this.recognition.stop();
    this.recognition.onend = () => {
      console.log('Listening stopped.');
    };
  }
}
handleChange(event) {
  this.setState({ transcription: event.target.value });
}
ProjectNameDataMethod(){
    var project = 'https://helpdeskservice.desss-portfolio.com/api/GetProjectName/GetProjectName?CompanyID='+this.state.LoginDatas.CompanyID+'&UserTypeID='+this.state.LoginDatas.UserTypeID+'&Est&Empid='+this.state.LoginDatas.EmpID;
    this.setState({ LoaderShow: true });
    axios.get(project)
    .then(res => {
    this.setState({ProjectDatas: res.data.data});
    this.setState({ LoaderShow: false });
    console.log('ProjectDatas',  this.state.ProjectDatas);
    })
    .catch(error => {
      this.setState({ LoaderShow: false });
    console.log(error);
    })
}

IssueTypeDataMethod(){
    var issue = 'https://helpdeskservice.desss-portfolio.com/api/ProjectTaskType/LoadTasktype';
    axios.post(issue)
    .then(res => {
    this.setState({IssueTypeDatas: res.data.data});
    console.log('IssueTypeDatas',  this.state.IssueTypeDatas);
    })
    .catch(error => {
    console.log(error);
    })
}
Module_Epic_DataMethod(){
  var module = 'https://helpdeskservice.desss-portfolio.com/api/ModuleByCompany/LoadModule?CompanyID=' + this.state.LoginDatas.CompanyID;
  axios.post(module)
  .then(res => {
  this.setState({Module_Epic_Datas: res.data.data});
  console.log('Module_Epic_Datas',  this.state.Module_Epic_Datas);
  })
  .catch(error => {
  console.log(error);
  })
}
WorkFlowDataMethod(){
  var workflow = 'https://helpdeskservice.desss-portfolio.com/api/SubModTaskCat/GetTaskCategory?CompanyID=' + this.state.LoginDatas.CompanyID;
  axios.get(workflow)
  .then(res => {
  this.setState({WorkFlowDatas: res.data.data});
  console.log('WorkFlowDatas',  this.state.WorkFlowDatas);
  })
  .catch(error => {
  console.log(error);
  })
}

ReportedByDataMethod(){
  var URL = 'https://helpdeskservice.desss-portfolio.com/api/AssignedBy/loadall?CompanyID=' + this.state.LoginDatas.CompanyID;
  axios.post(URL)
  .then(res => {
  this.setState({ReportedByData: res.data.data});
  console.log('ReportedByData',  this.state.ReportedByData);
  })
  .catch(error => {
  console.log(error);
  })
}

BoardDatasMethod = (e) =>{
  console.log('Board Datas', e.target.value);
  // this.setFieldValue("Projectname", e.target.value);
  this.setState({ BoardDatas: [] });
  this.setState({ ProjectID: e.target.value });
  var URL = 'https://helpdeskservice.desss-portfolio.com/api/Board/LoadBoard?ProjectID=' + e.target.value;
  axios.post(URL)
  .then(res => {
    console.log('Board Datas', res.data.data);
    this.setState({ BoardDatas: res.data.data });
 })
  .catch(error => {
  console.log(error);
 })


 this.setState({ SprintDatas: [] });
 var URL1 = 'https://helpdeskservice.desss-portfolio.com/api/GridSprint/LoadBoard?ProjectID=' + e.target.value;
 axios.post(URL1)
 .then(res => {
   console.log('SprintDatas', res.data.data);
   this.setState({ SprintDatas: res.data.data });
})
 .catch(error => {
 console.log(error);
});

var module = 'https://helpdeskservice.desss-portfolio.com/api/ModuleList/LoadGrid?CompanyID='+this.state.LoginDatas.CompanyID+'&ProjectID='+e.target.value;
axios.post(module)
.then(res => {
this.setState({Module_Epic_Datas: res.data.data});
console.log('Module_Epic_Datas',  this.state.Module_Epic_Datas);
})
.catch(error => {
console.log(error);
})
}   

PriorityChange = (e) => {
  this.setState({PriorityRadio: e.target.value});
  console.log('PriorityRadio', this.state.PriorityRadio);
}
IsActiveChange = (e) => {
  this.setState({IsActive: e.target.checked});
  console.log('IsActive', this.state.IsActive);
}
  render() {
    const { listening, transcription } = this.state;
    return (
         <div>
                {this.state.LoaderShow === true ? (
          <div className='loader-img'>
            <img   src={Loader} height="50" width="50" alt="Helpdesk" />
          </div>
        ) : (
          ''
        )}
         <Header />

         <Formik
          initialValues={{
            IssueName: '',
            IssueType: '',
            Module_Epic: '',
            WorkFlow: '',
            // Priority: '',
            // StartDate: '',
            // Reportedby: '',
            Source: '',
          }}
          validationSchema={AddBackLogsValidation}
          onSubmit={values => {
            console.log('AddBackLogsValidation', values);

            var Description = document.getElementById('Description').value;
            var Analysis = document.getElementById('Task_Analysis').value;
            var Boardid = document.getElementById('Board').value;
            var Sprintid = document.getElementById('Sprint').value;
            var Billable = document.getElementById('IsBillable').checked;
            var Active = document.getElementById('IsActive').checked;
            var Attachment = document.getElementById('FUAttachments').value;

            const { formData } = this.state;
                    formData.append("FileUpload", Attachment);
                    formData.append("CompanyID", this.state.LoginDatas.CompanyID);
                    formData.append("IssueName", values.IssueName);
                    formData.append("ProjectName", this.state.ProjectID);
                    formData.append("WorkFlow", values.WorkFlow);
                    formData.append("Description", Description);
                    formData.append("Analysis", Analysis);
                    formData.append("Module", values.Module_Epic);
                    formData.append("IssueType", values.IssueType);
                    formData.append("UserName", this.state.LoginDatas.EmpName);
                    formData.append("BoardID", Boardid);
                    formData.append("SprintID", Sprintid);
                    formData.append("Billable", Billable);
                    formData.append("AllotedBy", this.state.LoginDatas.EmpID);
                    formData.append("Source", values.Source);
                    formData.append("Priority", this.state.PriorityRadio);
                    formData.append("Active", this.state.IsActive);

                    this.setState({ formData });


            // var AddBackLog = 'https://helpdeskservice.desss-portfolio.com/api/InsertBackLog/Insert';

            this.setState({ LoaderShow: true });
                  fetch('https://helpdeskservice.desss-portfolio.com/api/InsertBackLog/Insert', {
                    method: 'POST',
                    body: formData
                  })
                  .then(response => {
                    console.log(response)
                    this.setState({ LoaderShow: false });
                    this.props.history.push('/backloglist');
                  })
                  .catch(error => {
                    this.setState({ LoaderShow: false });
                    this.props.history.push('/backloglist');
                    console.log(error)
                  });

          }}
        >

{formik => (
  
   <Form>
     <div style={{width:'30%',margin:'auto'}} className='backlogedit-mobile'>
     <div className='popup-update' style={{textAlign:'right',marginBottom:'10px'}}>
      <button onClick={ () => {this.props.history.push('/backloglist');}}>Back To BackLogList</button>
    </div>
        <table id="Grid_DXPEForm_DXEFT" class="submodule-newpopup" cellSpacing={0} cellPadding={0} border={0} style={{fontFamily: 'Calibri', fontSize: 15, width: '100%', borderCollapse: 'collapse', padding:'25px',display:'flex',border:'1px solid #4c4a48',borderRadius:'10px',marginBottom:'10px'}}>
          <tbody style={{width:'100%'}}>
            <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Project Name</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>

              <select name="Projectname" id='Projectname' as="select" onChange={this.BoardDatasMethod} value={this.state.ProjectID} style={{width: '100%'}}>
              {/* <Field name="Projectname" id='Projectname' as="select" style={{width: '100%'}}> */}
              <option>--Select--</option>
                    { this.state.ProjectDatas.map((element) => 
                      <option  value={element.ProjectID}>{element.ProjectName}</option>
                    )}
              </select>


              </td>
            </tr>
            <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Module/Epic</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>

              <Field name="Module_Epic" id='Module_Epic' as="select" style={{width: '100%'}}>
              <option>--Select--</option>
                    { this.state.Module_Epic_Datas.map((element) => 
                      <option  value={element.ModuleID}>{element.ModuleName}</option>
                    )}
              </Field>
             <ErrorMessage component="Module_Epic" className=" form-label text-danger" name="Module_Epic" />

              </td>
            </tr>
            <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Issue Type</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <Field name="IssueType" id='IssueType' as="select" style={{width: '100%'}}>
              <option>--Select--</option>
                    { this.state.IssueTypeDatas.map((element) => 
                      <option  value={element.ModuleTypeID}>{element.ModuleTypeName}</option>
                    )}
              </Field>
             <ErrorMessage component="IssueType" className=" form-label text-danger" name="IssueType" />

              </td>
            </tr>
            <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Issue Name</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <Field name="IssueName" type="text" style={{width: '100%'}} />
                  <ErrorMessage component="label" className=" form-label text-danger" name="IssueName" />
              </td>
            </tr>
                      <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Work Flow</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              {/* <DatePicker  name="editmoduleFinaldate1"  selected={this.state.FinalEndDate} style={{width: '100%'}} dateFormat="dd-mm-yyyy"/> */}
             
              <Field name="WorkFlow" id='WorkFlow' as="select" style={{width: '100%'}}>
              <option>--Select--</option>
                    { this.state.WorkFlowDatas.map((element) => 
                      <option  value={element.TaskCategoryID}>{element.Description}</option>
                    )}
              </Field>
             <ErrorMessage component="WorkFlow" className=" form-label text-danger" name="WorkFlow" />

              </td>
            </tr>

            <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Priority</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <input name="Priority" id='Priority' value="Low" type="radio" style={{width: '8%'}} checked={this.state.PriorityRadio === 'Low'} onChange={this.PriorityChange}/>Low
                <input name="Priority" id='Priority' value="Medium" type="radio" style={{width: '8%'}} checked={this.state.PriorityRadio === 'Medium'} onChange={this.PriorityChange}/>Medium
                <input name="Priority" id='Priority' value="High" type="radio" style={{width: '8%'}} checked={this.state.PriorityRadio === 'High'} onChange={this.PriorityChange}/>High
                <input name="Priority" id='Priority' value="Criticle" type="radio" style={{width: '8%'}} checked={this.state.PriorityRadio === 'Criticle'} onChange={this.PriorityChange}/>Criticle
              </td>
            </tr>

            <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Board</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <select name="Board" id='Board' as="select" style={{width: '100%'}} >
              <option value={0}>--Select--</option>
                    { this.state.BoardDatas.map((element) => 
                      <option  value={element.BoardID}>{element.BoardName}</option>
                    )}
                </select>                  
              </td>
            </tr>

            <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Sprint</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <select name="Sprint" id='Sprint' as="select" style={{width: '100%'}} >
              <option value={0}>--Select--</option>
                    { this.state.SprintDatas.map((element) => 
                      <option  value={element.SprintID}>{element.SprintName}</option>
                    )}
                </select>                  
              </td>
            </tr>

            {/* <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Start Date</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <Field name="StartDate" id='StartDate' type="date" style={{width: '100%'}} >
              
              </Field>
               <ErrorMessage component="label" className=" form-label text-danger" name="StartDate" />
              </td>
            </tr> */}

            <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Description <button onClick={this.toggleListen}>{listening ? 'Off' : 'On'}
        </button></label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue"  colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <textarea  name="Description" id='Description' value={transcription}  onChange={this.handleChange}  type="textarea"  style={{width: '100%'}} />  
              </td>
            </tr>

            <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Task Analysis</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <textarea  name="Task_Analysis" id='Task_Analysis' type="textarea"  style={{width: '100%'}} />  
              </td>
            </tr>


            <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Reported By</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <select name="Reportedby" id='Reportedby' as="select" style={{width: '100%'}} value={this.state.LoginDatas.EmpID} disabled>
              {/* <option value={0}>--Select--</option> */}
                    { this.state.ReportedByData.map((element) => 
                      <option  value={element.EmpID}>{element.EmpName}</option>
                    )}
                </select>                  
                    {/* <ErrorMessage component="label" className="form-label text-danger" name="Reportedby" /> */}
              </td>
            </tr>

            <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>IsActive</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
                <input name="IsActive" id='IsActive' type="checkbox" style={{width: '8%'}} checked={this.state.IsActive === true} onChange={this.IsActiveChange}/>  
              </td>
            </tr>

            <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>IsBillable</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
                <input name="IsBillable" id='IsBillable' type="checkbox" style={{width: '8%'}} />  
              </td>
            </tr>

            <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Source</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <Field name="Source" id='Source' as="select" style={{width: '100%'}} >
              <option>--Select--</option>
              <option value={"Issue"}>Issue</option>
              <option value={"Task"}>Task</option>
              <option value={"Ticket"}>Ticket</option>
              <option value={"Email"}>Email</option>
              </Field>                  
                    <ErrorMessage component="label" className="form-label text-danger" name="Source" />
              </td>
            </tr>

            <tr>
              <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Ticket No</label>
              </td>
              <td className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>

                <input style={{width: '100%'}} type='text' readOnly></input>
            
              </td>
            </tr>
            <tr className='inner-line'>
              <td valign="top"><label className='popup-label'>Upload Attachments :</label>
              </td>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <td align="left" style={{verticalAlign: 'top'}}>
                        <input type="file" name="ctl00$MainContent$FUAttachments" id="FUAttachments" />
                        <a id="HlAttachment" href="../TaskFilesAttachment.aspx?TASID=43332" target="_blank">Attachments</a>
                      </td>
                      {/* <td valign="top">
                        <a id="HlAttachment" href="../TaskFilesAttachment.aspx?TASID=43332" target="_blank">Attachments</a>
                      </td> */}
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
 {/* sp 09-09-2024 tr td added */}
 <tr>
              <td style={{float:'right'}}>   <div class="popup-update">
        <button type='submit' >Save</button>
        {/* <button >Save and Exit</button> */}
        </div></td>
            </tr>
 {/* sp 09-09-2024 tr td added */}
          </tbody>
        </table>

      </div>
   
        </Form>
    )}

         </Formik>




         <Footer />
      </div>
    )
  }
}

export default backlognew