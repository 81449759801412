import React, { Component } from 'react'
import logohp1 from '../images/helpdesk_new_logo_1.png'
import logout from '../images/logout_ic.png'
import prof_pick from '../images/purpol_profile.png'
import axios from 'axios';

export class Header extends Component {
	constructor() {
		super();
		this.state = {
		  TapName: '/home',
		  LoginDatas:  JSON.parse(localStorage.getItem("HelpDeskLoginDatas")),
		  LoginType: '',
	  };
	}
componentDidMount(){
	
	console.log('Pathname', window.location.pathname);
	var tap = window.location.pathname;
	this.setState({ TapName: window.location.pathname});
	console.log('TapName', this.state.LoginDatas.UserType);

	if(this.state.LoginDatas.UserTypeID === '1' || this.state.LoginDatas.UserTypeID === '3'){
		this.setState({ LoginType: 'Admin'});
	}else if(this.state.LoginDatas.UserTypeID === '4'){
		console.log('LoginType', this.state.LoginType);
		this.setState({ LoginType: 'Client'});
	}else{
		this.setState({ LoginType: 'User'});
		console.log('LoginType', this.state.LoginType);
	}
	

	axios.get('https://helpdeskservice.desss-portfolio.com/api/GetClientprofile/GetClientprofile?ClientID='+this.state.LoginDatas.ClientID+'&ClientUserID=' + this.state.LoginDatas.ClientUserID)
    .then(response => {
    var data = response.data.data[0];
    localStorage.setItem("ClientprofileData", JSON.stringify(data));
    })
   .catch(error => {
    console.log('error', error);
    })

	
}
LoginMethod(){
	let auth = false;
	localStorage.setItem("auth", JSON.stringify(auth));
	this.props.history.push('/login');
}
// LoginMethod(){
	
// 	this.props.history.push('/login');
// }
  render() {
    return (
      <div>
        <div id="wrapper">
        <div class="fixed-header">
            {/* <div id="header" className="top-header" style={{width:"92%",height: "132px"}}> */}
			<div id="header" className="centerBG">
                <div>
                    <div width="100%" border="0" cellspacing="0" cellpadding="0">
                        <div>
						<div className='img-title'>
							<div class="img_menu_sec">
                            <div className='img-part'>
                                <img style={{padding: "0px 0px 0px 0px", width: "50px" ,height: "50px" , marginTop: "0px"}} src={logohp1} height="80" width="212" alt="Helpdesk" />
                            </div>
							<div class="men_nu">
						
								<div class="header">
						<input class="menu-btn" type="checkbox" id="menu-btn" />
						<label class="menu-icon" for="menu-btn"><span class="navicon"></span></label>
						<ul class="menu">
					
					{this.state.LoginType === 'User' || this.state.LoginType === 'Admin' ?  (
					<li id="MMenuBar_DXI11_T" class="dxmMenuItem_Aqua dxmIFC dxmILC" style={{fontfamily:"Calibri",fontsize:"14px",fontweight:"bold",cursor:"pointer",textAlign:"Center",verticalalign:"Middle"}}><a href="/home" style={{fontfamily:"Calibri",fontsize:"14px",fontweight:"bold"}}>Home</a></li>
					) : (
						''
					)}
				{/* {this.state.LoginDatas.LoginType === 'Admin' ? (
					<li id="MMenuBar_DXI11_T" class="dxmMenuItem_Aqua dxmIFC dxmILC" style={{fontfamily:"Calibri",fontsize:"14px",fontweight:"bold",cursor:"pointer",textAlign:"Center",verticalalign:"Middle"}}><a href="/home" style={{fontfamily:"Calibri",fontsize:"14px",fontweight:"bold"}}>Home</a></li>
					) : (
						''
					)} */}
					{this.state.LoginType === 'Client' ? (
					<li id="MMenuBar_DXI11_T" class="dxmMenuItem_Aqua dxmIFC dxmILC" style={{fontfamily:"Calibri",fontsize:"14px",fontweight:"bold",cursor:"pointer",textAlign:"Center",verticalalign:"Middle"}}><a href="/tickets" style={{fontfamily:"Calibri",fontsize:"14px",fontweight:"bold"}}>Tickets</a></li>
					) : (
						''
					)}
					{this.state.LoginType === 'Client' ? (
					<li id="MMenuBar_DXI11_T" class="dxmMenuItem_Aqua dxmIFC dxmILC" style={{fontfamily:"Calibri",fontsize:"14px",fontweight:"bold",cursor:"pointer",textAlign:"Center",verticalalign:"Middle"}}><a href="/profile" style={{fontfamily:"Calibri",fontsize:"14px",fontweight:"bold"}}>Profile</a></li>
					) : (
						''
					)}
					{this.state.LoginType === 'Admin' ? (
					<li id="MMenuBar_DXI11_T" class="dxmMenuItem_Aqua dxmIFC dxmILC" style={{fontfamily:"Calibri",fontsize:"14px",fontweight:"bold",cursor:"pointer",textAlign:"Center",verticalalign:"Middle"}}><a href="/project" style={{fontfamily:"Calibri",fontsize:"14px",fontweight:"bold"}}>Project</a></li>
					) : (
						''
					)}
					{this.state.LoginType === 'Admin' ? (
					<li id="MMenuBar_DXI11_T" class="dxmMenuItem_Aqua dxmIFC dxmILC" style={{fontfamily:"Calibri",fontsize:"14px",fontweight:"bold",cursor:"pointer",textAlign:"Center",verticalalign:"Middle"}}><a href="/board" style={{fontfamily:"Calibri",fontsize:"14px",fontweight:"bold"}}
					onClick={() => {localStorage.setItem("BoardProjectID", '');}}>Board</a></li>
					) : (
						''
					)}

					{this.state.LoginType === 'Admin' ? (
					<li id="MMenuBar_DXI11_T" class="dxmMenuItem_Aqua dxmIFC dxmILC" style={{fontfamily:"Calibri",fontsize:"14px",fontweight:"bold",cursor:"pointer",textAlign:"Center",verticalalign:"Middle"}}><a href="/sprint-function" style={{fontfamily:"Calibri",fontsize:"14px",fontweight:"bold"}}
					onClick={() => {localStorage.setItem("SprintProjectID", '');}}>Sprint</a></li>
					) : (
						''
					)}

					{this.state.LoginType === 'Admin' ? (
					<li id="MMenuBar_DXI11_T" class="dxmMenuItem_Aqua dxmIFC dxmILC" style={{fontfamily:"Calibri",fontsize:"14px",fontweight:"bold",cursor:"pointer",textAlign:"Center",verticalalign:"Middle"}}><a href="/backloglist" style={{fontfamily:"Calibri",fontsize:"14px",fontweight:"bold"}}>Back log list</a></li>
					) : (
						''
					)}

					{this.state.LoginType === 'Admin' ? (
					<li id="MMenuBar_DXI11_T" class="cardviewlist" style={{fontfamily:"Calibri",fontsize:"14px",fontweight:"bold",cursor:"pointer",textAlign:"Center",verticalalign:"Middle"}}><a href="/cardview" style={{fontfamily:"Calibri",fontsize:"14px",fontweight:"bold"}}>
						CardView list
						</a>
						<div className='cardview-body'>
							<ul className='cardviewhover'>
								<li>
									<a href="/unassigned" style={{fontfamily:"Calibri",fontsize:"14px",fontweight:"bold"}}>
								Unassigned (Summary) 
								</a>
								</li>
								<li>
									<a href="/assigned" style={{fontfamily:"Calibri",fontsize:"14px",fontweight:"bold"}}>
									Assigned (Summary) 
									</a>
								</li>
								<li>
									<a href="/completed" style={{fontfamily:"Calibri",fontsize:"14px",fontweight:"bold"}}>
									Completed (Summary) 
									</a>
								</li>
								<li>
									<a href="/CompletedTaskDetails" style={{fontfamily:"Calibri",fontsize:"14px",fontweight:"bold"}}>
									Completed Task (Details)
									</a>
								</li>
								<li>
									<a href="/TaskAllotmentList" style={{fontfamily:"Calibri",fontsize:"14px",fontweight:"bold"}}>
									Task Allotment List
									</a>
								</li>
								<li>
									<a href="/TaskAllotmentList" style={{fontfamily:"Calibri",fontsize:"14px",fontweight:"bold"}}>
									TimeSheet List
									</a>
								</li>
								<li>
									<a href="/TaskAllotmentList" style={{fontfamily:"Calibri",fontsize:"14px",fontweight:"bold"}}>
									TaskAllotment vs TimeSheet List
									</a>
								</li>
								<li>
									<a href="/weeklyreport" style={{fontfamily:"Calibri",fontsize:"14px",fontweight:"bold"}}>
									Weekly Allotment list
									</a>
								</li>
							</ul>
						</div>
						</li>
						
					) : (
						''
					)}
					</ul>
								</div>
								
					</div>
					</div>
                            <div className='title-part' >
                                <div class="menu">
                                    <div width="100%" border="0" cellspacing="0" cellpadding="0">
                                        <div>
										<div>
                                            <div width="100%" valign="top" class="namecls">
                                                <p>
                                                    <a className='header-name log_out_user'>
														<div class="prof_img_ico"><img style={{width: "35px" ,height: "35px"}} src={prof_pick} alt="logout" /></div>
                                                        <span id="lblUser" style={{fontfamily:"Calibri",}}>{this.state.LoginDatas.ClientName}</span>
                                                        <span id="lblUser" style={{fontfamily:"Calibri",}}>{this.state.LoginDatas.EmpName}</span>
														<div className='log-out'>
															<a class="log_btn_atag" href='/login'><img style={{width: "30px" ,height: "30px"}} src={logout} alt="logout" /></a>
														</div>
                                                    </a>
													{/* <button  onClick={() => { this.LoginMethod(); }}>
													   <span id="lblUser" style={{fontfamily:"Calibri",fontSize: "16px"}}>{this.state.LoginDatas.ClientName}</span>
													</button> */}
                                                    {/* <!--HTML for Flex Drop Down Menu 1--> */}
                                                </p>
                                                
                                            </div>
                                            {/* <td width="71%"></td> */}
                                        </div>
                                        </div>
									</div>
                                </div>
                            </div>
                        </div>
                    </div>
					</div>
					
                </div>
            </div>
                </div>
                </div>
      </div>
    )
  }
}

export default Header