import React, { Component } from 'react'
import Header from './header';
import Footer from './footer';
import axios from 'axios';
import moment from 'moment';
import Loader from '../images/loader2.gif';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import '../css list/home.css';
import dep_ment_ic from '../images/department_ic.png';
import proj_nam from '../images/project_name_ic.png';
import tsk_cat_ic from '../images/task_cat_ic.png';
import tsktype_ic from '../images/task_type_ic.png'; 
import sprint_ic from '../images/sprint_ic.png'; 
import project_ic from '../images/project_name_ic.png';
import clos_ic from '../images/close_ic.png';
import issue_nam_ic from '../images/issue_nam_ic.png'; 
import allot_hrs_ic from '../images/allot_hrs_ic.png';
import tsk_status_ic from '../images/tsk_status_ic.png';  
import assign_to_ic from '../images/assign_to_ic.png';
import assing_by_ic from '../images/assign_by_ic.png';
import board_ic from '../images/board_ic.png';
import module_ic from '../images/module_ic.png';
import no_tsk_ic from '../images/notaskicon.png';
import no_tsk_img from '../images/no_task.png';
import drop_dwn_ic from '../images/drop_dwn_ic.png';
import tim_sht from '../images/timesheet.png';
import tday_task from '../images/today_task.png';

const AddTaskLogsValidation = Yup.object({

  IssueName: Yup.string()
  .required('Issue Name is Required'),
  IssueType: Yup.string()
  .required('TaskType is Required'),
  Module_Epic: Yup.string()
  .required('Module is Required'),
  // WorkFlow: Yup.string()
  // .required('TaskCategory is Required'),
  // Priority: Yup.string()
  // .required('Priority is Required'),
  StartDate: Yup.string()
  .required('Date is Required'),
  AssignedBy: Yup.string()
  .required('Assigned By is Required'),
  AssignedTo: Yup.string()
  .required('Assigned To is Required'),
  Taskstatus: Yup.string()
  .required('Task Status is Required'),
  AllotedHrs: Yup.string()
  .required('Alloted Hours is Required'),
});

export class weeklyreport extends Component {
  constructor() {
    super();
    
    this.state = {
      LoginDatas:  JSON.parse(localStorage.getItem("HelpDeskLoginDatas")),
      TodayDate: new Date(),
      project_data : [],
      InProgressData: [],
      AssignedData: [],
      weeklyreportData: [],
      weekDates: this.getWeekDays(new Date()),
      DepartmentData : [],
      SelectedDepartment: '0',
      ProjectName: 'All',
      ProjectId: '0',
      Auth:  JSON.parse(localStorage.getItem("auth")),
      LoaderShow: false,
      selectedDatefunc: new Date(),
      selectedDate: moment(new Date()).format("MM/DD/YYYY"),
      selectedDate2: moment(new Date()).format("MM/DD/YYYY"),

      AddShowPopup: false,
      Module_Epic_Datas: [],
      WorkFlowDatas: [],
      IssueTypeDatas: [],
      BoardDatas: [],
      SprintDatas: [],
      TaskstatusDatas: [],
      ReportedByData: [],
      AllotedToDatas: [],
    };
  }

componentDidMount(){

         this.WorkFlowDataMethod();
         this.ReportedByDataMethod();
         this.TaskstatusDatasMethod();

        axios.post('https://helpdeskservice.desss-portfolio.com/api/LodeProjectBasedEMP/Project?UserType_Id='+this.state.LoginDatas.UserTypeID+'&Emp_id=' + this.state.LoginDatas.EmpID + '&Dept_id=' + this.state.LoginDatas.DeptID)
        .then( project => {
          var data = project.data.data;
          console.log("project" , project.data.data)
          data.map((key) =>{
            this.setState({ project_data: [ ...this.state.project_data, {
              ProjectName: key.Project_Name ,
              ProjectID: key.Project_Id
           }]
         });
         });
        })
        .catch(error => {
            console.log(error);
        });
        
        axios.get('https://helpdeskservice.desss-portfolio.com/api/DeptDropdown/LoadDeptDropdown')
        .then( res => {
          var data1 = res.data.data;
          console.log("deparmen" , res.data.data)
          data1.map((key) =>{
            this.setState({ DepartmentData: [ ...this.state.DepartmentData, {
              Department: key.Department ,
              DeptID: key.DeptID
           }]
         });
         });
         console.log("DepartmentData" , this.state.DepartmentData)
        })
        .catch(error => {
            console.log(error);
        })    

        // console.log('weeklyreport',  this.getWeekDays(this.state.selectedDatefunc));
        // this.setState({ weekDates:  this.getWeekDays(this.state.selectedDatefunc) });

        //  this.GetDatas();
}

GetDatas(){
  console.log('GetDatas',  this.state.SelectedDepartment);
  console.log('GetDatas',  this.state.weekDates);
  var weeklyreport = [];


  this.setState({ weeklyreportData: weeklyreport });
 
  var date =  moment(new Date()).format("MM/DD/YYYY");
    this.setState({ LoaderShow: true }); 
    
    axios.get('https://helpdeskservice.desss-portfolio.com/api/WeeklyTaskList/GetWeeklyTaskListNew?MondayDate='+this.state.weekDates[0]+'&TuesdayDate='+this.state.weekDates[1]+'&WednesdayDate='+this.state.weekDates[2]+'&ThursdayDate='+this.state.weekDates[3]+'&FridayDate='+this.state.weekDates[4]+'&SaturdayDate='+this.state.weekDates[5]+'&Dept='+this.state.SelectedDepartment)
    // axios.get('https://helpdeskservice.desss-portfolio.com/api/WeeklyTaskList/GetWeeklyTaskList?MondayDate='+this.state.weekDates[0]+'&TuesdayDate='+this.state.weekDates[1]+'&WednesdayDate='+this.state.weekDates[2]+'&ThursdayDate='+this.state.weekDates[3]+'&FridayDate='+this.state.weekDates[4]+'&SaturdayDate='+this.state.weekDates[5]+'&Dept='+this.state.SelectedDepartment)
    // axios.get('https://helpdeskservice.desss-portfolio.com/api/WeeklyTaskList/GetWeeklyTaskList?MondayDate='+this.state.weekDates[0]+'&TuesdayDate='+this.state.weekDates[1]+'&WednesdayDate='+this.state.weekDates[2]+'&ThursdayDate='+this.state.weekDates[3]+'&FridayDate='+this.state.weekDates[4]+'&SaturdayDate='+this.state.weekDates[5]+'&Dept=16')
    .then(res => {
      var data = res.data.data;
      this.setState({ LoaderShow: false });  
      if(data[0].errorMsg === "No Data Found"){
        return;
      }
      if(data[0].errorMsg === "Invalid API Call - Execution Timeout Expired.  The timeout period elapsed prior to completion of the operation or the server is not responding."){
        return;
      }
      // data.forEach((key, value) => {   
      //   weeklyreport.push({
      //     EmpID: key.EmpID, 
      //     DeptID: key.DeptID,
      //     Department: key.Department,
      //     TaskRowCount: key.TaskRowCount,
      //     TimesheetRowCount: key.TimesheetRowCount,
      //     FullName: key.FullName,
      //     PrjTaskAllotID: key.PrjTaskAllotID,
      //     TimeSheetID: key.TimeSheetID,
      //     ProjectName: key.ProjectName,
      //     ModuleName: key.ModuleName,
      //     TaskName: key.TaskName,
      //     TaskStatus: key.TaskStatus,
      //     AllotedHrs: key.AllotedHrs,
      //     TimesheetAllotedHrs: key.TimesheetAllotedHrs,
      //     ActualWorkedHours: key.ActualWorkedHours,
      //     ProjectID: key.ProjectID,
      //     AllotedDate: moment(key.AllotedDate).format("MM/DD/YYYY"),
      //     DayCount: key.DayCount,
      //     DeptCount: key.DeptCount,
      //     SprintName: key.SprintName,
      //     SprintDeliveryDate: key.SprintDeliveryDate,
          
      //   });
          
      // });

      this.setState({ weeklyreportData: data });
      console.log('weeklyreportData', this.state.weeklyreportData);
      // console.log('Assigned', this.state.Assigned);
    }) 
    .catch(error => {
      this.setState({ LoaderShow: false });  
        console.log(error);
    })

}

getWeekDays(date){
  const weekDays = [];
  const currentDate = date;
  const currentDay = currentDate.getDay();
  const firstDayOfWeek = currentDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1); // Adjust if Sunday (0)

  // Loop through Monday (1) to Saturday (6) and add them to the array
  for (let i = 1; i <= 6; i++) {
    const targetDate = new Date(currentDate.setDate(firstDayOfWeek + i - 1));
    weekDays.push(this.formatDate(targetDate));
  }

  return weekDays;
};


formatDate = (date) => {
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

DepartmentChange = (e) => {
  this.setState({ SelectedDepartment: e.target.value });
}

DateChange = (e) => {
  console.log('Date Change2', new Date(e.target.value));
  this.setState({ selectedDatefunc: new Date(e.target.value) });
  this.setState({ selectedDate: moment(e.target.value).format("MM/DD/YYYY") });
  console.log('Date Change2', this.state.selectedDatefunc);
  this.setState({ weekDates:  this.getWeekDays(new Date(e.target.value)) });
  console.log('Date Change date2', this.state.weekDates);
}
AddShowPopupMethod(){
  this.setState({ AddShowPopup: true });
}
AddPopupClose = () => {
  this.setState({ AddShowPopup: false });
};

BoardDatasMethod = (e) =>{
  console.log('Board Datas', e.target.value);
  // this.setFieldValue("Projectname", e.target.value);
  this.setState({ BoardDatas: [] });
  this.setState({ ProjectID: e.target.value });
  var URL = 'https://helpdeskservice.desss-portfolio.com/api/Board/LoadBoard?ProjectID=' + e.target.value;
  axios.post(URL)
  .then(res => {
    console.log('Board Datas', res.data.data);
    this.setState({ BoardDatas: res.data.data });
 })
  .catch(error => {
  console.log(error);
 })


 this.setState({ SprintDatas: [] });
 var URL1 = 'https://helpdeskservice.desss-portfolio.com/api/GridSprint/LoadBoard?ProjectID=' + e.target.value;
 axios.post(URL1)
 .then(res => {
   console.log('SprintDatas', res.data.data);
    if(res.data.data[0].errorMsg === "No Data Found"){
      return;
    }else{
   this.setState({ SprintDatas: res.data.data });
  }

})
 .catch(error => {
 console.log(error);
});

var module = 'https://helpdeskservice.desss-portfolio.com/api/ModuleList/LoadGrid?CompanyID='+this.state.LoginDatas.CompanyID+'&ProjectID='+e.target.value;
axios.post(module)
.then(res => {
this.setState({Module_Epic_Datas: res.data.data});
console.log('Module_Epic_Datas',  this.state.Module_Epic_Datas);
})
.catch(error => {
console.log(error);
})

this.setState({ AllotedToDatas: [] });
var Alloted = 'https://helpdeskservice.desss-portfolio.com/api/AllotedToDropdown/AllotedTO?CompanyID='+this.state.LoginDatas.CompanyID+'&ProjectID=' + e.target.value;
axios.post(Alloted)
.then(res1 => {
this.setState({AllotedToDatas: res1.data.data});
console.log('AllotedTo',  this.state.AllotedToDatas);
})
.catch(error => {
console.log(error);
})
}

IssueTypeDataMethod = (e) => {
  console.log('Board Datas', e.target.value);
  // this.setFieldValue("Projectname", e.target.value);
  this.setState({ IssueTypeDatas: [] });
  this.setState({ TaskCategoryID: e.target.value });
  var issue = 'https://helpdeskservice.desss-portfolio.com/api/TaskDropdown/loadTask?TaskCategory=' +  e.target.value;
  axios.post(issue)
  .then(res => {
  this.setState({IssueTypeDatas: res.data.data});
  console.log('IssueTypeDatas',  this.state.IssueTypeDatas);
  })
  .catch(error => {
  console.log(error);
  })
}
TaskstatusDatasMethod(){
  var URL = 'https://helpdeskservice.desss-portfolio.com/api/TaskStatus/LodeTaskStatus';
  axios.post(URL)
  .then(res => {
    console.log('TaskstatusDatas', res.data.data);
    this.setState({ TaskstatusDatas: res.data.data });
 })
  .catch(error => {
  console.log(error);
 })
}
ReportedByDataMethod(){
  var URL = 'https://helpdeskservice.desss-portfolio.com/api/AssignedBy/loadall?CompanyID=' + this.state.LoginDatas.CompanyID;
  axios.post(URL)
  .then(res => {
  this.setState({ReportedByData: res.data.data});
  console.log('ReportedByData',  this.state.ReportedByData);
  })
  .catch(error => {
  console.log(error);
  })
  }  

  WorkFlowDataMethod(){
    var workflow = 'https://helpdeskservice.desss-portfolio.com/api/SubModTaskCat/GetTaskCategory?CompanyID=' + this.state.LoginDatas.CompanyID;
    axios.get(workflow)
    .then(res => {
    this.setState({WorkFlowDatas: res.data.data});
    console.log('WorkFlowDatas',  this.state.WorkFlowDatas);
    })
    .catch(error => {
    console.log(error);
    })
    }

render() {
    return (
      <div>

         {this.state.LoaderShow === true ? (
          <div className='loader-img'>
            <img   src={Loader} height="50" width="50" alt="Helpdesk" />
          </div>
        ) : (
          ''
        )}

        <Header />

          <div>
  
<div class="centerBG" style={{border: '1px solid #898989'}}>


 <div
  style={{
    paddingBottom: '10px',
    paddingTop: '20px'
  }}
>
  <table>
    <tbody>
      <tr>
        <td>
          <div style={{paddingTop: '20px',marginLeft:'40px'}}>
            <span id="label_Text" style={{color: '#4c4a48', fontSize: '25px', fontWeight: 'bold'}}>Task Allotment Report</span>
          </div>
        </td>
        <td>
          <div style={{display: "flex",alignItems : "center",justifyContent:"center"}}>
            <span id="Label74" style={{fontSize:"17px",color:"#4c4a48",paddingRight:"10px"}}>Department : </span>
              <select name="system_ton" id="ddlTaskStatus" class="select_new MB10 fleft selector_s" onChange={this.DepartmentChange}  value={this.state.SelectedDepartment} style={{marginleft:"10px", width:"210px", padding: "10px"}}>
                                                              <option value="0">All</option>
                                                                { this.state.DepartmentData.map((element) => 
                                                                  <option value={element.DeptID}>{element.Department}</option>
                                                                )}
                                                            </select>
              <div style={{display: "flex"}}>
              <p style={{fontSize: "17px",margin: "0px",color:"#4c4a48"}} >Date :</p>
              <input id="dateRequired" type="text" value={this.state.selectedDate} readOnly style={{borderRight:"none"}} />
              <input id="dateRequired1" type="date" name="editmoduleFinaldate1" onChange={this.DateChange} style={{width: '18px',borderLeft:"none"}}/>
              <button class="tab-label"  style={{backgroundColor: "rgb(76, 74, 72)", color: "rgb(255, 255, 255)", border: "none", padding: "7px 15px", marginTop: "10px", borderRadius: "6px", marginRight: "10px",marginLeft:"20px"}} onClick={() =>this.GetDatas()}>View</button>
            </div>
                                                            
        </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div id="sortable-div1" class="drag_drop_main_containers">
        <div width="100%" class="drag_drop_table">
            <div class="thead" id="datedisplay">
              <div class="theadrow">
                <h3>{this.state.weekDates[0]}</h3>
                <h3>{this.state.weekDates[1]}</h3>
                <h3>{this.state.weekDates[2]}</h3>
                <h3>{this.state.weekDates[3]}</h3>
                <h3>{this.state.weekDates[4]}</h3>
                <h3>{this.state.weekDates[5]}</h3>              
              </div>
            </div>
        </div>
    </div>

{
 this.state.weeklyreportData.map((item) => (
  <div className="dept-5">
   <div className="hide-class deptid-5">
    <table>
      <tbody>
        <tr>
          <td>
            <h3 className="employe_name">
              Department :{item.Department}
            </h3>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  {
 item.Employees.map((item1) => (
<div>
<div
    className="hide-class deptemp-5 empname-31"
    style={{
      paddingLeft: '30px'
    }}
  >

    <table>
      <tbody>
        <tr>
          <td>
            <h3 className="employe_name">
              Employee : {item1.FullName}
            </h3>
          </td>
        </tr>
      </tbody>
    </table>

</div>



{
 item.Employees[0].Tasks.map((item2) => (  
<div className="drag_drop_main_container depttask-5  emptask-31">
  

    <div className="drag_drop_table">
      <div
        className="tbody"
        id="weeklytasklist-31"
      >
        <div
          className="trow"
          id="TasklistMuthu"
        >

       {/* Monday Start */}   
       {item2.TaskRowCount !== 0 && item2.DayCount === 1 ? 

         <div className="tdiv">
            <div className="drag_drop_container1">
              <div className="drag_contant">
                <ul
                  className="sortable-list drag_drop_list"
                  id="Monday104"
                >
                  <li className="sortable-item">
                  <div
  className="drop_top progress_border"
  id="52632"
>
  <h4
    style={{
      fontSize: '15px',
      fontWeight: '200'
    }}
  >
    {item2.ProjectName}
  </h4>
  <h4
    style={{
      fontSize: '15px',
      fontWeight: '200'
    }}
  >
    {item2.ModuleName}
  </h4>
  <p
    style={{
      color: '#cc0000',
      fontSize: '15px',
      fontWeight: '150'
    }}
  >
    Task :
    <span
      style={{
        color: 'black',
        fontSize: '15px',
        fontWeight: '100'
      }}
    >
       {item2.TaskName}
    </span>
  </p>
  <p
    style={{
      color: '#cc0000',
      fontSize: '15px',
      fontWeight: '150'
    }}
  >
    {' '}Allot Hrs :
    <span
      style={{
        color: 'black',
        fontSize: '15px',
        fontWeight: '100'
      }}
    >
     {item2.AllotedHrs}
    </span>
  </p>
  <p
    style={{
      color: '#cc0000',
      fontSize: '15px',
      fontWeight: '150'
    }}
  >
    {' '}Status:{' '}
    <span
      style={{
        color: 'black',
        fontSize: '15px',
        fontWeight: '100'
      }}
    >
       {item2.TaskStatus}
    </span>
  </p>
  <p
    style={{
      color: '#cc0000',
      fontSize: '15px',
      fontWeight: '150'
    }}
  >
    TimeSheet :
    <span
      style={{
        color: 'black',
        fontSize: '15px',
        fontWeight: '100'
      }}
    >
      {/* {' '}Not Updated{' '} */}
      {item2.TaskStatus === "Progress" ? <p>Not Updated</p> : <p>Updated</p>}
    </span>
  </p>
  <button className="Addtask" onClick={() =>this.AddShowPopupMethod()}>Add</button>
  {/* <a className="Addtask" href=" ">Add</a>
  <a className="Deletetask" href=" " target="_blank">Del</a>
  <a className="Edit" href=" ">Edit</a>
  <a className="Duplicate" href=" ">Dup</a> */}
</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          :

          <div className="tdiv">
            <div className="drag_drop_container1">
              <div className="drag_contant">
                <ul
                  className="sortable-list drag_drop_list"
                  id="Monday31"
                >
                  <li className="sortable-item">
                    <div className="drop_top progress_border">
                      <p
                        style={{
                          color: 'Red',
                          fontSize: '15px',
                          fontWeight: '150'
                        }}
                      >
                        {' '}No Task Alloted
                      </p>
                      {/* <a lassName="Addtask" href=" ">
                        Add
                      </a> */}
                      <button className="Addtask" onClick={() =>this.AddShowPopupMethod()}>Add</button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          
        }

          {/* Monday End */}

  {/* Tuesday Start */}   
    {item2.TaskRowCount !== 0 && item2.DayCount === 2 ? 

<div className="tdiv">
   <div className="drag_drop_container1">
     <div className="drag_contant">
       <ul
         className="sortable-list drag_drop_list"
         id="Monday104"
       >
         <li className="sortable-item">
         <div
className="drop_top progress_border"
id="52632"
>
<h4
style={{
fontSize: '15px',
fontWeight: '200'
}}
>
{item2.ProjectName}
</h4>
<h4
style={{
fontSize: '15px',
fontWeight: '200'
}}
>
{item2.ModuleName}
</h4>
<p
style={{
color: '#cc0000',
fontSize: '15px',
fontWeight: '150'
}}
>
Task :
<span
style={{
color: 'black',
fontSize: '15px',
fontWeight: '100'
}}
>
{item2.TaskName}
</span>
</p>
<p
style={{
color: '#cc0000',
fontSize: '15px',
fontWeight: '150'
}}
>
{' '}Allot Hrs :
<span
style={{
color: 'black',
fontSize: '15px',
fontWeight: '100'
}}
>
{item2.AllotedHrs}
</span>
</p>
<p
style={{
color: '#cc0000',
fontSize: '15px',
fontWeight: '150'
}}
>
{' '}Status:{' '}
<span
style={{
color: 'black',
fontSize: '15px',
fontWeight: '100'
}}
>
{item2.TaskStatus}
</span>
</p>
<p
style={{
color: '#cc0000',
fontSize: '15px',
fontWeight: '150'
}}
>
TimeSheet :
<span
style={{
color: 'black',
fontSize: '15px',
fontWeight: '100'
}}
>
{/* {' '}Not Updated{' '} */}
{item2.TaskStatus === "Progress" ? <p>Not Updated</p> : <p>Updated</p>}
</span>
</p>
<button className="Addtask" onClick={() =>this.AddShowPopupMethod()}>Add</button>
{/* <a className="Addtask" href=" ">Add</a>
<a className="Deletetask" href=" " target="_blank">Del</a>
<a className="Edit" href=" ">Edit</a>
<a className="Duplicate" href=" ">Dup</a> */}
</div>
         </li>
       </ul>
     </div>
   </div>
 </div>

 :

 <div className="tdiv">
   <div className="drag_drop_container1">
     <div className="drag_contant">
       <ul
         className="sortable-list drag_drop_list"
         id="Monday31"
       >
         <li className="sortable-item">
           <div className="drop_top progress_border">
             <p
               style={{
                 color: 'Red',
                 fontSize: '15px',
                 fontWeight: '150'
               }}
             >
               {' '}No Task Alloted
             </p>
             {/* <a lassName="Addtask" href=" ">
               Add
             </a> */}
             <button className="Addtask" onClick={() =>this.AddShowPopupMethod()}>Add</button>
           </div>
         </li>
       </ul>
     </div>
   </div>
 </div>
 
}

 {/* Tuesday End */}


  {/* Wednesday  Start */}   
  {item2.TaskRowCount !== 0 && item2.DayCount === 3 ? 

<div className="tdiv">
   <div className="drag_drop_container1">
     <div className="drag_contant">
       <ul
         className="sortable-list drag_drop_list"
         id="Monday104"
       >
         <li className="sortable-item">
         <div
className="drop_top progress_border"
id="52632"
>
<h4
style={{
fontSize: '15px',
fontWeight: '200'
}}
>
{item2.ProjectName}
</h4>
<h4
style={{
fontSize: '15px',
fontWeight: '200'
}}
>
{item2.ModuleName}
</h4>
<p
style={{
color: '#cc0000',
fontSize: '15px',
fontWeight: '150'
}}
>
Task :
<span
style={{
color: 'black',
fontSize: '15px',
fontWeight: '100'
}}
>
{item2.TaskName}
</span>
</p>
<p
style={{
color: '#cc0000',
fontSize: '15px',
fontWeight: '150'
}}
>
{' '}Allot Hrs :
<span
style={{
color: 'black',
fontSize: '15px',
fontWeight: '100'
}}
>
{item2.AllotedHrs}
</span>
</p>
<p
style={{
color: '#cc0000',
fontSize: '15px',
fontWeight: '150'
}}
>
{' '}Status:{' '}
<span
style={{
color: 'black',
fontSize: '15px',
fontWeight: '100'
}}
>
{item2.TaskStatus}
</span>
</p>
<p
style={{
color: '#cc0000',
fontSize: '15px',
fontWeight: '150'
}}
>
TimeSheet :
<span
style={{
color: 'black',
fontSize: '15px',
fontWeight: '100'
}}
>
{/* {' '}Not Updated{' '} */}
{item2.TaskStatus === "Progress" ? <p>Not Updated</p> : <p>Updated</p>}
</span>
</p>
<button className="Addtask" onClick={() =>this.AddShowPopupMethod()}>Add</button>
{/* <a className="Addtask" href=" ">Add</a>
<a className="Deletetask" href=" " target="_blank">Del</a>
<a className="Edit" href=" ">Edit</a>
<a className="Duplicate" href=" ">Dup</a> */}
</div>
         </li>
       </ul>
     </div>
   </div>
 </div>

 :

 <div className="tdiv">
   <div className="drag_drop_container1">
     <div className="drag_contant">
       <ul
         className="sortable-list drag_drop_list"
         id="Monday31"
       >
         <li className="sortable-item">
           <div className="drop_top progress_border">
             <p
               style={{
                 color: 'Red',
                 fontSize: '15px',
                 fontWeight: '150'
               }}
             >
               {' '}No Task Alloted
             </p>
             {/* <a lassName="Addtask" href=" ">
               Add
             </a> */}
             <button className="Addtask" onClick={() =>this.AddShowPopupMethod()}>Add</button>
           </div>
         </li>
       </ul>
     </div>
   </div>
 </div>
 
}

 {/* Wednesday End */}


  {/* Thursday Start */}   
  {item2.TaskRowCount !== 0 && item2.DayCount === 4 ? 

<div className="tdiv">
   <div className="drag_drop_container1">
     <div className="drag_contant">
       <ul
         className="sortable-list drag_drop_list"
         id="Monday104"
       >
         <li className="sortable-item">
         <div
className="drop_top progress_border"
id="52632"
>
<h4
style={{
fontSize: '15px',
fontWeight: '200'
}}
>
{item2.ProjectName}
</h4>
<h4
style={{
fontSize: '15px',
fontWeight: '200'
}}
>
{item2.ModuleName}
</h4>
<p
style={{
color: '#cc0000',
fontSize: '15px',
fontWeight: '150'
}}
>
Task :
<span
style={{
color: 'black',
fontSize: '15px',
fontWeight: '100'
}}
>
{item2.TaskName}
</span>
</p>
<p
style={{
color: '#cc0000',
fontSize: '15px',
fontWeight: '150'
}}
>
{' '}Allot Hrs :
<span
style={{
color: 'black',
fontSize: '15px',
fontWeight: '100'
}}
>
{item2.AllotedHrs}
</span>
</p>
<p
style={{
color: '#cc0000',
fontSize: '15px',
fontWeight: '150'
}}
>
{' '}Status:{' '}
<span
style={{
color: 'black',
fontSize: '15px',
fontWeight: '100'
}}
>
{item2.TaskStatus}
</span>
</p>
<p
style={{
color: '#cc0000',
fontSize: '15px',
fontWeight: '150'
}}
>
TimeSheet :
<span
style={{
color: 'black',
fontSize: '15px',
fontWeight: '100'
}}
>
{/* {' '}Not Updated{' '} */}
{item2.TaskStatus === "Progress" ? <p>Not Updated</p> : <p>Updated</p>}
</span>
</p>
<button className="Addtask" onClick={() =>this.AddShowPopupMethod()}>Add</button>
{/* <a className="Addtask" href=" ">Add</a>
<a className="Deletetask" href=" " target="_blank">Del</a>
<a className="Edit" href=" ">Edit</a>
<a className="Duplicate" href=" ">Dup</a> */}
</div>
         </li>
       </ul>
     </div>
   </div>
 </div>

 :

 <div className="tdiv">
   <div className="drag_drop_container1">
     <div className="drag_contant">
       <ul
         className="sortable-list drag_drop_list"
         id="Monday31"
       >
         <li className="sortable-item">
           <div className="drop_top progress_border">
             <p
               style={{
                 color: 'Red',
                 fontSize: '15px',
                 fontWeight: '150'
               }}
             >
               {' '}No Task Alloted
             </p>
             {/* <a lassName="Addtask" href=" ">
               Add
             </a> */}
             <button className="Addtask" onClick={() =>this.AddShowPopupMethod()}>Add</button>
           </div>
         </li>
       </ul>
     </div>
   </div>
 </div>
 
}

 {/* Thursday End */}

  {/* Friday Start */}   
  {item2.TaskRowCount !== 0 && item2.DayCount === 5 ? 

<div className="tdiv">
   <div className="drag_drop_container1">
     <div className="drag_contant">
       <ul
         className="sortable-list drag_drop_list"
         id="Monday104"
       >
         <li className="sortable-item">
         <div
className="drop_top progress_border"
id="52632"
>
<h4
style={{
fontSize: '15px',
fontWeight: '200'
}}
>
{item2.ProjectName}
</h4>
<h4
style={{
fontSize: '15px',
fontWeight: '200'
}}
>
{item2.ModuleName}
</h4>
<p
style={{
color: '#cc0000',
fontSize: '15px',
fontWeight: '150'
}}
>
Task :
<span
style={{
color: 'black',
fontSize: '15px',
fontWeight: '100'
}}
>
{item2.TaskName}
</span>
</p>
<p
style={{
color: '#cc0000',
fontSize: '15px',
fontWeight: '150'
}}
>
{' '}Allot Hrs :
<span
style={{
color: 'black',
fontSize: '15px',
fontWeight: '100'
}}
>
{item2.AllotedHrs}
</span>
</p>
<p
style={{
color: '#cc0000',
fontSize: '15px',
fontWeight: '150'
}}
>
{' '}Status:{' '}
<span
style={{
color: 'black',
fontSize: '15px',
fontWeight: '100'
}}
>
{item2.TaskStatus}
</span>
</p>
<p
style={{
color: '#cc0000',
fontSize: '15px',
fontWeight: '150'
}}
>
TimeSheet :
<span
style={{
color: 'black',
fontSize: '15px',
fontWeight: '100'
}}
>
{/* {' '}Not Updated{' '} */}
{item2.TaskStatus === "Progress" ? <p>Not Updated</p> : <p>Updated</p>}
</span>
</p>
<button className="Addtask" onClick={() =>this.AddShowPopupMethod()}>Add</button>
{/* <a className="Addtask" href=" ">Add</a>
<a className="Deletetask" href=" " target="_blank">Del</a>
<a className="Edit" href=" ">Edit</a>
<a className="Duplicate" href=" ">Dup</a> */}
</div>
         </li>
       </ul>
     </div>
   </div>
 </div>

 :

 <div className="tdiv">
   <div className="drag_drop_container1">
     <div className="drag_contant">
       <ul
         className="sortable-list drag_drop_list"
         id="Monday31"
       >
         <li className="sortable-item">
           <div className="drop_top progress_border">
             <p
               style={{
                 color: 'Red',
                 fontSize: '15px',
                 fontWeight: '150'
               }}
             >
               {' '}No Task Alloted
             </p>
             {/* <a lassName="Addtask" href=" ">
               Add
             </a> */}
             <button className="Addtask" onClick={() =>this.AddShowPopupMethod()}>Add</button>
           </div>
         </li>
       </ul>
     </div>
   </div>
 </div>
 
}

 {/* Friday End */}

  {/* Saturday Start */}   
  {item2.TaskRowCount !== 0 && item2.DayCount === 5 ? 

<div className="tdiv">
   <div className="drag_drop_container1">
     <div className="drag_contant">
       <ul
         className="sortable-list drag_drop_list"
         id="Monday104"
       >
         <li className="sortable-item">
         <div
className="drop_top progress_border"
id="52632"
>
<h4
style={{
fontSize: '15px',
fontWeight: '200'
}}
>
{item2.ProjectName}
</h4>
<h4
style={{
fontSize: '15px',
fontWeight: '200'
}}
>
{item2.ModuleName}
</h4>
<p
style={{
color: '#cc0000',
fontSize: '15px',
fontWeight: '150'
}}
>
Task :
<span
style={{
color: 'black',
fontSize: '15px',
fontWeight: '100'
}}
>
{item2.TaskName}
</span>
</p>
<p
style={{
color: '#cc0000',
fontSize: '15px',
fontWeight: '150'
}}
>
{' '}Allot Hrs :
<span
style={{
color: 'black',
fontSize: '15px',
fontWeight: '100'
}}
>
{item2.AllotedHrs}
</span>
</p>
<p
style={{
color: '#cc0000',
fontSize: '15px',
fontWeight: '150'
}}
>
{' '}Status:{' '}
<span
style={{
color: 'black',
fontSize: '15px',
fontWeight: '100'
}}
>
{item2.TaskStatus}
</span>
</p>
<p
style={{
color: '#cc0000',
fontSize: '15px',
fontWeight: '150'
}}
>
TimeSheet :
<span
style={{
color: 'black',
fontSize: '15px',
fontWeight: '100'
}}
>
{/* {' '}Not Updated{' '} */}
{item2.TaskStatus === "Progress" ? <p>Not Updated</p> : <p>Updated</p>}
</span>
</p>
<button className="Addtask" onClick={() =>this.AddShowPopupMethod()}>Add</button>
{/* <a className="Addtask" href=" ">Add</a>
<a className="Deletetask" href=" " target="_blank">Del</a>
<a className="Edit" href=" ">Edit</a>
<a className="Duplicate" href=" ">Dup</a> */}
</div>
         </li>
       </ul>
     </div>
   </div>
 </div>

 :

 <div className="tdiv">
   <div className="drag_drop_container1">
     <div className="drag_contant">
       <ul
         className="sortable-list drag_drop_list"
         id="Monday31"
       >
         <li className="sortable-item">
           <div className="drop_top progress_border">
             <p
               style={{
                 color: 'Red',
                 fontSize: '15px',
                 fontWeight: '150'
               }}
             >
               {' '}No Task Alloted
             </p>
             {/* <a lassName="Addtask" href=" ">
               Add
             </a> */}
             <button className="Addtask" onClick={() =>this.AddShowPopupMethod()}>Add</button>
           </div>
         </li>
       </ul>
     </div>
   </div>
 </div>
 
}

 {/* Saturday End */}
 


        </div>
      </div>
    </div>

</div>
  ))
}

</div>

))
}

  {/* <div
    className="hide-class deptemp-5 empname-104"
    style={{
      paddingLeft: '30px'
    }}
  >
    <table>
      <tbody>
        <tr>
          <td>
            <h3 className="employe_name">
              Employee : Aravindasamy R
            </h3>
          </td>
        </tr>
      </tbody>
    </table>

  </div>
  <div className="drag_drop_main_container depttask-5  emptask-104">
    <div className="drag_drop_table">
      <div
        className="tbody"
        id="weeklytasklist-104"
      >
        <div
          className="trow"
          id="TasklistAravindasamy"
        >
          <div className="tdiv">
            <div className="drag_drop_container1">
              <div className="drag_contant">
                <ul
                  className="sortable-list drag_drop_list"
                  id="Monday104"
                >
                  <li className="sortable-item">
                  <div
  className="drop_top progress_border"
  id="52632"
>
  <h4
    style={{
      fontSize: '15px',
      fontWeight: '200'
    }}
  >
    jet rubber website
  </h4>
  <h4
    style={{
      fontSize: '15px',
      fontWeight: '200'
    }}
  >
    components
  </h4>
  <p
    style={{
      color: '#cc0000',
      fontSize: '15px',
      fontWeight: '150'
    }}
  >
    Task :
    <span
      style={{
        color: 'black',
        fontSize: '15px',
        fontWeight: '100'
      }}
    >
      admin mobile view
    </span>
  </p>
  <p
    style={{
      color: '#cc0000',
      fontSize: '15px',
      fontWeight: '150'
    }}
  >
    {' '}Allot Hrs :
    <span
      style={{
        color: 'black',
        fontSize: '15px',
        fontWeight: '100'
      }}
    >
      9
    </span>
  </p>
  <p
    style={{
      color: '#cc0000',
      fontSize: '15px',
      fontWeight: '150'
    }}
  >
    {' '}Status:{' '}
    <span
      style={{
        color: 'black',
        fontSize: '15px',
        fontWeight: '100'
      }}
    >
      Completed
    </span>
  </p>
  <p
    style={{
      color: '#cc0000',
      fontSize: '15px',
      fontWeight: '150'
    }}
  >
    TimeSheet :
    <span
      style={{
        color: 'black',
        fontSize: '15px',
        fontWeight: '100'
      }}
    >
      {' '}Not Updated{' '}
    </span>
  </p>
  <a className="Addtask" href=" ">Add</a>
  <a className="Deletetask" href=" " target="_blank">Del</a>
  <a className="Edit" href=" ">Edit</a>
  <a className="Duplicate" href=" ">Dup</a>
</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="tdiv">
            <div className="drag_drop_container1">
              <div className="drag_contant">
                <ul
                  className="sortable-list drag_drop_list"
                  id="Tuesday104"
                >
                  <li className="sortable-item">
                    <div className="drop_top progress_border">
                      <p
                        style={{
                          color: 'Red',
                          fontSize: '15px',
                          fontWeight: '150'
                        }}
                      >
                        {' '}No Task Alloted
                      </p>
                      <a lassName="Addtask" href=" ">
                        Add
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="tdiv">
            <div className="drag_drop_container1">
              <div className="drag_contant">
                <ul
                  className="sortable-list drag_drop_list"
                  id="Wednesday104"
                >
                  <li className="sortable-item">
                    <div className="drop_top progress_border">
                      <p
                        style={{
                          color: 'Red',
                          fontSize: '15px',
                          fontWeight: '150'
                        }}
                      >
                        {' '}No Task Alloted
                      </p>
                      <a lassName="Addtask" href=" ">
                        Add
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="tdiv">
            <div className="drag_drop_container1">
              <div className="drag_contant">
                <ul
                  className="sortable-list drag_drop_list"
                  id="Thursday104"
                >
                  <li className="sortable-item">
                    <div className="drop_top progress_border">
                      <p
                        style={{
                          color: 'Red',
                          fontSize: '15px',
                          fontWeight: '150'
                        }}
                      >
                        {' '}No Task Alloted
                      </p>
                      <a lassName="Addtask" href=" ">
                        Add
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="tdiv">
            <div className="drag_drop_container1">
              <div className="drag_contant">
                <ul
                  className="sortable-list drag_drop_list"
                  id="Friday104"
                >
                  <li className="sortable-item">
                    <div className="drop_top progress_border">
                      <p
                        style={{
                          color: 'Red',
                          fontSize: '15px',
                          fontWeight: '150'
                        }}
                      >
                        {' '}No Task Alloted
                      </p>
                      <a lassName="Addtask" href=" ">
                        Add
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="tdiv">
            <div className="drag_drop_container1">
              <div className="drag_contant">
                <ul
                  className="sortable-list drag_drop_list"
                  id="Saturday104"
                >
                  <li className="sortable-item">
                    <div className="drop_top progress_border">
                      <p
                        style={{
                          color: 'Red',
                          fontSize: '15px',
                          fontWeight: '150'
                        }}
                      >
                        {' '}No Task Alloted
                      </p>
                      <a lassName="Addtask" href=" ">
                        Add
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}


</div>
    
))
}

</div>    
  
</div>

{this.state.AddShowPopup && (

<div className="popup-create boardpopup-grid">
<div className='popup-body'>

<Formik
          initialValues={{
            IssueName: '',
            IssueType: '',
            Module_Epic: '',
            // WorkFlow: '',
            // Priority: '',
            StartDate: '',
            // Reportedby: '',
            AssignedTo: '',
            AssignedBy: '',
            Taskstatus:'',
            AllotedHrs:'',
          }}
          validationSchema={AddTaskLogsValidation}
          onSubmit={values => {
            console.log('AddBackLogsValidation', values);

            var Description = document.getElementById('Description').value;
            // var Analysis = document.getElementById('Task_Analysis').value;
            var Boardid = document.getElementById('Board').value;
            var Sprintid = document.getElementById('Sprint').value;
            var Billable = document.getElementById('IsBillable').checked;
            // var Active = document.getElementById('IsActive').checked;
            // var Attachment = document.getElementById('FUAttachments').value;

          var date = moment(values.StartDate).format("MM/DD/YYYY")
            var AddTask = 'http://helpdeskservice.desss-portfolio.com/api/TaskLogNew/SaveTaskallotment/?ProjectID=' + this.state.ProjectID + '&UserName=' + this.state.LoginDatas.EmpName + '&CompanyID='+this.state.LoginDatas.CompanyID+'&IssueName='+values.IssueName+'&ProjectName='+this.state.ProjectID+'&ModuleName='+values.Module_Epic+'&TaskCategory='+this.state.TaskCategoryID+'&TaskType='+values.IssueType+'&TaskStatus='+values.Taskstatus+'&TaskDate='+date+'&AssignedBy='+values.AssignedBy+'&AssignedTo='+values.AssignedTo+'&ImportStatus=&ApprovedStatus=Approved&AllotedHours='+values.AllotedHrs+'&Description='+Description+'&Billable='+Billable+'&BoardName='+Boardid+'&SprintName=' + Sprintid;
            this.setState({ LoaderShow: true }); 
            axios.post(AddTask)
            .then(AddTaskDatas => {
              this.setState({ LoaderShow: false }); 
               this.setState({ AddShowPopup: false });
               Swal.fire({
                icon: "success",
                title: "Task Added Successfully",
                showConfirmButton: false,
                timer: 1500
              });
              this.GetDatas();
              console.log('AddTaskDatas',  AddTaskDatas.data.data);
            })
            .catch(error => {
              this.setState({ LoaderShow: false }); 
             this.setState({ AddShowPopup: false });
             this.GetDatas();
              console.log(error);
              
            })
          }}

         
        >

{formik => (
   
 <Form class="addtsk_frm_fld">
     <div className="dxpc-headerContent" style={{float:'left'}}>
      <span className="dxpc-headerText" id="Grid_DXPEForm_PWH-1T">Add Task</span>
    </div>
    <button className='popup-closebtn' onClick={this.AddPopupClose} style={{float:'right'}}><img style={{width: "30px" ,height: "30px",}} src={clos_ic} alt="logout" /></button>
        <table id="Grid_DXPEForm_DXEFT" class="submodule-newpopup" cellSpacing={0} cellPadding={0} border={0} style={{fontFamily: 'Calibri', fontSize: 15, width: '100%', borderCollapse: 'collapse', padding:'15px 0px',display:'flex',borderRadius:'10px',marginBottom:'0px'}}>
          <tbody style={{width:'100%'}}>
            <div class="add_tsk_inpt_fld1">
              {/* <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Project Name</label>
              </td> */}
              <div className="dxgvEditFormCell_Office2003Blue seldct_drop_ic" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <label class="drop_lef_img"><img style={{width: "25px" ,height: "25px",}} src={proj_nam} alt="logout" /></label>
              <span class="drop_rit_img"><img style={{width: "20px" ,height: "20px",}} src={drop_dwn_ic} alt="logout" /></span>
              <select name="Projectname" class="selector_s" id='Projectname' as="select" onChange={this.BoardDatasMethod} value={this.state.ProjectID} style={{width: '100%'}}>
              {/* <Field name="Projectname" id='Projectname' as="select" style={{width: '100%'}}> */}
              <option>Project Name</option>
                    { this.state.project_data.map((element) => 
                      <option  value={element.ProjectID}>{element.ProjectName}</option>
                    )}
              </select>


              </div>
            
            <div class="add_tsk_fld_rite">
              {/* <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Module</label>
              </td> */}
              <div className="dxgvEditFormCell_Office2003Blue seldct_drop_ic" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <label class="drop_lef_img"><img style={{width: "25px" ,height: "25px",}} src={module_ic} alt="logout" /></label>
              <span class="drop_rit_img"><img style={{width: "20px" ,height: "20px",}} src={drop_dwn_ic} alt="logout" /></span>
              <Field name="Module_Epic" id='Module_Epic' class="selector_s" as="select" style={{width: '100%'}}>
              <option>Module</option>
                    { this.state.Module_Epic_Datas.map((element) => 
                      <option  value={element.ModuleID}>{element.ModuleName}</option>
                    )}
              </Field>
             <ErrorMessage component="Module_Epic" className=" form-label text-danger" name="Module_Epic" />

              </div>
            </div>
            </div>

            <div class="add_tsk_inpt_fld2 seldct_drop_ic">
              {/* <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Issue Name</label>
              </td> */}
              
              <div className="dxgvEditFormCell_Office2003Blue" class="issu_nam_inpfld" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <label class="drop_lef_img"><img style={{width: "25px" ,height: "25px",}} src={issue_nam_ic} alt="logout" /></label>
              <Field name="IssueName" type="text" placeholder="Issue Name" style={{width: '100%'}} />
                  <ErrorMessage component="label" className=" form-label text-danger" name="IssueName" />
              </div>
              
           

           
              {/* <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>TaskCategory</label>
              </td> */}
              <div className="dxgvEditFormCell_Office2003Blue seldct_drop_ic" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              {/* <DatePicker  name="editmoduleFinaldate1"  selected={this.state.FinalEndDate} style={{width: '100%'}} dateFormat="dd-mm-yyyy"/> */}
              <label class="drop_lef_img"><img style={{width: "25px" ,height: "25px",}} src={tsk_cat_ic} alt="logout" /></label>
              <span class="drop_rit_img"><img style={{width: "20px" ,height: "20px",}} src={drop_dwn_ic} alt="logout" /></span>
              <select name="WorkFlow" id='WorkFlow' class="selector_s" as="select" onChange={this.IssueTypeDataMethod} value={this.state.TaskCategoryID} style={{width: '100%'}}>
              <option>TaskCategory</option>
                    { this.state.WorkFlowDatas.map((element) => 
                      <option  value={element.TaskCategoryID}>{element.Description}</option>
                    )}
              </select>

              {/* <Field name="WorkFlow" id='WorkFlow' as="select" style={{width: '100%'}}>
              <option>--Select--</option>
                    { this.state.WorkFlowDatas.map((element) => 
                      <option  value={element.TaskCategoryID}>{element.Description}</option>
                    )}
              </Field>
             <ErrorMessage component="WorkFlow" className=" form-label text-danger" name="WorkFlow" /> */}

              </div>
            
            </div>


            <div class="add_tsk_inpt_fld3">
           
              {/* <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>TaskType</label>
              </td> */}
              <div className="dxgvEditFormCell_Office2003Blue seldct_drop_ic" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <label class="drop_lef_img"><img style={{width: "25px" ,height: "25px",}} src={tsktype_ic} alt="logout" /></label>
              <span class="drop_rit_img"><img style={{width: "20px" ,height: "20px",}} src={drop_dwn_ic} alt="logout" /></span>
              <Field name="IssueType" class="selector_s" id='IssueType' as="select" style={{width: '100%'}}>
              <option>TaskType</option>
                    { this.state.IssueTypeDatas.map((element) => 
                      <option  value={element.TaskID}>{element.TaskDescription}</option>
                    )}
              </Field>
             <ErrorMessage component="IssueType" className=" form-label text-danger" name="IssueType" />

              </div>
           

           
              {/* <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Date</label>
              </td> */}
              <div className="dxgvEditFormCell_Office2003Blue datfltbx" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <Field name="StartDate" class="dat_bx_icon" id='StartDate' type="date" style={{width: '100%'}} >
              
              </Field>
               <ErrorMessage component="label" className=" form-label text-danger" name="StartDate" />
              </div>
            
            </div>

            <div class="add_tsk_inpt_fld4">

           
              {/* <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Board</label>
              </td> */}
              <div className="dxgvEditFormCell_Office2003Blue seldct_drop_ic" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <label class="drop_lef_img"><img style={{width: "25px" ,height: "25px",}} src={board_ic} alt="logout" /></label>
              <span class="drop_rit_img"><img style={{width: "20px" ,height: "20px",}} src={drop_dwn_ic} alt="logout" /></span>
              <select name="Board" id='Board' class="selector_s" as="select" style={{width: '100%'}} >
              <option value={0}>Board</option>
                    { this.state.BoardDatas.map((element) => 
                      <option  value={element.BoardID}>{element.BoardName}</option>
                    )}
                </select>                  
              </div>
           

          
              {/* <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Sprint</label>
              </td> */}
              <div className="dxgvEditFormCell_Office2003Blue seldct_drop_ic" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <label class="drop_lef_img"><img style={{width: "auto" ,height: "25px",}} src={sprint_ic} alt="logout" /></label>
              <span class="drop_rit_img"><img style={{width: "20px" ,height: "20px",}} src={drop_dwn_ic} alt="logout" /></span>
              <select name="Sprint" class="selector_s" id='Sprint' as="select" style={{width: '100%'}} >
              <option value={0}>Sprint</option>
                    { this.state.SprintDatas.map((element) => 
                      <option  value={element.SprintID}>{element.SprintName}</option>
                    )}
                </select>                  
              </div>
            
            </div>

            <div class="add_tsk_inpt_fld5">
            
              {/* <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Task Status</label>
              </td> */}
              <div className="dxgvEditFormCell_Office2003Blue seldct_drop_ic" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <label class="drop_lef_img"><img style={{width: "25px" ,height: "25px",}} src={tsk_status_ic} alt="logout" /></label>
              <span class="drop_rit_img"><img style={{width: "20px" ,height: "20px",}} src={drop_dwn_ic} alt="logout" /></span>
              <Field name="Taskstatus" class="selector_s" id='Taskstatus' as="select" style={{width: '100%'}} >
              <option>Task Status</option>
                    { this.state.TaskstatusDatas.map((element) => 
                      <option  value={element.TaskStatusID}>{element.TaskStatusDescription}</option>
                    )}
              </Field>
              {/* <Field name="Taskstatus" id="Taskstatus" defaultValue={this.state.EditTaskAllotDatas.TaskStatus} type="text" style={{width: '100%'}}/> */}
                  <ErrorMessage component="Taskstatus" className=" form-label text-danger" name="Taskstatus" />
              </div>
            

            
              {/* <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Assigned By</label>
              </td> */}
              <div className="dxgvEditFormCell_Office2003Blue seldct_drop_ic" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <label class="drop_lef_img"><img style={{width: "25px" ,height: "25px",}} src={assing_by_ic} alt="logout" /></label>
              <span class="drop_rit_img"><img style={{width: "20px" ,height: "20px",}} src={drop_dwn_ic} alt="logout" /></span>
              <Field name="AssignedBy" class="selector_s" id='AssignedBy' as="select" style={{width: '100%'}} >
              <option value={0}>Assigned By</option>
                    { this.state.ReportedByData.map((element) => 
                      <option  value={element.EmpID}>{element.EmpName}</option>
                    )}
                </Field>                  
                    <ErrorMessage component="label" className="form-label text-danger" name="AssignedBy" />
              </div>
            
            </div>

            <div class="add_tsk_inpt_fld6">
            
              {/* <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Assigned To</label>
              </td> */}
              <div className="dxgvEditFormCell_Office2003Blue seldct_drop_ic" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <label class="drop_lef_img"><img style={{width: "25px" ,height: "25px",}} src={assign_to_ic} alt="logout" /></label>
              <span class="drop_rit_img"><img style={{width: "20px" ,height: "20px",}} src={drop_dwn_ic} alt="logout" /></span> 
              <Field name="AssignedTo" class="selector_s" id='AssignedTo' as="select" style={{width: '100%'}} >
              <option>Assigned To</option>
                    { this.state.AllotedToDatas.map((element) => 
                      <option  value={element.EmpID}>{element.EName}</option>
                    )}
                </Field>                  
                    <ErrorMessage component="label" className="form-label text-danger" name="AssignedTo" />
              </div>
            

            
              {/* <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Alloted Hours</label>
              </td> */}
              <div className="dxgvEditFormCell_Office2003Blue seldct_drop_ic" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <label class="drop_lef_img"><img style={{width: "25px" ,height: "25px",}} src={allot_hrs_ic} alt="logout" /></label>
              <Field name="AllotedHrs" type="number" class="allot_hrs_fld" placeholder="Alloted Hours" style={{width: '100%'}} />
                  <ErrorMessage component="label" className=" form-label text-danger" name="AllotedHrs" />
              </div>
            
            </div>

            <div>
              {/* <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Description</label>
              </td> */}
              <div className="dxgvEditFormCell_Office2003Blue"  colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <textarea  name="Description" id='Description' placeholder='Description' type="textarea"  style={{width: '100%'}} />  
              </div>
            </div>

 
            <div class="is_bill_radiobtn">
              <div className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label_x'>IsBillable</label>
              </div>
              <div className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
                <input name="IsBillable" id='IsBillable' type="checkbox" style={{width: ''}} />  
              </div>
            </div>

          </tbody>
        </table>

       <div class="popup-update">
        <button type='submit' >Save</button>
        {/* <button >Save and Exit</button> */}
        </div>
        </Form>
    )}

 </Formik>
 </div>
  </div>
)}

<div className='free-space'></div>
        <Footer />
      </div>
    )
  }
}

export default weeklyreport