import React, { Component } from 'react'
import Header from './header';
import Footer from './footer';
import axios from 'axios';
import moment from 'moment';
import Loader from '../images/loader2.gif';

export class assigned extends Component {
  constructor() {
    super();
    
    this.state = {
      LoginDatas:  JSON.parse(localStorage.getItem("HelpDeskLoginDatas")),
      TodayDate: new Date(),
      project_data : [],
      AssignedData: [],
      ProjectName: 'All',
      ProjectId: '0',
      Auth:  JSON.parse(localStorage.getItem("auth")),
      LoaderShow: false,
      selectedDate: moment(new Date()).format("MM/DD/YYYY"),
      selectedDate2: moment(new Date()).format("MM/DD/YYYY"),
      
    };
  }

componentDidMount(){

        axios.post('https://helpdeskservice.desss-portfolio.com/api/LodeProjectBasedEMP/Project?UserType_Id='+this.state.LoginDatas.UserTypeID+'&Emp_id=' + this.state.LoginDatas.EmpID + '&Dept_id=' + this.state.LoginDatas.DeptID)
        .then( project => {
          var data = project.data.data;
          console.log("project" , project.data.data)
          data.map((key) =>{
            this.setState({ project_data: [ ...this.state.project_data, {
              ProjectName: key.ProjectName ,
              ProjectID: key.ProjectID
           }]
         });
         });
        })
        .catch(error => {
            console.log(error);
        }) 
        this.GetDatas('0', 'All');
}
GetDatas(Id, Name){
  var Assigned = [];
  
  this.setState({ LoaderShow: true });  
  this.setState({ProjectName: Name});
  this.setState({ProjectId: Id});

  this.setState({ AssignedData: Assigned });

  var date =  moment(new Date()).format("MM/DD/YYYY");
    axios.get('https://helpdeskservice.desss-portfolio.com/api/CardViewAssUnCom/LoadAssUnAssCom?EmpID=' + this.state.LoginDatas.EmpID + '&user_projectname=' + Name + '&user_projectid=' + Id + '&UserTypeID='+this.state.LoginDatas.UserTypeID+'&DeptID=' + this.state.LoginDatas.DeptID)
    .then(res => {
      var data = res.data.data;
      this.setState({ LoaderShow: false });  
      if(data[0].errorMsg === "No Data Found"){
        return;
      }
      data.forEach((key, value) => {   
        Assigned.push({
            TaskName: key.TaskName, 
            TaskStausID: key.TaskStausID,
            CardviewStatus: key.CardviewStatus,
            CreatedBy: key.CreatedBy,
            FullName: key.FullName,
            CreatedDate: moment(key.CreatedDate).format("MM/DD/YYYY"),
            AllotedDate: moment(key.AllotedDate).format("MM/DD/YYYY"),
            AllotedHrs: key.AllotedHrs,
            Est_CompletedDate:  moment(key.Est_CompletedDate).format("MM/DD/YYYY"),
            PrjTaskID: key.PrjTaskID,
            // PrjTaskAllotID: key.PrjTaskAllotID,
            ProjectName: key.ProjectName,
            ModuleName: key.ModuleName,
          });
          
      });
      this.setState({ AssignedData: Assigned });
      console.log('AssignedData', this.state.AssignedData);
      // console.log('Assigned', this.state.Assigned);
    }) 
    .catch(error => {
      this.setState({ LoaderShow: false });  
        console.log(error);
    })

}

ProjectChange = (e) => {
  // document.getElementById('dateRequired').value = '';
  var data = e.target.value;
  var splitted = data.split("~");
  this.setState({ ProjectId: splitted[0] });
  this.setState({ ProjectName: splitted[1] });
  this.GetDatas(splitted[0], splitted[1]);
  console.log('projectChange', this.state.ProjectId, this.state.ProjectName);
}
  render() {
    return (
      <div>
        {this.state.LoaderShow === true ? (
          <div className='loader-img'>
            <img   src={Loader} height="50" width="50" alt="Helpdesk" />
          </div>
        ) : (
          ''
        )}
        <Header />

          <div class="centerBG">
  <div style={{ marginLeft: 138, marginTop: 19, paddingBottom: 10 }}>
    <span id="Label74">Project : </span>
    <br />
    <select
      name="ctl00$MainContent$txtProjectList_User"
      id="txtProjectList_User"
      style={{ height: 27, width: 197 }}
      onChange={this.ProjectChange}
    >
       <option value="0">All</option>
         { this.state.project_data.map((element) => 
        <option value={element.ProjectID + '~' + element.ProjectName}>{element.ProjectName}</option>
        )}
    </select>
  </div>
  <div id="sortable-div" className="drag_drop_main_container">

  <div
      id="pendingdiv"
      className="col-sm-3 col-xs-3 col-md-3 drag_drop_container">
      <div className="drag_contant" id="progress">
        <h3 className="h3_head">Assigned</h3>
        <ul className="sortable-list drag_drop_list ui-sortable">
          <li className="ui-sortable-handle" />
          <li className="sortable-item">
          {
           this.state.AssignedData.map((item) => (
            <div className="drop_top progress_border" id={22861}>
              <h4 style={{ fontSize: 15, fontWeight: 200 }} className="testing">
              {item.ProjectName +'----'+ item.ModuleName}
              </h4>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 150 }}>
                {" "}
                TaskName:{" "}
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                {item.TaskName}
                </span>
              </p>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 150 }}>
                {" "}
                Alloted By:
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                {item.CreatedBy}
                </span>
              </p>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 150 }}>
                {" "}
                Alloted To:{" "}
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                {item.FullName}
                </span>
              </p>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 150 }}>
                {" "}
                Crt Date:{" "}
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                {item.CreatedDate}
                </span>
              </p>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 150 }}>
                {" "}
                Assigned Date :{" "}
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                {item.AllotedDate}
                </span>
              </p>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 150 }}>
                {" "}
                Alloted Hours:{" "}
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                {item.AllotedHrs}
                </span>
              </p>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 150 }}>
                {" "}
                Est-Comp-Date :
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                 {item.Est_CompletedDate}
                </span>
              </p>
            </div>
            ))
          }
          </li>
        </ul>
      </div>
    </div>

    {/* <div
      id="progessdiv"
      className="col-sm-3 col-xs-3 col-md-3 drag_drop_container">
      <div className="drag_contant" id="assigned">
        <h3 className="h3_head">Unassigned</h3>
        <ul className="sortable-list drag_drop_list ui-sortable">
          <li className="ui-sortable-handle" />
          <li className="sortable-item">
            <div className="drop_top progress_border" id={22861}>
              <div style={{display:'flex',justifyContent:'space-between'}}>
                <h4 style={{ fontSize: 15, fontWeight: 200 }} className="testing">
                  Awards----Auston-bugs-0217
                </h4>
                <a href="/unassignedit" style={{backgroundColor: "rgb(76, 74, 72)", color: "rgb(255, 255, 255)", border: "none", padding: "5px 10px", borderRadius: "6px"}}>
                  Edit
                </a>
              </div>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 150 }}>
                {" "}
                TaskName:{" "}
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                  CONFIRM WHICH PORTAL TO USE FOR EACH COMPANY
                </span>
              </p>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 150 }}>
                {" "}
                Alloted By:
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                  Chandler
                </span>
              </p>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 150 }}>
                {" "}
                Alloted To:{" "}
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                  BalaMurugan
                </span>
              </p>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 150 }}>
                {" "}
                Crt Date:{" "}
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                  2/15/2019
                </span>
              </p>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 150 }}>
                {" "}
                Assigned Date :{" "}
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                  2/15/2019
                </span>
              </p>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 150 }}>
                {" "}
                Alloted Hours:{" "}
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                  4
                </span>
              </p>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 150 }}>
                {" "}
                Est-Comp-Date :
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                  3/22/2024
                </span>
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div> */}

  </div>
</div>
<div className='free-space'></div>
        <Footer />
      </div>
    )
  }
}


export default assigned