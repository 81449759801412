import React, { Component } from 'react'
import Header from './header';
import Footer from './footer';
import axios from 'axios';
import moment from 'moment';
import Loader from '../images/loader2.gif';
import project_ic from '../images/project_name_ic.png';
import drop_dwn_ic from '../images/drop_dwn_ic.png';

export class cardView extends Component {
  constructor() {
    super();
    
    this.state = {
      LoginDatas:  JSON.parse(localStorage.getItem("HelpDeskLoginDatas")),
      TodayDate: new Date(),
      project_data : [],
      InProgressData: [],
      AssignedData: [],
      CompletedData: [],
      ProjectName: 'All',
      ProjectId: '0',
      Auth:  JSON.parse(localStorage.getItem("auth")),
      LoaderShow: false,
      selectedDate: moment(new Date()).format("MM/DD/YYYY"),
      selectedDate2: moment(new Date()).format("MM/DD/YYYY"),
      
    };
  }

componentDidMount(){

        axios.post('https://helpdeskservice.desss-portfolio.com/api/LodeProjectBasedEMP/Project?UserType_Id='+this.state.LoginDatas.UserTypeID+'&Emp_id=' + this.state.LoginDatas.EmpID + '&Dept_id=' + this.state.LoginDatas.DeptID)
        .then( project => {
          var data = project.data.data;
          console.log("project" , project.data.data)
          data.map((key) =>{
            this.setState({ project_data: [ ...this.state.project_data, {
              ProjectName: key.ProjectName ,
              ProjectID: key.ProjectID
           }]
         });
         });
        })
        .catch(error => {
            console.log(error);
        }) 
        this.GetDatas('0', 'All');
}

GetDatas(Id, Name){
  var Assigned = [];
  var InProgress = [];
  var Completed = [];
  this.setState({ LoaderShow: true });  
  this.setState({ProjectName: Name});
  this.setState({ProjectId: Id});

  this.setState({ AssignedData: Assigned });
  this.setState({ InProgressData: InProgress });
  this.setState({ CompletedData: Completed });

  var date =  moment(new Date()).format("MM/DD/YYYY");
    axios.get('https://helpdeskservice.desss-portfolio.com/api/Cardview_Assigned/GetCVAssigndList?EmpID=' + this.state.LoginDatas.EmpID + '&user_projectname=' + Name + '&user_projectid=' + Id + '&UserTypeID='+this.state.LoginDatas.UserTypeID+'&DeptID=' + this.state.LoginDatas.DeptID)
    .then(res => {
      var data = res.data.data;
      this.setState({ LoaderShow: false });  
      if(data[0].errorMsg === "No Data Found"){
        return;
      }
      data.forEach((key, value) => {   
          Assigned.push({
            TaskName: key.TaskName, 
            TaskStausID: key.TaskStausID,
            CardviewStatus: key.CardviewStatus,
            CreatedBy: key.CreatedBy,
            FullName: key.FullName,
            CreatedDate: moment(key.CreatedDate).format("MM/DD/YYYY"),
            AllotedDate: moment(key.AllotedDate).format("MM/DD/YYYY"),
            AllotedHrs: key.AllotedHrs,
            Est_CompletedDate:  moment(key.Est_CompletedDate).format("MM/DD/YYYY"),
            PrjTaskID: key.PrjTaskID,
            PrjTaskAllotID: key.PrjTaskAllotID,
            ProjectName: key.ProjectName,
            ModuleName: key.ModuleName,
          });
          
      });
      this.setState({ AssignedData: Assigned });
      console.log('AssignedData', this.state.AssignedData);
      // console.log('Assigned', this.state.Assigned);
    }) 
    .catch(error => {
      this.setState({ LoaderShow: false });  
        console.log(error);
    })

    axios.get('https://helpdeskservice.desss-portfolio.com/api/Cardview_Assigned/GetCVAssigndList?EmpID=' + this.state.LoginDatas.EmpID + '&user_projectname=' + Name + '&user_projectid=' + Id + '&UserTypeID='+this.state.LoginDatas.UserTypeID+'&DeptID=' + this.state.LoginDatas.DeptID)
    .then(res => {
      var data = res.data.data;
      this.setState({ LoaderShow: false });  
      if(data[0].errorMsg === "No Data Found"){
        return;
      }
      data.forEach((key, value) => {   
        InProgress.push({
            TaskName: key.TaskName, 
            TaskStausID: key.TaskStausID,
            CardviewStatus: key.CardviewStatus,
            CreatedBy: key.CreatedBy,
            FullName: key.FullName,
            CreatedDate: moment(key.CreatedDate).format("MM/DD/YYYY"),
            AllotedDate: moment(key.AllotedDate).format("MM/DD/YYYY"),
            AllotedHrs: key.AllotedHrs,
            Est_CompletedDate:  moment(key.Est_CompletedDate).format("MM/DD/YYYY"),
            PrjTaskID: key.PrjTaskID,
            PrjTaskAllotID: key.PrjTaskAllotID,
            ProjectName: key.ProjectName,
            ModuleName: key.ModuleName,
          });
          
      });
      this.setState({ InProgressData: InProgress });
      console.log('InProgressData', this.state.InProgressData);
      // console.log('Assigned', this.state.Assigned);
    }) 
    .catch(error => {
      this.setState({ LoaderShow: false });  
        console.log(error);
    })

    axios.get('https://helpdeskservice.desss-portfolio.com/api/Cardview_Completed/GetCVCompletedList?EmpID=' + this.state.LoginDatas.EmpID + '&user_projectname=' + Name + '&user_projectid=' + Id + '&UserTypeID='+this.state.LoginDatas.UserTypeID+'&DeptID=' + this.state.LoginDatas.DeptID)
    .then(res => {
      var data = res.data.data;
      this.setState({ LoaderShow: false });  
      if(data[0].errorMsg === "No Data Found"){
        return;
      }
      data.forEach((key, value) => {   
        Completed.push({
            TaskName: key.TaskName, 
            TaskStausID: key.TaskStausID,
            CardviewStatus: key.CardviewStatus,
            CreatedBy: key.CreatedBy,
            FullName: key.FullName,
            CreatedDate: moment(key.CreatedDate).format("MM/DD/YYYY"),
            AllotedDate: moment(key.AllotedDate).format("MM/DD/YYYY"),
            AllotedHrs: key.AllotedHrs,
            Est_CompletedDate:  moment(key.Est_CompletedDate).format("MM/DD/YYYY"),
            PrjTaskID: key.PrjTaskID,
            PrjTaskAllotID: key.PrjTaskAllotID,
            ProjectName: key.ProjectName,
            ModuleName: key.ModuleName,
          });
          
      });
      this.setState({ CompletedData: Completed });
      console.log('CompletedData', this.state.CompletedData);
      // console.log('Assigned', this.state.Assigned);
    }) 
    .catch(error => {
      this.setState({ LoaderShow: false });  
        console.log(error);
    })
}

ProjectChange = (e) => {
  // document.getElementById('dateRequired').value = '';
  var data = e.target.value;
  var splitted = data.split("~");
  this.setState({ ProjectId: splitted[0] });
  this.setState({ ProjectName: splitted[1] });
  this.GetDatas(splitted[0], splitted[1]);
  console.log('projectChange', this.state.ProjectId, this.state.ProjectName);
}
  render() {
    return (
      <div>
         {this.state.LoaderShow === true ? (
          <div className='loader-img'>
            <img   src={Loader} height="50" width="50" alt="Helpdesk" />
          </div>
        ) : (
          ''
        )}
        <Header />

          <div class="centerBG">
  <div style={{marginTop: 19, textAlign:'right'}}>
    {/* <span id="Label74">Project : </span>
    <br /> */}
    <div class="seldct_drop_ic">
    <label class="drop_lef_img"><img style={{width: "30px" ,height: "30px",}} src={project_ic} alt="logout" /></label>
    <span class="drop_rit_img"><img style={{width: "25px" ,height: "25px",}} src={drop_dwn_ic} alt="logout" /></span>
    <select class="selector_s"
      name="ctl00$MainContent$txtProjectList_User"
      id="txtProjectList_User"
      style={{width: 210, padding: '10px',}}
      onChange={this.ProjectChange}
    >
       <option value="0">All</option>
         { this.state.project_data.map((element) => 
        <option value={element.ProjectID + '~' + element.ProjectName}>{element.ProjectName}</option>
        )}
    </select>
    </div>
  </div>
  <div id="sortable-div" className="drag_drop_main_container assign_comp_prog_overall">
    <div
      id="progessdiv"
      className=" drag_drop_container">
      <div className="drag_contant" id="assigned">
        <h3 className="h3_head" style={{textAlign: 'left'}}>Assigned</h3>
        <ul className="sortable-list drag_drop_list ui-sortable">
          <li className="ui-sortable-handle" />
          <li className="sortable-item">
          {
           this.state.AssignedData.map((item) => (
            <div className="drop_top progress_border" id={22861}>
              <h4 style={{ fontSize: 15, fontWeight: 200 }} className="testing">
              {item.ProjectName +'----'+ item.ModuleName}
              </h4>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 600 }}>
                {" "}
                TaskName:{" "}
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                {item.TaskName}
                </span>
              </p>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 600 }}>
                {" "}
                Alloted By:
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                {item.CreatedBy}
                </span>
              </p>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 600 }}>
                {" "}
                Alloted To:{" "}
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                {item.FullName}
                </span>
              </p>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 600 }}>
                {" "}
                Crt Date:{" "}
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                {item.CreatedDate}
                </span>
              </p>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 600 }}>
                {" "}
                Assigned Date :{" "}
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                {item.AllotedDate}
                </span>
              </p>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 600 }}>
                {" "}
                Alloted Hours:{" "}
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                {item.AllotedHrs}
                </span>
              </p>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 600 }}>
                {" "}
                Est-Comp-Date :
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                 {item.Est_CompletedDate}
                </span>
              </p>
            </div>
            ))
          }
          </li>
        </ul>
      </div>
    </div>
    <div
      id="pendingdiv"
      className=" drag_drop_container">
      <div className="drag_contant" id="progress">
        <h3 className="h3_head" style={{textAlign: 'left'}}>InProgress</h3>
        <ul className="sortable-list drag_drop_list ui-sortable">
          <li className="ui-sortable-handle" />
          <li className="sortable-item">
          {
           this.state.InProgressData.map((item) => (
            <div className="drop_top progress_border" id={22861}>
              <h4 style={{ fontSize: 15, fontWeight: 200 }} className="testing">
              {item.ProjectName +'----'+ item.ModuleName}
              </h4>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 600 }}>
                {" "}
                TaskName:{" "}
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                {item.TaskName}
                </span>
              </p>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 600 }}>
                {" "}
                Alloted By:
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                {item.CreatedBy}
                </span>
              </p>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 600 }}>
                {" "}
                Alloted To:{" "}
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                {item.FullName}
                </span>
              </p>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 600 }}>
                {" "}
                Crt Date:{" "}
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                {item.CreatedDate}
                </span>
              </p>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 600 }}>
                {" "}
                Assigned Date :{" "}
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                {item.AllotedDate}
                </span>
              </p>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 600 }}>
                {" "}
                Alloted Hours:{" "}
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                {item.AllotedHrs}
                </span>
              </p>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 600 }}>
                {" "}
                Est-Comp-Date :
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                 {item.Est_CompletedDate}
                </span>
              </p>
            </div>
            ))
          }
          </li>
        </ul>
      </div>
    </div>
    <div
      id="completeddiv"
      className=" drag_drop_container"
    >
      <div className="drag_contant" id="completed">
        <h3 className="h3_head" style={{textAlign: 'left'}}>Completed</h3>
        <ul className="sortable-list drag_drop_list ui-sortable">
          <li className="ui-sortable-handle" />
          <li className="sortable-item">
          {
           this.state.CompletedData.map((item) => (
            <div className="drop_top progress_border" id={22861}>
              <h4 style={{ fontSize: 15, fontWeight: 200 }} className="testing">
              {item.ProjectName +'----'+ item.ModuleName}
              </h4>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 600 }}>
                {" "}
                TaskName:{" "}
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                {item.TaskName}
                </span>
              </p>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 600 }}>
                {" "}
                Alloted By:
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                {item.CreatedBy}
                </span>
              </p>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 600 }}>
                {" "}
                Alloted To:{" "}
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                {item.FullName}
                </span>
              </p>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 600 }}>
                {" "}
                Crt Date:{" "}
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                {item.CreatedDate}
                </span>
              </p>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 600 }}>
                {" "}
                Assigned Date :{" "}
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                {item.AllotedDate}
                </span>
              </p>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 600 }}>
                {" "}
                Alloted Hours:{" "}
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                {item.AllotedHrs}
                </span>
              </p>
              <p style={{ color: "#cc0000", fontSize: 15, fontWeight: 600 }}>
                {" "}
                Est-Comp-Date :
                <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
                 {item.Est_CompletedDate}
                </span>
              </p>
            </div>
            ))
          }
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div className='free-space'></div>
        <Footer />
      </div>
    )
  }
}

export default cardView