import axios from 'axios';
import React, { useEffect, useState } from 'react';

const SprintMyForm = ({ projectNameList, boardNameList, boardList, setEmptyBoadList, getNewOpen ,ProjectID}) => {
  const [projects] = useState(projectNameList);
  const [projectId, setProjectId] = useState(ProjectID);
  const [boardId, setBoardId] = useState('');
  const[projectName,setProjectName]=useState("");
  // const[boardNameList,setBoardNameList]=useState(boardNameList);

  const [formData, setFormData] = useState({
    projectName: '',
    sprintName: '',
    board: '',
    sprintStartDate: '',
    sprintEndDate: '',
    sprintDeliveryDate: '',
    sprintEstimatedHours: '',
    sprintCompleteHours: '',
    comments: '',
  });

  const [formErrors, setFormErrors] = useState({
    projectName: false,
    sprintName: false,
    board: false,
    sprintStartDate: false,
    sprintEndDate: false,
    sprintDeliveryDate: false,
    sprintEstimatedHours: false,
    sprintCompleteHours: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

    // Validate the field and update formErrors
    setFormErrors({
      ...formErrors,
      [name]: value.trim() === ''
    });
  };

  // const handleProjectChange = (e) => {
  //   const { value } = e.target;
  //   const project = projects.find((project) => {
  //     return project.ProjectName.toLowerCase() === value.toLowerCase();
  //   });

  //   if (project) {
  //     boardList(project.ProjectID);
  //     setProjectId(project.ProjectID);
  //   } else {
  //     console.log("Project not found:", value);
  //     setEmptyBoadList();
  //   }

  //   setFormData({
  //     ...formData,
  //     projectName: value
  //   });
  // };

  const handleBoardChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      board: value
    });

    const boardid = boardNameList.find((boardlist) => {
      return boardlist.BoardName.toLowerCase() === value.toLowerCase();
    });

    if (boardid) {
      setBoardId(boardid.BoardID);
    } else {
      console.log("BoardId not found:", value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { projectName, sprintName, board, sprintStartDate, sprintEndDate, sprintDeliveryDate, sprintEstimatedHours, sprintCompleteHours, comments } = formData;

    // Check for empty fields and set formErrors accordingly
    const errors = {
      // projectName: projectName.trim() === '',
      sprintName: sprintName.trim() === '',
      board: board.trim() === '',
      sprintStartDate: sprintStartDate.trim() === '',
      sprintEndDate: sprintEndDate.trim() === '',
      sprintDeliveryDate: sprintDeliveryDate.trim() === '',
      sprintEstimatedHours: sprintEstimatedHours.trim() === '',
      sprintCompleteHours: sprintCompleteHours.trim() === ''
    };

    setFormErrors(errors);

    // If any required field is empty, prevent form submission
    if (Object.values(errors).some(error => error)) {
      return;
    }

    // Additional validation for Sprint Estimated Hours and Sprint Completed Hours
    if (isNaN(sprintEstimatedHours) || isNaN(sprintCompleteHours)) {
      alert('Please enter valid numbers for Sprint Estimated Hours and Sprint Completed Hours.');
      return;
    }

    // If all fields are filled and numbers are valid, proceed with form submission
    const url = 'https://helpdeskservice.desss-portfolio.com/api/InsertSprintData/InsertData?ProjectID=' + projectId + '&BoardID=' + boardId + '&SprintName=' + sprintName + '&StartDate=' + sprintStartDate + '&DeliveryDate=' + sprintDeliveryDate + '&EstimatedHours=' + sprintEstimatedHours + '&CompletedHours=' + sprintCompleteHours + '&Comments=' + comments + '&EndDate=' + sprintEndDate;
    axios.post(url)
      .then(response => {
        console.log(response.data.data)
        getNewOpen();
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
      })
  };

  const filteredProjects = projects.filter(project =>
    project.ProjectName.toLowerCase().includes(formData.projectName.toLowerCase())
  );
  useEffect(()=>{
    console.log("BOO"+boardNameList)
    // console.log("prolist"+projectNameList)
//     projectNameList.map((list) => {
                     
//       if (projectNameList.ProjectID == 2842) {
//         console.log("ganesh"+list.projectName)
//       }else{
//         console.log("Noooo")
//       }

//   })

for (let i = 0; i < projectNameList.length; i++) {
  if (projectNameList[i].ProjectID == 2842) {
    console.log("ganesh " + projectNameList[i].ProjectName);
    setProjectName (projectNameList[i].ProjectName);
    break;  // Exit the loop once the project is found
  }
}
})

  return (
    <form onSubmit={handleSubmit} style={{borderTop: "1px solid rgb(117, 117, 117)", paddingTop: "10px"}}>
       <div className='sprint-popup'>
                    <label>Project Name </label>
                    

                    <span style={{border:'1px solid #ccc',width:'275px', display:'inline-block',padding:'3px'}} >{projectName}</span>;
                       
                  
                   </div>
      {/* <div className='sprint-popup'>
        <label htmlFor="projectName">Project Name:</label>
        <input
          type="text"
          name="projectName"
          value={formData.projectName}
          onChange={handleProjectChange}
          autoComplete="off"
          list="projectList"
          className={formErrors.projectName ? 'error' : ''}
        />
        {formErrors.projectName && <span className="error-message">Project Name is required</span>}
        <datalist id="projectList">
          {filteredProjects.map((project, index) => (
            <option key={project.ProjectID} value={project.ProjectName} />
          ))}
        </datalist>
      </div> */}
      <div className='sprint-popup'>
        <label htmlFor="sprintName">Sprint Name:</label>
        <input
          type="text"
          id="sprintName"
          name="sprintName"
          value={formData.sprintName}
          onChange={handleChange}
          className={formErrors.sprintName ? 'error' : ''}
        />
        {formErrors.sprintName && <span className="error-message">Sprint Name is required</span>}
      </div>
      <div className='sprint-popup'>
        <label htmlFor="board">Board:</label>
        <select
          id="board"
          name="board"
          value={formData.board}
          onChange={handleBoardChange}
          className={formErrors.board ? 'error' : ''}
        >
          <option>--Select--</option>
          {boardNameList.map((board, index) => (
            <option key={board.BoardId} value={board.BoardName}>{board.BoardName}</option>
          ))}
        </select>
        {formErrors.board && <span className="error-message">Board is required</span>}
      </div>
      <div className='sprint-popup'>
        <label htmlFor="sprintStartDate">Sprint Start Date:</label>
        <input
          type="date"
          id="sprintStartDate"
          name="sprintStartDate"
          value={formData.sprintStartDate}
          onChange={handleChange}
          className={formErrors.sprintStartDate ? 'error' : ''}
        />
        {formErrors.sprintStartDate && <span className="error-message">Sprint Start Date is required</span>}
      </div>
      <div className='sprint-popup'>
        <label htmlFor="sprintEndDate">Sprint End Date:</label>
        <input
          type="date"
          id="sprintEndDate"
          name="sprintEndDate"
          value={formData.sprintEndDate}
          onChange={handleChange}
          className={formErrors.sprintEndDate ? 'error' : ''}
        />
        {formErrors.sprintEndDate && <span className="error-message">Sprint End Date is required</span>}
      </div>
      <div className='sprint-popup'>
        <label htmlFor="sprintDeliveryDate">Sprint Delivery Date:</label>
        <input
          type="date"
          id="sprintDeliveryDate"
          name="sprintDeliveryDate"
          value={formData.sprintDeliveryDate}
          onChange={handleChange}
          className={formErrors.sprintDeliveryDate ? 'error' : ''}
        />
        {formErrors.sprintDeliveryDate && <span className="error-message">Sprint Delivery Date is required</span>}
      </div>
      <div className='sprint-popup'>
        <label htmlFor="sprintEstimatedHours">Sprint Estimated Hours:</label>
        <input
          type="text"
          id="sprintEstimatedHours"
          name="sprintEstimatedHours"
          value={formData.sprintEstimatedHours}
          onChange={handleChange}
          className={formErrors.sprintEstimatedHours ? 'error' : ''}
        />
        {formErrors.sprintEstimatedHours && <span className="error-message">Sprint Estimated Hours is required</span>}
      </div>
      <div className='sprint-popup'>
        <label htmlFor="sprintCompleteHours">Sprint Complete Hours:</label>
        <input
          type="text"
          id="sprintCompleteHours"
          name="sprintCompleteHours"
          value={formData.sprintCompleteHours}
          onChange={handleChange}
          className={formErrors.sprintCompleteHours ? 'error' : ''}
        />
        {formErrors.sprintCompleteHours && <span className="error-message">Sprint Completed Hours is required</span>}
      </div>
      <div className='sprint-popup'>
        <label htmlFor="comments">Comments:</label>
        <input
          id="comments"
          name="comments"
          value={formData.comments}
          onChange={handleChange}
        />
      </div>
      <div class="popup-update" style={{width: "100%", textAlign: "center", marginTop: "15px"}}>
        <button type="submit">Submit</button>
      </div>
    </form>
  );
};

export default SprintMyForm;
