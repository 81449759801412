import React, { Component } from 'react';
import Header from './header'
import axios from 'axios';
import moment from 'moment';
// import DatePicker from "react-datepicker";
import dep_ment_ic from '../images/department_ic.png';
import proj_nam from '../images/project_name_ic.png';
import tsk_cat_ic from '../images/task_cat_ic.png';
import tsktype_ic from '../images/task_type_ic.png'; 
import sprint_ic from '../images/sprint_ic.png'; 
import project_ic from '../images/project_name_ic.png';
import clos_ic from '../images/close_ic.png';
import issue_nam_ic from '../images/issue_nam_ic.png'; 
import allot_hrs_ic from '../images/allot_hrs_ic.png';
import tsk_status_ic from '../images/tsk_status_ic.png';  
import assign_to_ic from '../images/assign_to_ic.png';
import assing_by_ic from '../images/assign_by_ic.png';
import board_ic from '../images/board_ic.png';
import module_ic from '../images/module_ic.png';
import no_tsk_ic from '../images/notaskicon.png';
import no_tsk_img from '../images/no_task.png';
import drop_dwn_ic from '../images/drop_dwn_ic.png';
import tim_sht from '../images/timesheet.png';
import tday_task from '../images/today_task.png';
import Footer from '../Home/footer';
import Loader from '../images/loader2.gif';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import '../css list/home.css';
import Swal from 'sweetalert2';


const AddTaskLogsValidation = Yup.object({

    IssueName: Yup.string()
    .required('Issue Name is Required'),
    IssueType: Yup.string()
    .required('TaskType is Required'),
    Module_Epic: Yup.string()
    .required('Module is Required'),
    // WorkFlow: Yup.string()
    // .required('TaskCategory is Required'),
    // Priority: Yup.string()
    // .required('Priority is Required'),
    StartDate: Yup.string()
    .required('Date is Required'),
    AssignedBy: Yup.string()
    .required('Assigned By is Required'),
    AssignedTo: Yup.string()
    .required('Assigned To is Required'),
    Taskstatus: Yup.string()
    .required('Task Status is Required'),
    AllotedHrs: Yup.string()
    .required('Alloted Hours is Required'),
  });

export class TaskAllotmentList extends Component {
    constructor() {
    super();
    
    this.state = {
      LoginDatas:  JSON.parse(localStorage.getItem("HelpDeskLoginDatas")),
      TodayDate: new Date(),
      Test : [] ,
      InProgressData: [],
      Assigned: [],
      project_data : [],
      DepartmentData : [],
      ProjectName: 'All',
      ProjectId: '0',
      Auth:  JSON.parse(localStorage.getItem("auth")),
      LoaderShow: false,
      selectedDate: moment(new Date()).format("MM/DD/YYYY"),
      selectedDate2: moment(new Date()).format("MM/DD/YYYY"),
      SelectedDepartment: '',
      EmployeesLessHourTaskData: [],
      EmployeesTaskData: [],
      EmployeesNoTaskData: [],
      LeaveData: [],
      showtab1: false,
      showtab2: true,
      AddShowPopup: false,
      BoardDatas: [],
      SprintDatas: [],
      Module_Epic_Datas: [],
      WorkFlowDatas: [],
      IssueTypeDatas: [],
      ReportedByData: [],
      AllotedToDatas: [],
      TaskstatusDatas: [],
      ProjectID: '',
      TaskCategoryID: '',
    };
  }

componentDidMount(){

    //  this.IssueTypeDataMethod();
     this.WorkFlowDataMethod();
     this.ReportedByDataMethod();
     this.TaskstatusDatasMethod();

      const Auth =  JSON.parse(localStorage.getItem("auth"));
    if(!Auth){
     this.props.history.push('/login');
    }
        // this.GetDatas('0', 'All');
        this.TodayTaskTabDatasMethod();
        axios.post('https://helpdeskservice.desss-portfolio.com/api/LodeProjectBasedEMP/Project?UserType_Id='+this.state.LoginDatas.UserTypeID+'&Emp_id=' + this.state.LoginDatas.EmpID + '&Dept_id=' + this.state.LoginDatas.DeptID)
        .then( project => {
          var data = project.data.data;
          console.log("project" , project.data.data)
          data.map((key) =>{
            this.setState({ project_data: [ ...this.state.project_data, {
              ProjectName: key.ProjectName ,
              ProjectID: key.ProjectID
           }]
         });
         });
        })
        .catch(error => {
            console.log(error);
        }) 

         axios.get('https://helpdeskservice.desss-portfolio.com/api/DeptDropdown/LoadDeptDropdown')
        .then( res => {
          var data1 = res.data.data;
          console.log("deparmen" , res.data.data)
          data1.map((key) =>{
            this.setState({ DepartmentData: [ ...this.state.DepartmentData, {
              Department: key.Department ,
              DeptID: key.DeptID
           }]
         });
         });
         console.log("DepartmentData" , this.state.DepartmentData)
        })
        .catch(error => {
            console.log(error);
        })    
 }

GetDatas(Id, Name){
  var Inprogressdata1 = [];
  var Assigned1 = [];
  this.setState({ LoaderShow: true });  
  this.setState({ProjectName: Name});
  this.setState({ProjectId: Id});
  this.setState({ InProgressData: Inprogressdata1 });
  this.setState({ Assigned: Assigned1 });
  var date =  moment(new Date()).format("MM/DD/YYYY");
    axios.get('https://helpdeskservice.desss-portfolio.com/api/ClientProfile/GetProject_task?ProjectName=' + Name + '&ProjectId=' + Id + '&Emp_Id=' + this.state.LoginDatas.EmpID + '&usertype_id=' + this.state.LoginDatas.UserTypeID + '&dept_id=&date=' + date)
    .then(Issues => {
      this.setState({ Test: Issues.data.data });
      var data = Issues.data.data;
      this.setState({ LoaderShow: false });  
      data.forEach((key, value) => {
        
        if(key.CardviewStatus === 'InProgress'){
          console.log('InProgress');
          Inprogressdata1.push({
            TaskName: key.TaskName, 
            CreatedBy: key.CreatedBy,
            FullName: key.FullName,
            CreatedDate: moment(key.CreatedDate).format("MM/DD/YYYY"),
            AllotedDate: moment(key.AllotedDate).format("MM/DD/YYYY"),
            AllotedHrs: key.AllotedHrs,
            Est_CompletedDate:  moment(key.Est_CompletedDate).format("MM/DD/YYYY"),
            PrjTaskID: key.PrjTaskID,
            ProjectName: key.ProjectName,
            ModuleName: key.ModuleName,
          });
       }else if(key.CardviewStatus === 'Assigned'){
          console.log('Assigned');
          Assigned1.push({
            TaskName: key.TaskName, 
            CreatedBy: key.CreatedBy,
            FullName: key.FullName,
            // CreatedDate: key.CreatedDate,
            CreatedDate:  moment(key.CreatedDate).format("MM/DD/YYYY"),
            AllotedDate: moment(key.AllotedDate).format("MM/DD/YYYY"),
            AllotedHrs: key.AllotedHrs,
            Est_CompletedDate: moment(key.Est_CompletedDate).format("MM/DD/YYYY"),
            PrjTaskID: key.PrjTaskID,
            ProjectName: key.ProjectName,
            ModuleName: key.ModuleName,
          });
       }
      })
      this.setState({ InProgressData: Inprogressdata1 });
      this.setState({ Assigned: Assigned1 });
      // console.log('InProgress', this.state.InProgressData);
      // console.log('Assigned', this.state.Assigned);
    }) 
    .catch(error => {
      this.setState({ LoaderShow: false });  
        console.log(error);
    })
}

handletab1 = () => {
  this.GetDatas('0', 'All');
  this.setState({ showtab1: true });
  this.setState({ showtab2: false });
const tab1 = document.getElementById('first-tab');
const tab2 = document.getElementById('second-tab');
    tab1.classList.add('active');
    tab2.classList.remove('active');

};
handletab2 = () => {
  this.setState({ showtab1: false });
  this.setState({ showtab2: true });
  this.TodayTaskTabDatasMethod();
const tab1 = document.getElementById('first-tab');
const tab2 = document.getElementById('second-tab');
    tab1.classList.remove('active');
    tab2.classList.add('active');

};

TodayTaskTabDatasMethod(){
  console.log('SelectedDate', this.state.selectedDate2);
  // alert('hi');
  var NoTask = [];
  var LessHour = [];
  var Taskhave = [];
  var leave = [];
  this.setState({ EmployeesNoTaskData: NoTask });
  this.setState({ EmployeesLessHourTaskData: LessHour });
  this.setState({ EmployeesTaskData: Taskhave });
  this.setState({ LeaveData: leave });
  this.setState({ LoaderShow: true });  
  var date =  moment(new Date()).format("MM/DD/YYYY");

    axios.get('https://helpdeskservice.desss-portfolio.com/api/TodaysTask/LoadNotTaskAlloted?selecteddate='+this.state.selectedDate2+'&DeptID='+this.state.SelectedDepartment)
    .then(res => {
      var data1 = res.data.data;
      this.setState({ LoaderShow: false });  
      data1.forEach((key, value) => {
        if(key.PrjTaskID === 0 && key.ProjectID === 0){
          NoTask.push({
            FullName: key.FullName,
          });
        }
        if(key.PrjTaskID === 0 && key.ProjectID === 1){
          leave.push({
            FullName: key.FullName,
          });
        }
      })
      this.setState({ EmployeesNoTaskData: NoTask });
      this.setState({ LeaveData: leave });
      console.log('Datas', this.state.EmployeesNoTaskData);
    }) 
    .catch(error => {
      this.setState({ LoaderShow: false });  
        console.log(error);
    })

    axios.get('https://helpdeskservice.desss-portfolio.com/api/TodaysTask/LoadTaskLessHour?selecteddate='+this.state.selectedDate2+'&DeptID='+this.state.SelectedDepartment + '&projectId=')
    .then(res2 => {
      var data2 = res2.data.data;
      this.setState({ LoaderShow: false });  
      data2.forEach((key, value) => {
        if(data2[0].errorMsg === "No Data Found"){
          return;
        }else{
          LessHour.push({
            FullName: key.FullName,
            TaskName: key.TaskName,
            AllotedHrs: key.AllotedHrs,
            ProjectName: key.ProjectName,
            ModuleName: key.ModuleName,
            TaskStatus: key.TaskStatus,
            TaskDate: moment(key.TaskDate).format("MM/DD/YYYY"),
            DeptID: key.DeptID,
            EmpID: key.EmpID,
          });
        }
      })
      this.setState({ EmployeesLessHourTaskData: LessHour });
      console.log('Datas2', this.state.EmployeesLessHourTaskData);
    }) 
    .catch(error => {
      this.setState({ LoaderShow: false });  
        console.log(error);
    })

    axios.get('https://helpdeskservice.desss-portfolio.com/api/TodaysTask/LoadTaskList?TaskDate='+this.state.selectedDate2+'&DeptID='+this.state.SelectedDepartment + '&projectId=')
    .then(res3 => {
      var data3 = res3.data.data;
      this.setState({ LoaderShow: false });  
      if(data3[0].errorMsg === "No Data Found"){
        return;
      }else{
      data3.forEach((key, value) => {
          Taskhave.push({
            FullName: key.FullName,
            TaskName: key.TaskName,
            AllotedHrs: key.AllotedHrs,
            ProjectName: key.ProjectName,
            ModuleName: key.ModuleName,
            TaskStatus: key.TaskStatus,
            TaskDate: moment(key.TaskDate).format("MM/DD/YYYY"),
            DeptID: key.DeptID,
            EmpID: key.EmpID,
          });
      })
    }
      this.setState({ EmployeesTaskData: Taskhave });
      console.log('Datas2', this.state.EmployeesTaskData);
    }) 
    .catch(error => {
      this.setState({ LoaderShow: false });  
        console.log(error);
    })
}

AddShowPopupMethod(){
  this.setState({ AddShowPopup: true });
}
ProjectChange = (e) => {
  document.getElementById('dateRequired').value = '';
  this.setState({ selectedDate: '' });
  var data = e.target.value;
  var splitted = data.split("~");
  this.setState({ ProjectId: splitted[0] });
  this.setState({ ProjectName: splitted[1] });
  this.GetDatas(splitted[0], splitted[1]);
  console.log('projectChange', this.state.ProjectId, this.state.ProjectName);
}

DepartmentChange = (e) => {
  this.setState({ SelectedDepartment: e.target.value });
}

TimeSheetMethod(res, datas){
  localStorage.setItem('PrjTaskID', datas.PrjTaskID);
  localStorage.setItem('TaskName', datas.TaskName);
  this.props.history.push('/timesheet');
}

AddPopupClose = () => {
  this.setState({ AddShowPopup: false });
};

DateChange = (e) => {
  console.log('Date Change', e.target.value);
  var date =  moment(e.target.value).format("MM/DD/YYYY");
  this.setState({ selectedDate: moment(e.target.value).format("MM/DD/YYYY") });
  console.log('Date Change date', date);
  if(date === 'Invalid date'){
    this.GetDatas(this.state.ProjectId, this.state.ProjectName);
  }
  var Inprogressdata1 = [];
  var Assigned1 = [];
  this.setState({ InProgressData: Inprogressdata1 });
  this.setState({ Assigned: Assigned1 });
  this.setState({ LoaderShow: true });  
    axios.get('https://helpdeskservice.desss-portfolio.com/api/ClientProfile/GetProject_task?ProjectName=' + this.state.ProjectName + '&ProjectId=' + this.state.ProjectId + '&Emp_Id=' + this.state.LoginDatas.EmpID + '&usertype_id=' + this.state.LoginDatas.UserTypeID + '&dept_id=&date=' + date)
    .then(Issues => {
      this.setState({ Test: Issues.data.data });
      var data = Issues.data.data;
      data.forEach((key, value) => {
        this.setState({ LoaderShow: false });  
        if(key.CardviewStatus === 'InProgress'){
          console.log('InProgress');
          Inprogressdata1.push({
            TaskName: key.TaskName, 
            CreatedBy: key.CreatedBy,
            FullName: key.FullName,
            CreatedDate: moment(key.CreatedDate).format("MM/DD/YYYY"),
            AllotedDate: moment(key.AllotedDate).format("MM/DD/YYYY"),
            AllotedHrs: key.AllotedHrs,
            Est_CompletedDate:  moment(key.Est_CompletedDate).format("MM/DD/YYYY"),
            PrjTaskID: key.PrjTaskID,
            ProjectName: key.ProjectName,
            ModuleName: key.ModuleName,
          });
       }else if(key.CardviewStatus === 'Assigned'){
          console.log('Assigned');
          Assigned1.push({
            TaskName: key.TaskName, 
            CreatedBy: key.CreatedBy,
            FullName: key.FullName,
            // CreatedDate: key.CreatedDate,
            CreatedDate:  moment(key.CreatedDate).format("MM/DD/YYYY"),
            AllotedDate: moment(key.AllotedDate).format("MM/DD/YYYY"),
            AllotedHrs: key.AllotedHrs,
            Est_CompletedDate: moment(key.Est_CompletedDate).format("MM/DD/YYYY"),
            PrjTaskID: key.PrjTaskID,
            ProjectName: key.ProjectName,
            ModuleName: key.ModuleName,
          });
       }
      })
      this.setState({ InProgressData: Inprogressdata1 });
      this.setState({ Assigned: Assigned1 });
      // console.log('InProgress', this.state.InProgressData);
      // console.log('Assigned', this.state.Assigned);
    }) 
    .catch(error => {
      this.setState({ LoaderShow: false });  
        console.log(error);
    })

}
DateChange2 = (e) => {
  console.log('Date Change2', e.target.value);
  var date =  moment(e.target.value).format("MM/DD/YYYY");
  this.setState({ selectedDate2: moment(e.target.value).format("MM/DD/YYYY") });
  console.log('Date Change date2', this.state.selectedDate2);
}
BoardDatasMethod = (e) =>{
  console.log('Board Datas', e.target.value);
  // this.setFieldValue("Projectname", e.target.value);
  this.setState({ BoardDatas: [] });
  this.setState({ ProjectID: e.target.value });
  var URL = 'https://helpdeskservice.desss-portfolio.com/api/Board/LoadBoard?ProjectID=' + e.target.value;
  axios.post(URL)
  .then(res => {
    console.log('Board Datas', res.data.data);
    this.setState({ BoardDatas: res.data.data });
 })
  .catch(error => {
  console.log(error);
 })


 this.setState({ SprintDatas: [] });
 var URL1 = 'https://helpdeskservice.desss-portfolio.com/api/GridSprint/LoadBoard?ProjectID=' + e.target.value;
 axios.post(URL1)
 .then(res => {
   console.log('SprintDatas', res.data.data);
    if(res.data.data[0].errorMsg === "No Data Found"){
      return;
    }else{
   this.setState({ SprintDatas: res.data.data });
  }

})
 .catch(error => {
 console.log(error);
});

var module = 'https://helpdeskservice.desss-portfolio.com/api/ModuleList/LoadGrid?CompanyID='+this.state.LoginDatas.CompanyID+'&ProjectID='+e.target.value;
axios.post(module)
.then(res => {
this.setState({Module_Epic_Datas: res.data.data});
console.log('Module_Epic_Datas',  this.state.Module_Epic_Datas);
})
.catch(error => {
console.log(error);
})

this.setState({ AllotedToDatas: [] });
var Alloted = 'https://helpdeskservice.desss-portfolio.com/api/AllotedToDropdown/AllotedTO?CompanyID='+this.state.LoginDatas.CompanyID+'&ProjectID=' + e.target.value;
axios.post(Alloted)
.then(res1 => {
this.setState({AllotedToDatas: res1.data.data});
console.log('AllotedTo',  this.state.AllotedToDatas);
})
.catch(error => {
console.log(error);
})

}

IssueTypeDataMethod = (e) => {
  console.log('Board Datas', e.target.value);
  // this.setFieldValue("Projectname", e.target.value);
  this.setState({ IssueTypeDatas: [] });
  this.setState({ TaskCategoryID: e.target.value });
  var issue = 'https://helpdeskservice.desss-portfolio.com/api/TaskDropdown/loadTask?TaskCategory=' +  e.target.value;
  axios.post(issue)
  .then(res => {
  this.setState({IssueTypeDatas: res.data.data});
  console.log('IssueTypeDatas',  this.state.IssueTypeDatas);
  })
  .catch(error => {
  console.log(error);
  })
}

Module_Epic_DataMethod(){
var module = 'https://helpdeskservice.desss-portfolio.com/api/ModuleByCompany/LoadModule?CompanyID=' + this.state.LoginDatas.CompanyID;
axios.post(module)
.then(res => {
this.setState({Module_Epic_Datas: res.data.data});
console.log('Module_Epic_Datas',  this.state.Module_Epic_Datas);
})
.catch(error => {
console.log(error);
})
}

WorkFlowDataMethod(){
var workflow = 'https://helpdeskservice.desss-portfolio.com/api/SubModTaskCat/GetTaskCategory?CompanyID=' + this.state.LoginDatas.CompanyID;
axios.get(workflow)
.then(res => {
this.setState({WorkFlowDatas: res.data.data});
console.log('WorkFlowDatas',  this.state.WorkFlowDatas);
})
.catch(error => {
console.log(error);
})
}

TaskstatusDatasMethod(){
  var URL = 'https://helpdeskservice.desss-portfolio.com/api/TaskStatus/LodeTaskStatus';
  axios.post(URL)
  .then(res => {
    console.log('TaskstatusDatas', res.data.data);
    this.setState({ TaskstatusDatas: res.data.data });
 })
  .catch(error => {
  console.log(error);
 })
}

ReportedByDataMethod(){
var URL = 'https://helpdeskservice.desss-portfolio.com/api/AssignedBy/loadall?CompanyID=' + this.state.LoginDatas.CompanyID;
axios.post(URL)
.then(res => {
this.setState({ReportedByData: res.data.data});
console.log('ReportedByData',  this.state.ReportedByData);
})
.catch(error => {
console.log(error);
})

}  
  render() {
    return (
      <div>
       {this.state.LoaderShow === true ? (
          <div className='loader-img'>
            <img   src={Loader} height="50" width="50" alt="Helpdesk" />
          </div>
        ) : (
          ''
        )}

    <Header />

        <div className="centerBG" style={{width: '90%'}}>
            <table width="100%" style={{paddingleft:  '0', paddingright: '0'}}>
               <tbody>
                   <tr style={{borderBottom: "none"}}>
                       <td>
                        <table>
                            <tbody>
                                {/* <tr className='dashboard'>
                                    <td>
                                        Dashboard
                                    </td>
                                    <td>
                                        {this.state.LoginDatas.EmpName + '-' + this.state.LoginDatas.IDNumber}
                                    </td>
                                </tr> */}
                            </tbody>
                        </table>
                       </td>
                   </tr>
                    <tr>
                        <td>
                            <table>
                                <tbody>
                                    {/* <tr className='task-list'>
                                        <td>
                                            Reference
                                        </td>
                                        <td>
                                            Task Allotment vs TimeSheet
                                        </td>
                                        <td>
                                            Leaves
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table>
                       </td>
                    </tr>
                    {/* <tr className='dashboard'>
                        <td style={{fontSize: '14px'}}>
                            Issues
                        </td>
                    </tr> */}
                    <tr>
                      <td class="table_bg_colr timesheet">
                        <div className="radio-tabs">

                        {/* <div className="tabs" style={{marginBottom:"0px"}}>

                          <label htmlFor="radio2" id="second-tab" className="tab active">
                            <div style={{paddingLeft: "0px"}} className="tab-label taskic_tit" onClick={ () => {this.handletab2();}}><img style={{width: "33px" ,height: "33px",}} src={tday_task} alt="logout" />Today's Task</div>
                          </label>

                          <label htmlFor="radio1" id="first-tab" className="tab">
                            <div className="tab-label taskic_tit" onClick={ () => {this.handletab1();}}><img style={{width: "33px" ,height: "33px"}} src={tim_sht} alt="logout" />TimeSheet</div>
                          </label>
                          
                        </div> */}

                        {this.state.showtab1 && (
                        <div id="first-panel" className="panel active animated slideInRight">
                          <div className="tab-1">
                                  <table>
                                      <tbody>
                                          <tr>
                                              <td>
                                                  <div id="leadTab_C0">
                                                      <div style={{display: "flex",gap: 15,justifyContent : 'right',position: "absolute",top: "-100px",right: "0px"}}>
                                                          {/* <span id="Label74">Project : </span> */}
                                                          <div class="seldct_drop_ic">
                                                          <label class="drop_lef_img"><img style={{width: "30px" ,height: "30px",}} src={project_ic} alt="logout" /></label>
                                                          <span class="drop_rit_img"><img style={{width: "25px" ,height: "25px",}} src={drop_dwn_ic} alt="logout" /></span>
                                                          <select name="system_ton" id="ddlTaskStatus" class="select_new MB10 fleft selector_s" onChange={this.ProjectChange} style={{marginleft:"10px", width:"230px", padding: "10px"}}>
                                                              <option value="0">Project</option>
                                                                { this.state.project_data.map((element) => 
                                                                  <option value={element.ProjectID + '~' + element.ProjectName}>{element.ProjectName}</option>
                                                                )}
                                                          </select>
                                                          </div>
                                                            <div style={{display: "flex"}}>
                                                            {/* <p style={{fontSize: "17px",margin: "0px"}} >Date :</p> */}
                                                            {/* <input id="dateRequired" type="text" value={this.state.selectedDate} readOnly/> */}
                                                            <input id="dateRequired" class="dat_bx_icon" type="date" name="editmoduleFinaldate1" onChange={this.DateChange} style={{width: '130px'}}/>
                                                            {/* <input id="dateRequired" type="date" name="dateRequired" value={this.state.selectedDate} onChange={this.DateChange}/> */}
                                                            {/* <input type="date" id="date-input" value={this.state.selectedDate} onChange={this.DateChange} /> */}
                                                          {/* <DatePicker id="datepicker" 
                                                           selected={this.state.TodayDate}
                                                          //  onChange={this.DateChange}
                                                           dateFormat="MM/dd/yyyy"
                                                           /> */}
                                                           </div>
                                                           
                                                      </div>
                                                    <div id="sortable-div" className="drag_drop_main_container tim_shet_lis_prog_inprog">
                                                      <div id="progessdiv" className="col-sm-6 col-xs-12 col-md-6 drag_drop_container"> 
                                                        <div className="drag_contant" id="assigned">
                                                          <h3 className="h3_head">Assigned</h3>
                                                          <ul className="sortable-list drag_drop_list">
                                                            <li className="sortable-item">
                                                              {
                                                                this.state.Assigned.map((item) => (
                                                                  <div className="drop_top progress_border" id="41956">
                                                                                  <h4 style={{fontSize: 15, fontWeight: 200 , cursor: 'pointer'}} className="testing" onClick={() => { this.TimeSheetMethod(this, item);}}>{item.ProjectName +'----'+ item.ModuleName}</h4>
                                                                                  <p style={{color: '#cc0000', fontSize: 15, fontWeight: 600}}> 
                                                                                    TaskName:
                                                                                    <span style={{color: 'black', fontSize: 15, fontWeight: 100}}>{item.TaskName}</span>
                                                                                  </p>
                                                                                  <p style={{color: '#cc0000', fontSize: 15, fontWeight: 600}}>
                                                                                    Alloted By:
                                                                                    <span style={{color: 'black', fontSize: 15, fontWeight: 100}}>{item.CreatedBy}</span>
                                                                                  </p>
                                                                                  <p style={{color: '#cc0000', fontSize: 15, fontWeight: 600}}> 
                                                                                    Alloted To:  
                                                                                    <span style={{color: 'black', fontSize: 15, fontWeight: 100}}>{item.FullName}</span>
                                                                                  </p>
                                                                                  {/* <p style={{color: '#cc0000', fontSize: 15, fontWeight: 150}}> 
                                                                                    Crt Date:  
                                                                                    <span style={{color: 'black', fontSize: 15, fontWeight: 100}}>{item.CreatedDate}</span>
                                                                                  </p> */}
                                                                                  <p style={{color: '#cc0000', fontSize: 15, fontWeight: 600}}>  
                                                                                    Assigned Date : 
                                                                                    <span style={{color: 'black', fontSize: 15, fontWeight: 100}}>{item.AllotedDate}</span>
                                                                                  </p>
                                                                                  <p style={{color: '#cc0000', fontSize: 15, fontWeight: 600}}> 
                                                                                    Alloted Hours:  
                                                                                    <span style={{color: 'black', fontSize: 15, fontWeight: 100}}>{item.AllotedHrs}</span>
                                                                                  </p>
                                                                                  <p style={{color: '#cc0000', fontSize: 15, fontWeight: 600}}> 
                                                                                    Est-Comp-Date :
                                                                                    <span style={{color: 'black', fontSize: 15, fontWeight: 100}}>{item.Est_CompletedDate}</span>
                                                                                  </p>
                                                                                  {/* <a href=" " className="Delete">Delete</a> */}
                                                                  </div>
                                                                ))
                                                              }
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                      <div id="pendingdiv" className="col-sm-6 col-xs-12 col-md-6 drag_drop_container"> 
                                                          <div className="drag_contant" id="progress">
                                                            <h3 className="h3_head">InProgress</h3>
                                                            <ul className="sortable-list drag_drop_list">
                                                              <li className="sortable-item">
                                                              {
                                                                this.state.InProgressData.map((item) => (
                                                                  <div className="drop_top progress_border" id="41956">
                                                                    <h4 style={{fontSize: 15, fontWeight: 200 , cursor: 'pointer'}} className="testing" onClick={() => { this.TimeSheetMethod(this, item);}}>{item.ProjectName +'----'+ item.ModuleName}</h4>
                                                                    <p style={{color: '#cc0000', fontSize: 15, fontWeight: 600}}> 
                                                                      TaskName:
                                                                      <span style={{color: 'black', fontSize: 15, fontWeight: 100}}>{item.TaskName}</span>
                                                                    </p>
                                                                    <p style={{color: '#cc0000', fontSize: 15, fontWeight: 600}}>
                                                                      Alloted By:
                                                                      <span style={{color: 'black', fontSize: 15, fontWeight: 100}}>{item.CreatedBy}</span>
                                                                    </p>
                                                                    <p style={{color: '#cc0000', fontSize: 15, fontWeight: 600}}> 
                                                                      Alloted To:  
                                                                      <span style={{color: 'black', fontSize: 15, fontWeight: 100}}>{item.FullName}</span> 
                                                                    </p>
                                                                    {/* <p style={{color: '#cc0000', fontSize: 15, fontWeight: 150}}> 
                                                                      Crt Date:  
                                                                      <span style={{color: 'black', fontSize: 15, fontWeight: 100}}>{item.CreatedDate}</span>
                                                                    </p> */}
                                                                    <p style={{color: '#cc0000', fontSize: 15, fontWeight: 600}}>  
                                                                      Assigned Date : 
                                                                      <span style={{color: 'black', fontSize: 15, fontWeight: 100}}>{item.AllotedDate}</span>
                                                                    </p>
                                                                    <p style={{color: '#cc0000', fontSize: 15, fontWeight: 600}}> 
                                                                      Alloted Hours:  
                                                                      <span style={{color: 'black', fontSize: 15, fontWeight: 100}}>{item.AllotedHrs}</span>
                                                                    </p>
                                                                    <p style={{color: '#cc0000', fontSize: 15, fontWeight: 600}}> 
                                                                      Est-Comp-Date :
                                                                      <span style={{color: 'black', fontSize: 15, fontWeight: 100}}>{item.Est_CompletedDate}</span>
                                                                    </p>
                                                                    {/* <a href=" " className="Delete">Delete</a> */}
                                                                  </div>
                                                                ))
                                                              }
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </div>
                                                      </div>
                                                  </div>
                                              </td>
                                          </tr>
                                      </tbody>
                                  </table>
                          </div>  
                        </div>
                         )}

{this.state.showtab2 && (
  <div id="second-panel" className="panel animated slideInRight">
    <div id="leadTab_C1" style={{}}>
  <div style={{position: "relative"}}>
    <div style={{paddingTop: 20,display:'inline'}}>
      <span id="label_Text" style={{color: '#4c4a48', fontSize: '25px', fontWeight: 'bold'}}>Task Allotment Report</span>
    </div>
    <div class="linic_input_boxes_one" style={{display: "flex",alignItems : "center",justifyContent:'flex-end',float:'r'}}>
                                                          <span id="Label74" style={{fontSize:"17px",color:"#4c4a48",paddingRight:"10px"}}> </span>
                                                          <div class="seldct_drop_ic">
                                                          <label class="drop_lef_img"><img style={{width: "35px" ,height: "35px",}} src={dep_ment_ic} alt="logout" /></label>
                                                          <span class="drop_rit_img"><img style={{width: "25px" ,height: "25px",}} src={drop_dwn_ic} alt="logout" /></span>
                                                          <select name="system_ton" id="ddlTaskStatus" class="select_new MB10 fleft selector_s" onChange={this.DepartmentChange}  value={this.state.SelectedDepartment} style={{marginleft:"10px", width:"210px", padding: "10px"}}>
                                                              <option value="">Department</option>
                                                                { this.state.DepartmentData.map((element) => 
                                                                  <option value={element.DeptID}>{element.Department}</option>
                                                                )}
                                                            </select>
                                                            </div>
                                                            <div class="dep_dat_flx_bx">
                                                            <p style={{fontSize: "17px",margin: "0px",color:"#4c4a48"}} ></p>
                                                           
                                                            <input class="dat_bx_lin" id="dateRequired" type="text" value={this.state.selectedDate2} readOnly style={{borderRight:"none"}} />
                                                            
                                                            <input class="dat_bx_icon" id="dateRequired" type="date" name="editmoduleFinaldate1" onChange={this.DateChange2} style={{}}/>
                                                            
                                                            <button class="tab-label" onClick={() =>this.TodayTaskTabDatasMethod()} style={{backgroundColor: "#9d70ff", color: "rgb(255, 255, 255)", border: "none", padding: "7px 15px", marginTop: "0px", borderRadius: "6px", marginRight: "10px",marginLeft:"20px"}}>View</button>
                                                            <button class="tab-label" onClick={() =>this.AddShowPopupMethod()} style={{backgroundColor: "#9d70ff", color: "rgb(255, 255, 255)", border: "none", padding: "7px 15px", marginTop: "0px", borderRadius: "6px", marginRight: "0px"}}>Task Log</button>
                                                            {/* <input id="dateRequired" type="date" name="dateRequired" value={this.state.2} onChange={this.DateChange}/> */}
                                                            {/* <input type="date" id="date-input" value={this.state.selectedDate2} onChange={this.DateChange} /> */}
                                                          {/* <DatePicker id="datepicker" 
                                                           selected={this.state.TodayDate}
                                                          //  onChange={this.DateChange}
                                                           dateFormat="MM/dd/yyyy"
                                                           /> */}
                                                           </div>
                                                           
    </div>


    <h2 class="notsk_less_tsk_title">Employees Have No Task</h2>
<div class="notsk_less_tsk">
  
  <div class="left_task_scrlbar">
    <div id="No-Task-List" className="drag_drop_main_container emp_hav_no_tsk_list_grid">
      
      {
    this.state.EmployeesNoTaskData.map((item) => (
      
      // <div id="div1" className="col-sm-3 col-xs-3 col-md-6 drag_drop_container">
      <div id="div1" className="drag_drop_container">
        <div className="drag_contant" id="NoTasklist1">
          <ul className="sortable-list drag_drop_list">
            <li className="sortable-item">
              <div className="drop_top progress_border">
                <h4 style={{fontSize: 15, fontWeight: 200, cursor: 'pointer'}} onClick={() =>this.AddShowPopupMethod()}>{item.FullName}</h4>
                <p style={{color: 'Red', fontSize: 15, fontWeight: 150}}> <img style={{width: "auto" ,height: "22px"}} src={no_tsk_ic} alt="logout" /> No Task Alloted</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      
    ))
  }

{
    this.state.LeaveData.map((item) => (
      
      // <div id="div1" className="col-sm-3 col-xs-3 col-md-6 drag_drop_container">
      <div id="div1" className="drag_drop_container">
        <div className="drag_contant" id="NoTasklist1">
          <ul className="sortable-list drag_drop_list">
            <li className="sortable-item">
              <div className="drop_top progress_border">
                <h4 style={{fontSize: 15, fontWeight: 200, cursor: 'pointer'}}>{item.FullName}</h4>
                <p style={{color: 'Red', fontSize: 15, fontWeight: 150}}> <img style={{width: "auto" ,height: "22px"}} src={no_tsk_ic} alt="logout" />Leave</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      
    ))
  }

      {/* <div id="div2" className="col-sm-3 col-xs-3 col-md-3 drag_drop_container">
        <div className="drag_contant" id="NoTasklist2">
          <ul className="sortable-list drag_drop_list">
            <li className="sortable-item">
              <div className="drop_top progress_border">
                <h4 style={{fontSize: 15, fontWeight: 200}}>Mukeshraj V</h4>
                <p style={{color: 'Red', fontSize: 15, fontWeight: 150}}>No Task Alloted</p>
              </div>
            </li>
          </ul>
        </div>
      </div> */}

      {/* <div id="div3" className="col-sm-3 col-xs-3 col-md-3 drag_drop_container">
        <div className="drag_contant" id="NoTasklist3">
          <ul className="sortable-list drag_drop_list">
            <li className="sortable-item">
              <div className="drop_top progress_border">
                <h4 style={{fontSize: 15, fontWeight: 200}}>vignesh D</h4>
                <p style={{color: 'Red', fontSize: 15, fontWeight: 150}}>No Task Alloted</p>
              </div>
            </li>
          </ul>
        </div>
      </div> */}

 {this.state.EmployeesNoTaskData.length === 0 && (
      <div id="div4" className="col-sm-3 col-xs-3 col-md-3 drag_drop_container">
        <div className="drag_contant" id="NoTasklist4">
          <ul className="sortable-list drag_drop_list" />
          <h3>No Task</h3>
        </div>
      </div>
 )}
    </div>
  </div>  

    <div id="LessHour" className="drag_drop_main_container less_hrs_tsk_list">
      <h2 class="notsk_less_tsk_tit_two">Employees Have Less Hour Task</h2>
      <div id="Task1" className="emp_hav_les_tsk drag_drop_container">
        <div className="drag_contant" id="LessHourTasklist1">
          <ul className="sortable-list drag_drop_list" id="Task169">
        {
          this.state.EmployeesLessHourTaskData.map((item) => (
            <li className="sortable-item">
              <div className="drop_top progress_border" id={52329}>
                <h4 className="Issues" style={{fontSize: 15, fontWeight: 200}}>{item.FullName}</h4>
                <h4 className="emy_les_tsk_def_tsk" style={{fontSize: 15, fontWeight: 200}}>{item.ProjectName}</h4>
                <h5 class="emy_les_tsk_def_tsk" style={{fontSize: 15, fontWeight: 200}}>{item.ModuleName}</h5>
                <p style={{color: 'rgb(204, 0, 0)', fontSize: 15, fontWeight: 150}}>Task Name:<span style={{color: 'black', fontSize: 15, fontWeight: 100}}>{item.TaskName}</span></p>
                <p style={{color: 'rgb(204, 0, 0)', fontSize: 15, fontWeight: 150}}>Alloted Hours :<span style={{color: 'black', fontSize: 15, fontWeight: 100}}>{item.AllotedHrs}</span></p>
                <p style={{color: 'rgb(204, 0, 0)', fontSize: 15, fontWeight: 150}}>Task Status: <span style={{color: 'black', fontSize: 15, fontWeight: 100}}>{item.TaskStatus}</span></p>
                <p style={{color: 'rgb(204, 0, 0)', fontSize: 15, fontWeight: 150}}>Alloted Date :<span style={{color: 'black', fontSize: 15, fontWeight: 100}}>{item.TaskDate}</span></p>
              </div>
            </li>
          ))
         }
          </ul>
        </div>
      </div>

      {this.state.EmployeesLessHourTaskData.length === 0 && (

      <div id="Task2" className=" drag_drop_container">
        <div className="drag_contant" id="LessHourTasklist2">
          {/* <h3>No Task</h3> */}
          <span class="notsk_img"><img style={{width: "300px"}} src={no_tsk_img} alt="logout" /></span>
        </div>
      </div>

      )}

       {/*<div id="Task3" className="col-sm-3 col-xs-3 col-md-3 drag_drop_container">
        <div className="drag_contant" id="LessHourTasklist3">
          <ul className="sortable-list drag_drop_list" />
        </div>
      </div>
      <div id="Task4" className="col-sm-3 col-xs-3 col-md-3 drag_drop_container">
        <div className="drag_contant" id="LessHourTasklist4">
          <ul className="sortable-list drag_drop_list" />
        </div>
      </div> */}

    </div>
</div>  

    <div id="TaskListdiv">
      <h2 class="emp_hav_tsk">Employes Have Task</h2>
      <div id="TaskList" className="drag_drop_main_container task_allot_list_grd_bx">
      {
          this.state.EmployeesTaskData.map((item) => (
        <div className="dept-16">
          <div style={{paddingLeft: 30, display: 'block'}} className="hide-class deptemp-16 empname-169">
            {/* <table>
              <tbody>
                <tr>
                  <td><h3>Employee : {item.FullName}</h3></td>
                </tr>
              </tbody>
            </table> */}
          </div>
          <div className="drag_drop_main_container depttask-16 emptask-169 emp_hav_tsk_grd_box" style={{display: 'table'}}>
            <div className="drag_drop_table">
              <div className="tbody" id="tasklist-169">
                <div className="trow" id="TasklistMohan">
                  <div className="tdiv tdiv1" >
                    <div className="drag_drop_container1">
                      <div className="drag_contant">
                        <ul className="sortable-list drag_drop_list" id="Task169">
                          <li className="sortable-item">
                            <div className="drop_top progress_border emp_hav_tsk_grd_bx" id={52329}>
                              <h3 class="emp_tsk_empoly_name">{item.FullName}</h3>
                              <div class="emphav_tsk_titandcon">
                              <h5 style={{fontSize: 15, fontWeight: 200}} className="Issues">{item.ProjectName}</h5>
                              <h5 class="task_grdbx_lis" style={{}}>{item.ModuleName}</h5>
                              </div>
                              <div class="emp_hav_tsk_listview">
                              <p style={{color: '#cc0000', fontSize: 15, fontWeight: 600}}>
                                Task Name:<span style={{color: 'black', fontSize: 15, fontWeight: 100}}>{item.TaskName}</span>
                              </p>
                              <p style={{color: '#cc0000', fontSize: 15, fontWeight: 600}}>Alloted Hours :<span style={{color: 'black', fontSize: 15, fontWeight: 100}}>{item.AllotedHrs}</span></p>
                              <p style={{color: '#cc0000', fontSize: 15, fontWeight: 600}}>Task Status: <span style={{color: 'black', fontSize: 15, fontWeight: 100}}>{item.TaskStatus}</span></p>
                              <p style={{color: '#cc0000', fontSize: 15, fontWeight: 600}}>Alloted Date :<span style={{color: 'black', fontSize: 15, fontWeight: 100}}>{item.TaskDate}</span></p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  {/* <div className="tdiv tdiv2" />
                  <div className="tdiv tdiv3" />
                  <div className="tdiv tdiv4" />
                  <div className="tdiv tdiv5" />
                  <div className="tdiv tdiv6" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
          ))
        }

{this.state.EmployeesTaskData.length === 0 && (
<div id="Task2" className="col-sm-3 col-xs-3 col-md-3 drag_drop_container">
<div className="drag_contant" id="LessHourTasklist2">
<h3>No Task</h3>
</div>
</div>
)}

</div>



    </div>
  </div>
</div>
</div>
 )}
      </div>
                          
                      </td>
                    </tr>
               </tbody>
            </table>
        </div>


{this.state.AddShowPopup && (

<div className="popup-create boardpopup-grid">
<div className='popup-body'>

<Formik
          initialValues={{
            IssueName: '',
            IssueType: '',
            Module_Epic: '',
            // WorkFlow: '',
            // Priority: '',
            StartDate: '',
            // Reportedby: '',
            AssignedTo: '',
            AssignedBy: '',
            Taskstatus:'',
            AllotedHrs:'',
          }}
          validationSchema={AddTaskLogsValidation}
          onSubmit={values => {
            console.log('AddBackLogsValidation', values);

            var Description = document.getElementById('Description').value;
            // var Analysis = document.getElementById('Task_Analysis').value;
            var Boardid = document.getElementById('Board').value;
            var Sprintid = document.getElementById('Sprint').value;
            var Billable = document.getElementById('IsBillable').checked;
            // var Active = document.getElementById('IsActive').checked;
            // var Attachment = document.getElementById('FUAttachments').value;

          var date = moment(values.StartDate).format("MM/DD/YYYY")
            var AddTask = 'http://helpdeskservice.desss-portfolio.com/api/TaskLogNew/SaveTaskallotment/?ProjectID=' + this.state.ProjectID + '&UserName=' + this.state.LoginDatas.EmpName + '&CompanyID='+this.state.LoginDatas.CompanyID+'&IssueName='+values.IssueName+'&ProjectName='+this.state.ProjectID+'&ModuleName='+values.Module_Epic+'&TaskCategory='+this.state.TaskCategoryID+'&TaskType='+values.IssueType+'&TaskStatus='+values.Taskstatus+'&TaskDate='+date+'&AssignedBy='+values.AssignedBy+'&AssignedTo='+values.AssignedTo+'&ImportStatus=&ApprovedStatus=Approved&AllotedHours='+values.AllotedHrs+'&Description='+Description+'&Billable='+Billable+'&BoardName='+Boardid+'&SprintName=' + Sprintid;
            this.setState({ LoaderShow: true }); 
            axios.post(AddTask)
            .then(AddTaskDatas => {
              this.setState({ LoaderShow: false }); 
               this.setState({ AddShowPopup: false });
               Swal.fire({
                icon: "success",
                title: "Task Added Successfully",
                showConfirmButton: false,
                timer: 1500
              });
              this.TodayTaskTabDatasMethod();
              console.log('AddTaskDatas',  AddTaskDatas.data.data);
            })
            .catch(error => {
              this.setState({ LoaderShow: false }); 
             this.setState({ AddShowPopup: false });
             this.TodayTaskTabDatasMethod();
              console.log(error);
              
            })
          }}

         
        >

{formik => (
   
 <Form class="addtsk_frm_fld">
     <div className="dxpc-headerContent" style={{float:'left'}}>
      <span className="dxpc-headerText" id="Grid_DXPEForm_PWH-1T">Add Task</span>
    </div>
    <button className='popup-closebtn' onClick={this.AddPopupClose} style={{float:'right'}}><img style={{width: "30px" ,height: "30px",}} src={clos_ic} alt="logout" /></button>
        <table id="Grid_DXPEForm_DXEFT" class="submodule-newpopup" cellSpacing={0} cellPadding={0} border={0} style={{fontFamily: 'Calibri', fontSize: 15, width: '100%', borderCollapse: 'collapse', padding:'15px 0px',display:'flex',borderRadius:'10px',marginBottom:'0px'}}>
          <tbody style={{width:'100%'}}>
            <div class="add_tsk_inpt_fld1">
              {/* <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Project Name</label>
              </td> */}
              <div className="dxgvEditFormCell_Office2003Blue seldct_drop_ic" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <label class="drop_lef_img"><img style={{width: "25px" ,height: "25px",}} src={proj_nam} alt="logout" /></label>
              <span class="drop_rit_img"><img style={{width: "20px" ,height: "20px",}} src={drop_dwn_ic} alt="logout" /></span>
              <select name="Projectname" class="selector_s" id='Projectname' as="select" onChange={this.BoardDatasMethod} value={this.state.ProjectID} style={{width: '100%'}}>
              {/* <Field name="Projectname" id='Projectname' as="select" style={{width: '100%'}}> */}
              <option>Project Name</option>
                    { this.state.project_data.map((element) => 
                      <option  value={element.ProjectID}>{element.ProjectName}</option>
                    )}
              </select>


              </div>
            
            <div class="add_tsk_fld_rite">
              {/* <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Module</label>
              </td> */}
              <div className="dxgvEditFormCell_Office2003Blue seldct_drop_ic" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <label class="drop_lef_img"><img style={{width: "25px" ,height: "25px",}} src={module_ic} alt="logout" /></label>
              <span class="drop_rit_img"><img style={{width: "20px" ,height: "20px",}} src={drop_dwn_ic} alt="logout" /></span>
              <Field name="Module_Epic" id='Module_Epic' class="selector_s" as="select" style={{width: '100%'}}>
              <option>Module</option>
                    { this.state.Module_Epic_Datas.map((element) => 
                      <option  value={element.ModuleID}>{element.ModuleName}</option>
                    )}
              </Field>
             <ErrorMessage component="Module_Epic" className=" form-label text-danger" name="Module_Epic" />

              </div>
            </div>
            </div>

            <div class="add_tsk_inpt_fld2 seldct_drop_ic">
              {/* <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Issue Name</label>
              </td> */}
              
              <div className="dxgvEditFormCell_Office2003Blue" class="issu_nam_inpfld" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <label class="drop_lef_img"><img style={{width: "25px" ,height: "25px",}} src={issue_nam_ic} alt="logout" /></label>
              <Field name="IssueName" type="text" placeholder="Issue Name" style={{width: '100%'}} />
                  <ErrorMessage component="label" className=" form-label text-danger" name="IssueName" />
              </div>
              
           

           
              {/* <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>TaskCategory</label>
              </td> */}
              <div className="dxgvEditFormCell_Office2003Blue seldct_drop_ic" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              {/* <DatePicker  name="editmoduleFinaldate1"  selected={this.state.FinalEndDate} style={{width: '100%'}} dateFormat="dd-mm-yyyy"/> */}
              <label class="drop_lef_img"><img style={{width: "25px" ,height: "25px",}} src={tsk_cat_ic} alt="logout" /></label>
              <span class="drop_rit_img"><img style={{width: "20px" ,height: "20px",}} src={drop_dwn_ic} alt="logout" /></span>
              <select name="WorkFlow" id='WorkFlow' class="selector_s" as="select" onChange={this.IssueTypeDataMethod} value={this.state.TaskCategoryID} style={{width: '100%'}}>
              <option>TaskCategory</option>
                    { this.state.WorkFlowDatas.map((element) => 
                      <option  value={element.TaskCategoryID}>{element.Description}</option>
                    )}
              </select>

              {/* <Field name="WorkFlow" id='WorkFlow' as="select" style={{width: '100%'}}>
              <option>--Select--</option>
                    { this.state.WorkFlowDatas.map((element) => 
                      <option  value={element.TaskCategoryID}>{element.Description}</option>
                    )}
              </Field>
             <ErrorMessage component="WorkFlow" className=" form-label text-danger" name="WorkFlow" /> */}

              </div>
            
            </div>


            <div class="add_tsk_inpt_fld3">
           
              {/* <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>TaskType</label>
              </td> */}
              <div className="dxgvEditFormCell_Office2003Blue seldct_drop_ic" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <label class="drop_lef_img"><img style={{width: "25px" ,height: "25px",}} src={tsktype_ic} alt="logout" /></label>
              <span class="drop_rit_img"><img style={{width: "20px" ,height: "20px",}} src={drop_dwn_ic} alt="logout" /></span>
              <Field name="IssueType" class="selector_s" id='IssueType' as="select" style={{width: '100%'}}>
              <option>TaskType</option>
                    { this.state.IssueTypeDatas.map((element) => 
                      <option  value={element.TaskID}>{element.TaskDescription}</option>
                    )}
              </Field>
             <ErrorMessage component="IssueType" className=" form-label text-danger" name="IssueType" />

              </div>
           

           
              {/* <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Date</label>
              </td> */}
              <div className="dxgvEditFormCell_Office2003Blue datfltbx" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <Field name="StartDate" class="dat_bx_icon" id='StartDate' type="date" style={{width: '100%'}} >
              
              </Field>
               <ErrorMessage component="label" className=" form-label text-danger" name="StartDate" />
              </div>
            
            </div>

            <div class="add_tsk_inpt_fld4">

           
              {/* <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Board</label>
              </td> */}
              <div className="dxgvEditFormCell_Office2003Blue seldct_drop_ic" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <label class="drop_lef_img"><img style={{width: "25px" ,height: "25px",}} src={board_ic} alt="logout" /></label>
              <span class="drop_rit_img"><img style={{width: "20px" ,height: "20px",}} src={drop_dwn_ic} alt="logout" /></span>
              <select name="Board" id='Board' class="selector_s" as="select" style={{width: '100%'}} >
              <option value={0}>Board</option>
                    { this.state.BoardDatas.map((element) => 
                      <option  value={element.BoardID}>{element.BoardName}</option>
                    )}
                </select>                  
              </div>
           

          
              {/* <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Sprint</label>
              </td> */}
              <div className="dxgvEditFormCell_Office2003Blue seldct_drop_ic" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <label class="drop_lef_img"><img style={{width: "auto" ,height: "25px",}} src={sprint_ic} alt="logout" /></label>
              <span class="drop_rit_img"><img style={{width: "20px" ,height: "20px",}} src={drop_dwn_ic} alt="logout" /></span>
              <select name="Sprint" class="selector_s" id='Sprint' as="select" style={{width: '100%'}} >
              <option value={0}>Sprint</option>
                    { this.state.SprintDatas.map((element) => 
                      <option  value={element.SprintID}>{element.SprintName}</option>
                    )}
                </select>                  
              </div>
            
            </div>

            <div class="add_tsk_inpt_fld5">
            
              {/* <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Task Status</label>
              </td> */}
              <div className="dxgvEditFormCell_Office2003Blue seldct_drop_ic" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <label class="drop_lef_img"><img style={{width: "25px" ,height: "25px",}} src={tsk_status_ic} alt="logout" /></label>
              <span class="drop_rit_img"><img style={{width: "20px" ,height: "20px",}} src={drop_dwn_ic} alt="logout" /></span>
              <Field name="Taskstatus" class="selector_s" id='Taskstatus' as="select" style={{width: '100%'}} >
              <option>Task Status</option>
                    { this.state.TaskstatusDatas.map((element) => 
                      <option  value={element.TaskStatusID}>{element.TaskStatusDescription}</option>
                    )}
              </Field>
              {/* <Field name="Taskstatus" id="Taskstatus" defaultValue={this.state.EditTaskAllotDatas.TaskStatus} type="text" style={{width: '100%'}}/> */}
                  <ErrorMessage component="Taskstatus" className=" form-label text-danger" name="Taskstatus" />
              </div>
            

            
              {/* <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Assigned By</label>
              </td> */}
              <div className="dxgvEditFormCell_Office2003Blue seldct_drop_ic" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <label class="drop_lef_img"><img style={{width: "25px" ,height: "25px",}} src={assing_by_ic} alt="logout" /></label>
              <span class="drop_rit_img"><img style={{width: "20px" ,height: "20px",}} src={drop_dwn_ic} alt="logout" /></span>
              <Field name="AssignedBy" class="selector_s" id='AssignedBy' as="select" style={{width: '100%'}} >
              <option value={0}>Assigned By</option>
                    { this.state.ReportedByData.map((element) => 
                      <option  value={element.EmpID}>{element.EmpName}</option>
                    )}
                </Field>                  
                    <ErrorMessage component="label" className="form-label text-danger" name="AssignedBy" />
              </div>
            
            </div>

            <div class="add_tsk_inpt_fld6">
            
              {/* <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Assigned To</label>
              </td> */}
              <div className="dxgvEditFormCell_Office2003Blue seldct_drop_ic" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <label class="drop_lef_img"><img style={{width: "25px" ,height: "25px",}} src={assign_to_ic} alt="logout" /></label>
              <span class="drop_rit_img"><img style={{width: "20px" ,height: "20px",}} src={drop_dwn_ic} alt="logout" /></span> 
              <Field name="AssignedTo" class="selector_s" id='AssignedTo' as="select" style={{width: '100%'}} >
              <option>Assigned To</option>
                    { this.state.AllotedToDatas.map((element) => 
                      <option  value={element.EmpID}>{element.EName}</option>
                    )}
                </Field>                  
                    <ErrorMessage component="label" className="form-label text-danger" name="AssignedTo" />
              </div>
            

            
              {/* <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Alloted Hours</label>
              </td> */}
              <div className="dxgvEditFormCell_Office2003Blue seldct_drop_ic" colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <label class="drop_lef_img"><img style={{width: "25px" ,height: "25px",}} src={allot_hrs_ic} alt="logout" /></label>
              <Field name="AllotedHrs" type="number" class="allot_hrs_fld" placeholder="Alloted Hours" style={{width: '100%'}} />
                  <ErrorMessage component="label" className=" form-label text-danger" name="AllotedHrs" />
              </div>
            
            </div>

            <div>
              {/* <td className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label'>Description</label>
              </td> */}
              <div className="dxgvEditFormCell_Office2003Blue"  colSpan={1} rowSpan={1} style={{width: '100%'}}>
              <textarea  name="Description" id='Description' placeholder='Description' type="textarea"  style={{width: '100%'}} />  
              </div>
            </div>

 
            <div class="is_bill_radiobtn">
              <div className="dxgvEditFormCaption_Office2003Blue">
                <label className='popup-label_x'>IsBillable</label>
              </div>
              <div className="dxgvEditFormCell_Office2003Blue" colSpan={1} rowSpan={1} style={{width: '100%'}}>
                <input name="IsBillable" id='IsBillable' type="checkbox" style={{width: ''}} />  
              </div>
            </div>

          </tbody>
        </table>

       <div class="popup-update">
        <button type='submit' >Save</button>
        {/* <button >Save and Exit</button> */}
        </div>
        </Form>
    )}

 </Formik>
 </div>
  </div>
)}

    <div className='free-space_x'></div>                                                    
     <Footer />
   </div>
    )
  }
}

export default TaskAllotmentList