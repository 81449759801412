import React, { Component } from 'react'
import Header from './header'
import refresh from '../images/refresh.png'
import Footer from './footer'
import axios from 'axios';
import Delete from '../images/delete_icon.png';
import ReactTable from "react-table-6";
import Swal from 'sweetalert2';
import Loader from '../images/loader2.gif';
import showmore from '../images/show_more_btn.png'
import Edit from '../images/edit_icon.png'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import New from '../images/add.png';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

// const options = {
//   title: {
//     text: 'Burndown Chart',
//     x: -20 // center
//   },
//   colors: ['blue', 'red'],
//   plotOptions: {
//     line: {
//       lineWidth: 3
//     },
//     tooltip: {
//       hideDelay: 200
//     }
//   },
//   subtitle: {
//     text: 'Sprint 1',
//     x: -20
//   },
//   xAxis: {
//     categories: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7', 'Day 8', 'Day 9', 'Day 10']
//   },
//   yAxis: {
//     title: {
//       text: 'Hours'
//     },
//     plotLines: [{
//       value: 0,
//       width: 1
//     }]
//   },
//   tooltip: {
//     valueSuffix: ' hrs',
//     crosshairs: true,
//     shared: true
//   },
//   legend: {
//     layout: 'vertical',
//     align: 'right',
//     verticalAlign: 'middle',
//     borderWidth: 0
//   },
//   series: [{
//     name: 'Ideal Burn',
//     color: 'rgba(255,0,0,0.25)',
//     lineWidth: 2,
//     data: [100, 90, 80, 70, 60, 50, 40, 30, 20, 10]
//   }, {
//     name: 'Actual Burn',
//     color: 'rgba(0,120,200,0.75)',
//     marker: {
//       radius: 6
//     },
//     data: [100, 110, 85, 60, 60, 30, 32, 23, 9, 2]
//   }]
// };

export class BurnOutChart extends Component {

  constructor() {
		super();
		this.state = {
      ProjectDatas:[],
      LoginDatas:  JSON.parse(localStorage.getItem("HelpDeskLoginDatas")),
      BurnDatas:  JSON.parse(localStorage.getItem("BurnOutChartData")),
      Auth:  JSON.parse(localStorage.getItem("auth")),
      ChartDatas: [],
	  };
	}

componentDidMount(){

    const Auth =  JSON.parse(localStorage.getItem("auth"));
      if(!Auth){
       this.props.history.push('/login');
      }
      var Datas = [];
      this.setState({ LoaderShow: true });
      // var URL = 'https://helpdeskservice.desss-portfolio.com/api/BurnOutChart/LoadBurnOutChart?SprintID=' + this.state.BurnDatas.SprintID;
      // var URL = 'https://helpdeskservice.desss-portfolio.com/api/BurnOutChart/LoadBurnOutChart?SprintID=1146';
      var URL = 'https://helpdeskservice.desss-portfolio.com/api/BurnOutChart/LoadBurnOutChart?SprintID=1329';
      axios.post(URL)
      .then(res => {
         var data = res.data.data;
         this.setState({ ChartDatas: data }); 
         this.setState({ LoaderShow: false });
      })
      .catch(error => {
        this.setState({ LoaderShow: false });
        console.log(error);
      })
   
  }



  render() {

    const options = {
      title: {
        text: 'Burndown Chart of Project',
        x: -20 // center
      },
      colors: ['blue', 'red'],
      plotOptions: {
        line: {
          lineWidth: 3
        },
        tooltip: {
          hideDelay: 200
        }
      },
      subtitle: {
        text: 'All Project Team Summary',
        x: -20
      },
      xAxis: {
        categories: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7', 'Day 8', 'Day 9', 'Day 10']
      },
      yAxis: {
        title: {
          text: 'Work Hours'
        },
        plotLines: [{
          value: 0,
          width: 1
        }]
      },
      tooltip: {
        valueSuffix: ' hrs',
        crosshairs: true,
        shared: true
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        borderWidth: 0
      },
      series: [{
        name: 'Allotted Hour Burn',
        color: 'rgba(255,0,0,0.25)',
        lineWidth: 2,
        data: [100, 90, 80, 70, 60, 50, 40, 30, 20, 10]
      }, {
        name: 'Actual Burn',
        color: 'rgba(0,120,200,0.75)',
        marker: {
          radius: 6
        },
        data: [100, 110, 85, 60, 60, 30, 32, 23, 9, 2]
      }]
    };


    return (
      <div>

        {this.state.LoaderShow === true ? (
          <div className='loader-img'>
            <img   src={Loader} height="50" width="50" alt="Helpdesk" />
          </div>
        ) : (
          ''
        )}

        <Header />
        <h3>BurnOutChart</h3>
        <HighchartsReact highcharts={Highcharts} options={options} />;
        <div className='free-space'></div>
        <Footer />
      </div>
    )
  }
}

export default BurnOutChart